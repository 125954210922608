import React, { useEffect, useReducer, useState } from 'react';

import { Row, Col, Typography, Button, Space, Table, Tag, Modal, Spin, Divider, Breadcrumb, notification, message, Empty, Popover} from 'antd';
import { Content } from 'antd/es/layout/layout';
import axios from 'axios';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

// import VehicleModal from '../../../images/vehicleModal.png';
// import DrumModal from '../../../images/drumModal.png';
// import ClampingModal from '../../../images/clampingModal.png';
// import mapImg from '../../../images/mapImg.png';
// import mapImgDeep from '../../../images/ManualDeep.png';
// import mapImgMachine from '../../../images/JCBMap.png';
// import mapImgTunneling from '../../../images/TunnelingMap.png';
// import modalDefault from '../../../images/modalDefault.png';
// import modalMan from '../../../images/ManWalking.png';
import { RELATED_ALARMS_REPORT, RELATED_ALARMS_REPORT_ERROR, RELATED_ALARMS_REPORT_SUCCESS, REPORT_ALARM_DETAILS, REPORT_ALARM_LIST, REPORT_ALARM_LIST_ERROR, REPORT_ALARM_LIST_SUCCESS } from './constants';
import alarmreport from './reducer';
import {BatchDownloader} from './downloader';
import { DownloadOutlined, HomeOutlined } from '@ant-design/icons';
import { datetimeCovertertolocal } from '../../datetime-converter';
import { useAuth } from '../../Context';
import activityicons from '../../activitytypes';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';


let alarm_data_pagination = {};


export default function AlarmReportTable() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const { state } = useLocation();
	const initalState = {alarm_data : [], details: null, pending: false, error: false };
	const [alarmdata, dispatch] = useReducer(alarmreport, initalState);
	const [modelOpen, setModelOpen] = useState(false);
	const navigate = useNavigate();
	const [showFetchAndDownloadModal, setShowFetchAndDownloadModal] = useState(false);
	const [url, setURL] = useState(null);
	const {getRegion, regiondata} = useAuth();
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const [alarmdetails, setAlarmDetails] = useState({});
	const [relatedAlarms, setRelatedAlarms] = useState([]);
	const [activeExpRow, setActiveExpRow] = useState([]);
	// const [showRelatedAlarm, setShowRelatedAlarm] = useState(false);

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));
  
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	let AlarmList = [];

	alarmdata.alarm_data && alarmdata.alarm_data.map((item, index) => {
		activityicons && activityicons.map((eventactivity)=>{

			if(item.eventType==eventactivity.type){

				const datetime = datetimeCovertertolocal(item.datetime);
				const newdate = datetime.localdate + ' ' +  datetime.localtime;
				AlarmList.push({
					key: index.toString(),
					alarm_prm_id: item.alarm_prm_id,
					eventType: item.eventType,
					description:item.ruleDesc,
					date:newdate,
					durations:item.duration ? (item.duration/60).toFixed(2) + ` ${langdata && langdata.Min ? langdata.Min:' Min'}` : 0 + ` ${langdata && langdata.Min ? langdata.Min:' Min'}`,
					channel: item.channel_name,
					eventDate: newdate,
					chainage: item.locationDetails ? item.locationDetails.info : null,
					odMeter: item.odMeter,
					status:item.status,
					pidsinfoId:item.pidsinfoId,
					lat: item.locationDetails ? item.locationDetails.Lat : null,
					long: item.locationDetails ? item.locationDetails.Long : null,
					channel_name:item.channel_name ? item.channel_name :'',
					alarmSeverity:item.alarmSeverity ? item.alarmSeverity :'',
					eventprobablity : item.eventprobablity ? item.eventprobablity :'',
					remarks:item.Remark? item.Remark :'',
					locationDetails:item.locationDetails,
					regioninfoId:item.regioninfoId,
					pipelineId:item.pipelineId,
					ackdetDetails:item.ackdetDetails,
					datetime:item.datetime,
					duration:item.duration,
					isparent: item.isParent ? 'Primary' : 'Related',
					gps:`Latitude: ${item.locationDetails.Lat} Longitude: ${item.locationDetails.Long}`,
					showGPS:`Latitude: ${item.locationDetails.Lat.toFixed(5)} Longitude: ${item.locationDetails.Long.toFixed(5)}`,
					pidsname:item.pidname.toUpperCase(),
					region : regiondata && regiondata.map((region) => { if (item.regioninfoId === region.id){ return region.regionName.toUpperCase();} }),
					activityUrl: eventactivity.type && item.eventType==eventactivity.type ? eventactivity.severity[item.alarmSeverity] : '-',
					channelId:item.channelId

				});
			}
		});
	});

	let contenttext = '';
	if(alarmdata && alarmdata.details && alarmdata.details){

		const datetime = datetimeCovertertolocal(alarmdata.details.datetime);
		const newdate = datetime.localdate + ' ' +  datetime.localtime;
		let regionObject = regiondata && regiondata.find((region) => region.id == alarmdata.details.regioninfoId);
		let regionName;
		if(regionObject !== undefined){
			regionName= regionObject.regionName.toUpperCase();
		}
		contenttext = `${langdata && langdata.Id ? langdata.Id:'Id'}: ${alarmdata.details.alarm_prm_id}\n${langdata && langdata.Activity ? langdata.Activity:'Activity'}: ${alarmdata.details.eventType}\n${langdata && langdata.Date ? langdata.Date:'Date'}: ${newdate}\n${langdata && langdata.Region ? langdata.Region:'Region'}: ${regionName}\n${langdata && langdata.PIDS ? langdata.PIDS:'PIDS'}: ${alarmdata.details.pidsname}\n${langdata && langdata.Channel ? langdata.Channel:'Channel'}: ${alarmdata.details.channel_name}\n${langdata && langdata.Chainage ? langdata.Chainage:'Chainage'}: ${alarmdata.details.locationDetails ? alarmdata.details.locationDetails.info : null}\n${langdata && langdata.Fiberlength ? langdata.Fiberlength:'Fiber Length'}: ${alarmdata.details.odMeter}\n${langdata && langdata.Latitude ? langdata.Latitude:'Latitude'}:${alarmdata && alarmdata.details.locationDetails && alarmdata.details.locationDetails.Lat}\n${langdata && langdata.Longitude ? langdata.Longitude:'Longitude'}: ${alarmdata && alarmdata.details.locationDetails && alarmdata.details.locationDetails.Long}`;
	}


	const columns = [
		
		{
			title: `${langdata && langdata.Id ? langdata.Id :'Id'}`,
			dataIndex: 'alarm_prm_id',
			key: 'alarm_prm_id',
			align: 'center',
			render: (text, record) => (
				<Space size="middle">
					{ record  && 
			<span>
				<span style={{ float: 'left', backgroundColor: record.alarmSeverity==3 ? 'red' :
					record.alarmSeverity==2 ? 'gold' : record.alarmSeverity==1 ? 'green' : record.alarmSeverity==0 ? 'violet' : '', 
				width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
			</span>
					}
					<text>{text}</text>
				</Space>
			)
		},
		{
			title: `${langdata && langdata.Activity ? langdata.Activity :'Activity'}`,
			dataIndex: 'eventType',
			key: 'eventType',
			//   render: (_,text) => <a onClick={showEditDrawer}>{text}</a>)

			render: (_, record) => (
				<Space size="middle">
					<a title={langdata && langdata.Moreinfo ? langdata.Moreinfo :'More info'} onClick={()=>{key(record);}}>{record.eventType}</a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)

		},
		{
			title: `${langdata && langdata.Region ? langdata.Region :'Region'}`,
			dataIndex: 'region',
			key: 'region',
		},
		{
			title: `${langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}`,
			dataIndex: 'pidsname',
			key: 'pidsname',
			
		},
		{
			title: `${langdata && langdata.Channel ? langdata.Channel :'Channel'}`,
			dataIndex: 'channel',
			key: 'channel',
		},
		{
			title: `${langdata && langdata.Type ? langdata.Type :'Type'}`,
			dataIndex: 'isparent',
			key: 'isparent',
		},
		{
			title: `${langdata && langdata.Description ? langdata.Description :'Description'}`,
			dataIndex: 'description',
			key: 'description',
			render: (text) => (
				<Popover title={langdata && langdata.Description ? langdata.Description :'Description'} content={text} trigger="hover">
					<Typography.Paragraph
						ellipsis={
							{
								rows: 2,
								expandable: false,
								symbol: ''
							}
						}
					>
						{text}
					</Typography.Paragraph>
				</Popover>
			)
		},
		{
			title: `${langdata && langdata.Date ? langdata.Date :'Date'}`,
			dataIndex: 'date',
			key: 'date',
			// render: (_, date) => (
			// 	<Space size="middle">
			// 		<p title="Edit Alarm">{ new Date(date.date).toLocaleDateString('en-UK', {
			// 			day: '2-digit',
			// 			month: '2-digit',
			// 			year: 'numeric'
			// 		})}</p>
			// 	</Space>
			// )
		},
		{
			title: `${langdata && langdata.Duration ? langdata.Duration :'Duration'}`,
			dataIndex: 'durations',
			key: 'durations',
		},
		
		{
			title: `${langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}`,
			dataIndex: 'chainage',
			key: 'chainage',
			render: (text) => (
				<Popover title={langdata && langdata.Chainage ? langdata.Chainage :'Chainage'} content={text} trigger="hover">
					<Typography.Paragraph
						ellipsis={
							{
								rows: 2,
								expandable: false,
								symbol: ''
							}
						}
					>
						{text}
					</Typography.Paragraph>
				</Popover>
			)
		},
		{
			title: `${langdata && langdata.FiberLength ? langdata.FiberLength :'Fiber Length (m)'}`,
			dataIndex: 'odMeter',
			key: 'odMeter',
		},
		{
			title: `${langdata && langdata.GPSCoordinates ? langdata.GPSCoordinates :'GPS Coordinates'}`,
			dataIndex: 'showGPS',
			key: 'showGPS',
		},
		{
			title: `${langdata && langdata.Status ? langdata.Status :'Status'}`,
			dataIndex: 'status',
			key: 'status',
			render: (_,  status ) => (
				<>
					{status &&
						<Tag color={status.alarmSeverity==3 ? 'red' :
							status.alarmSeverity==2 ? 'gold' : status.alarmSeverity==1 ? 'green' : status.alarmSeverity==0 ? 'violet' : ''}>
							{status.status}
						</Tag>}
				</>
			),
		}
	];
  
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		getAlarmData(1);
		getRegion(authtoken);
      

	},[authtoken]);


	const getAlarmData =(pagination)=>{

		setActiveExpRow([]);

		const related_alarm = state && state.reportFilter.related_alarm == false ? '&isparent=true' : '';
		
		let _pagination = { };
		_pagination.total = alarm_data_pagination.total ? alarm_data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;


		let data ={
			alarmSeverity: state && state.reportFilter.severity,
			allocationId: state && state.allocationId,
			endeventtime: state && state.toTime ? `${state.toTime}.000000` : `${moment.utc(new Date().setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
			eventenddate: state && state.toDate ? state.toDate : moment.utc(new Date().setHours(23,59,59,999)).format('YYYY-MM-DD') ,
			eventstartdate: state && state.fromDate ? state.fromDate : moment.utc(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD') ,
			eventtype: state && state.reportFilter.event.length > 0 ? state.reportFilter.event : ['all'],
			pidsId: state &&  state.reportFilter.pidsinfoId,
			region_id: state && state.reportFilter.regioninfoId,
			starteventtime: state && state.fromTime ? `${state.fromTime}.000000` : `${moment.utc(new Date().setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
			status: state && state.reportFilter.status,
			resolutionAdded: state && state.reportFilter.status==false ? false : null,
			frequency: state && state.reportFilter.frequency,
			eventchannel: state && state.reportFilter.channelId
		};

		dispatch({ type : REPORT_ALARM_LIST});
		setURL({base: `${api}/alarm`, params: `sort[eventdate]=-1&sort[eventtime]=-1${related_alarm}`, data:data});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/alarm?sort[eventdate]=-1&sort[eventtime]=-1${related_alarm}&limit=${_pagination.limit}&skip=${_pagination.skip}`,
			timeout: extendTimeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					alarm_data_pagination.total = response.data.total ? response.data.total : 0;
					alarm_data_pagination.limit = response.data.limit ? response.data.limit : 10;
					alarm_data_pagination.skip = response.data.skip ? response.data.skip : 0;
					alarm_data_pagination.current = pagination.current ? pagination.current : 1;
					dispatch({ type : REPORT_ALARM_LIST_SUCCESS, payload : response.data.data});
					data = response.data;
				}else {
					dispatch({ type : REPORT_ALARM_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : REPORT_ALARM_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};
  

	const key = (value) => {
		setModelOpen(true);
		dispatch({ type : REPORT_ALARM_DETAILS, payload : value});
	};

	const handleModelCancel = () => {
		setModelOpen(false);
	};

	const handleModelOk = (value) => { 
		navigate(`alarmDetails/${value}`);
	};

	const openShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(true);
	};
  
	const closeShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(false);
	};


	const expandedRowRender = () => {
		if(related_alarms_datas.length > 0){

			const columns = [
				{
					title: `${langdata && langdata.AlarmId ? langdata.AlarmId :'Alarm Id'}`,
					dataIndex: 'alarmId',
					key: 'alarmId',
					render: (text, record) => (
						<Space size="middle">
							{ record  && 
					<span>
						<span style={{ float: 'left', backgroundColor: record.alarmSeverity==3 ? 'red' :
							record.alarmSeverity==2 ? 'gold' : record.alarmSeverity==1 ? 'green' : record.alarmSeverity==0 ? 'violet' : '', 
						width: '3px', height: '20px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
					</span>
							}
							<text>{text}</text>
						</Space>
					),
				},
				{
					title: `${langdata && langdata.Activity ? langdata.Activity :'Activity'}`,
					dataIndex: 'activity',
					key: 'activity',
				},
				{
					title: `${langdata && langdata.Description ? langdata.Description :'Description'}`,
					dataIndex: 'description',
					key: 'description',
					render: (text) => (
						<Popover title={langdata && langdata.Description ? langdata.Description :'Description'} content={text} trigger="hover">
							<Typography.Paragraph
								ellipsis={
									{
										rows: 2,
										expandable: false,
										symbol: ''
									}
								}
							>
								{text}
							</Typography.Paragraph>
						</Popover>
					)
				},
				
				{
					title: `${langdata && langdata.Date ? langdata.Date :'Date'}`,
					dataIndex: 'date',
					key: 'date',
					// render: (_, date) => (
					// 	<Space size="middle">
					// 		<p title="Edit Alarm">{ new Date(date.date).toLocaleDateString('en-UK', {
					// 			day: '2-digit',
					// 			month: '2-digit',
					// 			year: 'numeric'
					// 		})}</p>
					// 	</Space>
					// )
				},
				
				{
					title: `${langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}`,
					dataIndex: 'chainage',
					key: 'chainage',
					render: (text) => (
						<Popover title={langdata && langdata.Chainage ? langdata.Chainage :'Chainage'} content={text} trigger="hover">
							<Typography.Paragraph
								ellipsis={
									{
										rows: 2,
										expandable: false,
										symbol: ''
									}
								}
							>
								{text}
							</Typography.Paragraph>
						</Popover>
					)
				},
				{
					title: `${langdata && langdata.Duration ? langdata.Duration :'Duration'}`,
					dataIndex: 'duration',
					key: 'duration',
				},
				{
					title: `${langdata && langdata.FiberLength ? langdata.FiberLength :'Fiber Length (m)'}`,
					dataIndex: 'fibrelength',
					key: 'fibrelength',
				},
				
				
			];

			return <Table onRow={() => ({
				style: {
					background: '#faf7f0',
				}
			})} columns={columns} dataSource={related_alarms_datas} pagination={false} 
			scroll={{
				y: 240,
			}} />;
		}else{
			return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

		}
		
	};

	const handleRealtedAlarm = (e,options) =>{
		setRelatedAlarms([]);
		activeExpRow === options.key ? setActiveExpRow() : setActiveExpRow(options.key);
		if(e==true){
			setAlarmDetails({id : options.alarm_prm_id, pidsinfoId: options.pidsinfoId});
		}
	};

	useEffect(()=>{
		if((alarmdetails && alarmdetails.id != undefined) && (alarmdetails && alarmdetails.id !== null) && (alarmdetails && alarmdetails.id != 'undefined')){
			dispatch({ type: RELATED_ALARMS_REPORT });
			let data = JSON.stringify({
				id: alarmdetails.id,
				pidsinfoId: alarmdetails.pidsinfoId,
				regioninfoId:null,
				allocationId: null,
			
			});
	
			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/relatedalarm`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
		
			axios.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch({ type : RELATED_ALARMS_REPORT_SUCCESS});
						setRelatedAlarms(response.data);
						
					}else {
						dispatch({ type : RELATED_ALARMS_REPORT_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : RELATED_ALARMS_REPORT_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});

		}

	},[alarmdetails]);

	let related_alarms_datas = [];
	
	if(relatedAlarms.length > 0){
		relatedAlarms.map( (item, index) => {
			const datetime = datetimeCovertertolocal(item.datetime);
			const newdate = datetime.localdate + ' ' +  datetime.localtime;
			related_alarms_datas.push({
				key: index.toString(),
				alarmId: item.alarm_prm_id,
				severity:item.alarmSeverity && item.alarmSeverity==3 ? 'RED' :  item.alarmSeverity==2 ? 'AMBER' : item.alarmSeverity==1 ? 'GREEN' : item.alarmSeverity==0 ? 'CLEAR' : '',
				activity: item.eventType,
				description:item.ruleDesc,
				date:newdate,
				duration:item.duration ? (item.duration/60).toFixed(2) + ' min' : null,
				channel: item.channel_name,
				chainage: item.locationDetails ? item.locationDetails.info : null,
				locationDetails:item.locationDetails && item.locationDetails,
				alarmSeverity:item.alarmSeverity,
				// pidsname:pidsData && pidsData.map((pids) => { if (item.pidsinfoId === pids.id){ return pids.name.toUpperCase();} }),
				region : regiondata && regiondata.map((region) => { if (item.regioninfoId[0] === region.id){ return region.regionName.toUpperCase();} }),
				fibrelength: item.odMeter,
			});

		});
	}else{
		related_alarms_datas = [];
	}

	return (
		<>
			<Content>
				<Row 
					style={{
						marginLeft:'15px',
						marginTop: '10px'
					}}            
				>
					<Breadcrumb
						items={[
							{
								href: '/dashboard',
								title: <HomeOutlined />,
							},
							{
								title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.AlarmsReport ? langdata.AlarmsReport :'Alarms Report'}</a> 
							},
							{
								title: `${langdata && langdata.AlarmsTable ? langdata.AlarmsTable :'Alarms Table'}`,
							},
						]}
					/>
				</Row>
        
				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24} style={{ margin : '10px'}}>
						{state.reportFilter.related_alarm == false && dashboardtype == 'oneview' ?
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={AlarmList} 
								pagination = { AlarmList.length > 0 ? alarm_data_pagination  : 0}
								onChange = { getAlarmData }
								expandable={{
									expandedRowKeys: activeExpRow,
									expandedRowRender,
									onExpand:(record,event)=>{handleRealtedAlarm(record,event);},
								}}
							// onRow={(record) => {
							// 	return {
							// 		onClick: () => { 
							// 			key(record);
							// 		}
							// 	};
							// }}
							/>
							:
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={AlarmList} 
								pagination = { AlarmList.length > 0 ? alarm_data_pagination  : 0}
								onChange = { getAlarmData }
							// onRow={(record) => {
							// 	return {
							// 		onClick: () => { 
							// 			key(record);
							// 		}
							// 	};
							// }}
							/>
						}
					</Col>
				</Row>

				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}}
				style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center',
					justifyContent:'center',
					display:'flex'
				}} 
				>
					<Col style={{
						alignItems:'left',
						justifyContent:'left',
						display:'flex',
						padding:'10px'
					}}  className="gutter-row" span={24}>
						<Button disabled={AlarmList && AlarmList.length > 0 ? false : true} type="primary" title={langdata && langdata.DownloadReport ? langdata.DownloadReport :'Download Report'} onClick={openShowFetchAndDownloadModal}><DownloadOutlined />{langdata && langdata.Download ? langdata.Download :'Download'}</Button>
					</Col>

				</Row>

				{/* Alarm Edit Starts*/}

    

   

				{/* Alarm Edit Ends */}
				<Modal
					open={modelOpen}
					title={langdata && langdata.AlarmDetails ? langdata.AlarmDetails :'Alarm Details'}
					onOk={handleModelOk}
					onCancel={handleModelCancel}
					footer={[
						<Row style={{marginTop:'25px'}} key={'id'} >
							<Col style={{justifyContent:'left', display:'flex'}} span={6}>
								<Button  key={'id'}
									onClick={() => {
										navigator.clipboard.writeText(contenttext);
										const args = {
											message: `${langdata && langdata.Alarmdetailscopiedtoclipboard ? langdata.Alarmdetailscopiedtoclipboard :'Alarm details copied to clipboard'}`,
											description: '',
											duration: 1,
										};
										notification.open(args);
									}}
									
								>
									{langdata && langdata.CopyToClipboard ? langdata.CopyToClipboard:'Copy To Clipboard'}
								</Button>
							</Col>
							{
								// eslint-disable-next-line react/jsx-key
							}
							<Col  span={18}>
								<Button onClick={handleModelCancel}>
									{langdata && langdata.Close ? langdata.Close:'Close'}
								</Button>
								{
								// eslint-disable-next-line react/jsx-key
								}

								<Button style={{marginLeft:'10px'}} type="primary" onClick={() => {navigate(( '/alarm-details'),{ state: { data: alarmdata.details, path: 'alarm' } });}}>
									{langdata && langdata.Moreinfo ? langdata.Moreinfo:'More info'}
								</Button>
							</Col>

						</Row>
					]}
				>
					<Divider/>
					<Row
						gutter={{
							xs: 8,
							sm: 16,
							md: 24,
							lg: 32,
						}}>

						<Col span={12}>          
							<Typography>{langdata && langdata.Id ? langdata.Id:'Id'}: {alarmdata && alarmdata.details && alarmdata.details.alarm_prm_id}</Typography>
							<Typography>{langdata && langdata.Activity ? langdata.Activity:'Activity'}: <b>{alarmdata && alarmdata.details && alarmdata.details.eventType}</b></Typography>
							<Typography>{langdata && langdata.Date ? langdata.Date:'Date'}: <b>{alarmdata && alarmdata.details &&  alarmdata.details.date}</b>
							</Typography>
							<Typography>{langdata && langdata.Latitude ? langdata.Latitude:'Latitude'}: {alarmdata && alarmdata.details && alarmdata.details.lat}</Typography>
							<Typography>{langdata && langdata.Longitude ? langdata.Longitude:'Longitude'}: {alarmdata && alarmdata.details && alarmdata.details.long}</Typography>
							<Typography>{langdata && langdata.Region ? langdata.Region:'Region'}: {alarmdata && alarmdata.details && alarmdata.details.region}</Typography>
							<Typography>{langdata && langdata.PIDS ? langdata.PIDS:'PIDS'}: {alarmdata && alarmdata.details && alarmdata.details.pidsname}</Typography>
							<Typography>{langdata && langdata.Channel ? langdata.Channel:'Channel'}: {alarmdata && alarmdata.details && alarmdata.details.channel_name}</Typography>
							<Typography>{langdata && langdata.Chainage ? langdata.Chainage:'Chainage'}: {alarmdata && alarmdata.details && alarmdata.details.chainage}</Typography>
							<Typography>{langdata && langdata.Fiberlength ? langdata.Fiberlength:'Fiber Length'}: {alarmdata && alarmdata.details && alarmdata.details.odMeter}{' m'}</Typography>
							<Typography>{langdata && langdata.Duration ? langdata.Duration:'Duration'}: {alarmdata && alarmdata.details && alarmdata.details.durations} </Typography>
							<Typography style={{color:alarmdata.details  && alarmdata.details.alarmSeverity === 0 ? 'CLEAR':
								alarmdata.details  && alarmdata.details.alarmSeverity === 1 ? 'green':
									alarmdata.details  && alarmdata.details.alarmSeverity === 2 ? '#fa9e0a' :
										alarmdata.details  && alarmdata.details.alarmSeverity === 3 ? 'red' : 
											'black'}}>{langdata && langdata.Severity ? langdata.Severity:'Severity'}: {alarmdata.details  && alarmdata.details.alarmSeverity && 
          (alarmdata.details  && alarmdata.details.alarmSeverity === 0 ? 'CLEAR':alarmdata.details  && alarmdata.details.alarmSeverity === 1 ? 'GREEN':alarmdata.details  && alarmdata.details.alarmSeverity === 2 ? 'AMBER' :alarmdata.details  && alarmdata.details.alarmSeverity === 3 ? 'RED' : '' )
								}</Typography>
							<Typography>{langdata && langdata.EventProbability ? langdata.EventProbability:'Event Probability'}: <b>{(alarmdata && alarmdata.details && alarmdata.details.eventprobablity ? alarmdata.details.eventprobablity *100 : 0).toFixed(2)}%</b></Typography>
							<Typography>{langdata && langdata.Resolutionstatus ? langdata.Resolutionstatus:'Resolution status'}: <b>{alarmdata && alarmdata.details && (alarmdata.details.status)}</b></Typography>
							<Typography>{langdata && langdata.Remark ? langdata.Remark:'Remark'}: {alarmdata && alarmdata.details && (alarmdata.details.remarks)}</Typography>
						</Col>
						<Col span={12}> 
							<img src={alarmdata && alarmdata.details && alarmdata.details.activityUrl} alt={`${alarmdata && alarmdata.details && alarmdata.details.eventType}`} align="right" />
						</Col>
						{/* <Col span={12}> 
							{alarmdata.details && alarmdata.details.eventType === 'Manual Digging' ? 
								<img src={mapImg} alt="manual digging" align="right" /> : 
								alarmdata.details && alarmdata.details.eventType === 'Machine Digging' ? 
									<img src={mapImgMachine} alt="Machine" align="right" /> : 
									alarmdata.details && alarmdata.details.eventType === 'Tunneling' ? 
										<img src={mapImgTunneling} alt="Tunneling" align="right" /> : 
										alarmdata.details && alarmdata.details.eventType === 'Vehicle Movement' ? 
											<img src={VehicleModal} alt="Tunneling" align="right" /> : 
											alarmdata && alarmdata.details && alarmdata.details.eventType === 'Drums Beating' ? 
												<img src={DrumModal} alt="Tunneling" align="right" /> : 
												alarmdata.details && alarmdata.details.eventType === 'Clamping' ? 
													<img src={ClampingModal} alt="Tunneling" align="right" /> : 
													alarmdata.details && alarmdata.details.eventType === 'Manual Digging - Deeper (1.5 to 3 feets)' ? 
														<img width="300px" src={mapImgDeep} alt="Manual Digging - Deeper (1.5 to 3 feets)" align="right" /> : 
														alarmdata.details && alarmdata.details.eventType === 'Man Movement' ? 
															<img src={modalMan} alt="Man Movement" align="right" /> : 
															<img src={modalDefault} alt="Unclassified" align="right" /> }
						</Col> */}
					</Row>
				</Modal>


				{/* Fetch and Download Modal Starts */}
				<Modal
					title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'}
					centered
					destroyOnClose={true}
					open={showFetchAndDownloadModal}
					onOk={{closeShowFetchAndDownloadModal}}
					onCancel={closeShowFetchAndDownloadModal}
					footer={[

					]}
					width={540}
				>
					<Row gutter={16}>
						<Col span={24}>
							<Typography>
								{langdata && langdata.Totalnumberofrecords ? langdata.Totalnumberofrecords:'Total number of records'} : {alarm_data_pagination.total}
							</Typography>
						</Col>
					</Row>

					{alarm_data_pagination.total > 0 ? (
						<BatchDownloader total={alarm_data_pagination.total} url={url}/>
					) : (
						<>
							<Row gutter={16} style={{
								backgroundColor: '#d3d3d3',
								padding: '5px',
								marginTop: '10px'
							}}>
								<Col span={24}>
									<Typography>{langdata && langdata.Norecordsfound ? langdata.Norecordsfound:'No records found.'}</Typography>
								</Col>
							</Row>
							<br/>
						</>
          
					)}
				</Modal>
				{/* Fetch and Download Modal Modal Ends */}

			</Content>
			<Spin fullscreen spinning={alarmdata.pending} tip={langdata && langdata.largeloadingmsg ? langdata.largeloadingmsg :'This process will take 2-3 minutes to complete. Please wait a moment.'} />

  
      
            


		</>
	);
}