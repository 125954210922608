import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import MyPDF from './MyPDF';
import { useLocation } from 'react-router-dom';

const MypdfGeneration = () => {
	
	const { state } = useLocation();
	
	return (
		<div>
			<PDFViewer style={{ width: '100%', height: '1000px' }}>
				<MyPDF jsonData={state&& state.data} />
			</PDFViewer>
		</div>
  
	);
};

export default MypdfGeneration;