import React, { useEffect, useReducer } from 'react';
import {Empty , Card, Col, Row, Typography, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import BlockOutlined from '@ant-design/icons';
import tlpalarmdata from './reducer';
import { TLP_ALARM, TLP_ALARM_ERROR, TLP_ALARM_SUCCESS } from './constants';
import moment from 'moment';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

export default function TLPLogs(props) {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const navigate = useNavigate();
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const initalState = {tlp_alarm: [], details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(tlpalarmdata, initalState);
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	// eslint-disable-next-line react/prop-types
	const pipelineChainageId = props.pipelineConfigId;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const offPSPEnabled = featuresModule.offPSPEnabled;
	const offCSPEnabled = featuresModule.offCSPEnabled;

	let current;
	let next;
	let previous;

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		
		getTlpData();
      

	},[authtoken]);

	const getTlpData = () => {

		dispatch({ type : TLP_ALARM});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			// eslint-disable-next-line react/prop-types
			url: `${api}/nearby-tlp?pipelineChainageId=${pipelineChainageId}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : TLP_ALARM_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : TLP_ALARM_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : TLP_ALARM_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	data && data.tlp_alarm.map((item,index)=>{
		// eslint-disable-next-line react/prop-types
		// eslint-disable-next-line react/prop-types
		if(item.pipelineChainageId == pipelineChainageId){
			current=index;
			
		}
		if(item.pipelineChainageId > pipelineChainageId){
			next = index;

		}
		if(item.pipelineChainageId < pipelineChainageId)
			previous = index;

	});

	
	return(
		data && data.tlp_alarm.length > 0 ?
			<Row gutter={16}>
				{data && data.tlp_alarm.length > 1 && (previous ==0 || previous) ? 
					<Col span={8}>
						<Card title='Previous Chainage' bordered={true} >
							<Typography>{langdata && langdata.ChainageKms ? langdata.ChainageKms : 'Chainage (Kms)'}: {parseFloat(data.tlp_alarm[previous ? previous : 0].pipelineChainage)/100	}</Typography>
							<Typography >{langdata && langdata.DeviceID ? langdata.DeviceID : 'Device id'}: {data.tlp_alarm[previous ? previous : 0].deviceId}</Typography>
							<Typography> {langdata && langdata.InstallatonDate ? langdata.InstallatonDate : 'Installaton date'}: {data.tlp_alarm[previous ? previous : 0].installedDate ?   moment.utc(data.tlp_alarm[previous ? previous : 0].installedDate).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[previous ? previous : 0].installedDate).local().format(settingstimeformat) : '' }</Typography>
							<Typography> {langdata && langdata.LastSync ? langdata.LastSync : 'Last Sync'}: {data.tlp_alarm[previous ? previous : 0].lastSync ?   moment.utc(data.tlp_alarm[previous ? previous : 0].lastSync).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[previous ? previous : 0].lastSync).local().format(settingstimeformat) : '' }</Typography>
							<Typography> {langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)'}:{data.tlp_alarm[previous ? previous : 0].vac2}</Typography>
							<Typography> {langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)'}: {data.tlp_alarm[previous ? previous : 0].vdc2 ? data.tlp_alarm[previous ? previous : 0].vdc2  : 0 }</Typography>
							<Typography> {langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)'}: {data.tlp_alarm[previous ? previous : 0].secondVoltage ? (data.tlp_alarm[previous ? previous : 0].vac1 ? data.tlp_alarm[previous ? previous : 0].vac1 :0) : 'NA' }</Typography>
							<Typography> {langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)'}:{data.tlp_alarm[previous ? previous : 0].secondVoltage ? (data.tlp_alarm[previous ? previous : 0].vdc1 ? data.tlp_alarm[previous ? previous : 0].vdc1 : 0) :'NA'}</Typography>
							{offPSPEnabled ?
								<>
									<Typography> {langdata && langdata.OFFPSPACPICKUPV ? langdata.OFFPSPACPICKUPV : 'OFF PSP AC PICKUP (V)'}:{data.tlp_alarm[previous ? previous : 0].offPSPac ? data.tlp_alarm[previous ? previous : 0].offPSPac : 0}</Typography>
									<Typography> {langdata && langdata.OFFPSPV ? langdata.OFFPSPV : 'OFF PSP (V)'}: {data.tlp_alarm[previous ? previous : 0].offPSPdc ? data.tlp_alarm[previous ? previous : 0].offPSPdc  : 0 }</Typography>
								</>
								: null }
							{offCSPEnabled?
								<>
									<Typography> {langdata && langdata.OFFCSPACPICKUP ? langdata.OFFCSPACPICKUP : 'OFF CSP AC PICKUP (V)'}: {data.tlp_alarm[previous ? previous : 0].secondVoltage ? (data.tlp_alarm[previous ? previous : 0].offCSPac ? data.tlp_alarm[previous ? previous : 0].offCSPac :0):'NA' }</Typography>
									<Typography> {langdata && langdata.OFFCSP ? langdata.OFFCSP : 'OFF CSP (V)'}:{data.tlp_alarm[previous ? previous : 0].secondVoltage ? (data.tlp_alarm[previous ? previous : 0].offCSPdc ? data.tlp_alarm[previous ? previous : 0].offCSPdc : 0) : 'NA'}</Typography>
								</>:null}

							<Typography> {langdata && langdata.BatteryV ? langdata.BatteryV : 'Battery (V)'}: {data.tlp_alarm[previous ? previous : 0].vbat ? data.tlp_alarm[previous ? previous : 0].vbat : 0}</Typography>
							<Typography> {langdata && langdata.CurrentmA ? langdata.CurrentmA : 'Current (mA)'}: {data.tlp_alarm[previous ? previous : 0].current ? data.tlp_alarm[previous ? previous : 0].current : 0}</Typography>
							<Typography> {langdata && langdata.CurrentACmA ? langdata.CurrentACmA : 'Current AC (mA)'}: {data.tlp_alarm[previous ? previous : 0].currentAc ? data.tlp_alarm[previous ? previous : 0].currentAc : 0}</Typography>
							{offPSPEnabled ?
								<Typography> {langdata && langdata.CurrentDCmA ? langdata.CurrentDCmA : 'Current DC (mA)'}: {data.tlp_alarm[previous ? previous : 0].currentDc ? data.tlp_alarm[previous ? previous : 0].currentDc : 0}</Typography>:
								null}

							<Typography>{langdata && langdata.Temp ? langdata.Temp : 'Temp'}: {data.tlp_alarm[previous ? previous : 0].temp ? data.tlp_alarm[previous ? previous : 0].temp : 0}</Typography>
							<Typography>{langdata && langdata.Solarpanelavailable ? langdata.Solarpanelavailable : 'Solar panel available'}: {data.tlp_alarm[previous ? previous : 0].hasSolarPanel == true ? 'YES' : 'NO' }</Typography>
							<Typography>{langdata && langdata.Anomalydetected ? langdata.Anomalydetected : 'Anomaly detected'}: {data.tlp_alarm[previous ? previous : 0].anomaly == true ? 'YES' : 'NO'}</Typography>
							<Typography>{langdata && langdata.Isvulnerable ? langdata.Isvulnerable : 'Is vulnerable'}: {data.tlp_alarm[previous ? previous : 0].isVulnerable == true ? 'YES' : 'NO' }</Typography>
							<Typography>{langdata && langdata.IsTRU ? langdata.IsTRU : 'Is TRU'}: {data.tlp_alarm[previous ? previous : 0].isTRU == true ? 'YES' : 'NO'}</Typography>
							<Typography> {langdata && langdata.TRUvoltageV ? langdata.TRUvoltageV : 'TRU voltage (V)'}: {data.tlp_alarm[previous ? previous : 0].truVoltage ? data.tlp_alarm[previous ? previous : 0].truVoltage : 0 }</Typography>
							<Typography> {langdata && langdata.TRUcorrectionvoltageV ? langdata.TRUcorrectionvoltageV : 'TRU correction voltage (V)'}: {data.tlp_alarm[previous ? previous : 0].truCorrectionVoltage ? data.tlp_alarm[previous ? previous : 0].truCorrectionVoltage  : 0 }</Typography>
							<Typography> {langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'}: {data.tlp_alarm[previous ? previous : 0].latitude ? data.tlp_alarm[previous ? previous : 0].latitude:0}</Typography>
							<Typography> {langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'}: {data.tlp_alarm[previous ? previous : 0].longitude ? data.tlp_alarm[previous ? previous : 0].longitude : 0}</Typography>
						</Card> 
					</Col>
					: null}
				
				{data && data.tlp_alarm.length > 0 && (current ==0 || current)   ? 

					<Col span={8}>
						<Card title="Current Chainage" bordered={true} >
							<Typography>{langdata && langdata.ChainageKms ? langdata.ChainageKms : 'Chainage (Kms)'}: {parseFloat(data.tlp_alarm[current ? current : 0].pipelineChainage)/100	}</Typography>
							<Typography >{langdata && langdata.DeviceID ? langdata.DeviceID : 'Device id'}: {data.tlp_alarm[current ? current : 0].deviceId}</Typography>
							<Typography> {langdata && langdata.InstallatonDate ? langdata.InstallatonDate : 'Installaton date'}: {data.tlp_alarm[current ? current : 0].installedDate ?   moment.utc(data.tlp_alarm[current ? current : 0].installedDate).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[current ? current : 0].installedDate).local().format(settingstimeformat) : '' }</Typography>
							<Typography> {langdata && langdata.LastSync ? langdata.LastSync : 'Last Sync'}: {data.tlp_alarm[current ? current : 0].lastSync ?   moment.utc(data.tlp_alarm[current ? current : 0].lastSync).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[current ? current : 0].lastSync).local().format(settingstimeformat) : '' }</Typography>
							<Typography> {langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)'}:{data.tlp_alarm[current ? current : 0].vac2}</Typography>
							<Typography> {langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)'}: {data.tlp_alarm[current ? current : 0].vdc2 ? data.tlp_alarm[current ? current : 0].vdc2  : 0 }</Typography>
							<Typography> {langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)'}: {data.tlp_alarm[current ? current : 0].secondVoltage ? (data.tlp_alarm[current ? current : 0].vac1 ? data.tlp_alarm[current ? current : 0].vac1 :0) : 'NA' }</Typography>
							<Typography> {langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)'}:{data.tlp_alarm[current ? current : 0].secondVoltage ? (data.tlp_alarm[current ? current : 0].vdc1 ? data.tlp_alarm[current ? current : 0].vdc1 : 0) :'NA'}</Typography>
							{offPSPEnabled ?
								<>
									<Typography> {langdata && langdata.OFFPSPACPICKUPV ? langdata.OFFPSPACPICKUPV : 'OFF PSP AC PICKUP (V)'}:{data.tlp_alarm[current ? current : 0].offPSPac ? data.tlp_alarm[current ? current : 0].offPSPac : 0}</Typography>
									<Typography> {langdata && langdata.OFFPSPV ? langdata.OFFPSPV : 'OFF PSP (V)'}: {data.tlp_alarm[current ? current : 0].offPSPdc ? data.tlp_alarm[current ? current : 0].offPSPdc  : 0 }</Typography>
								</>
								: null }
							{offCSPEnabled?
								<>
									<Typography> {langdata && langdata.OFFCSPACPICKUP ? langdata.OFFCSPACPICKUP : 'OFF CSP AC PICKUP (V)'}: {data.tlp_alarm[current ? current : 0].secondVoltage ? (data.tlp_alarm[current ? current : 0].offCSPac ? data.tlp_alarm[current ? current : 0].offCSPac :0):'NA' }</Typography>
									<Typography> {langdata && langdata.OFFCSP ? langdata.OFFCSP : 'OFF CSP (V)'}:{data.tlp_alarm[current ? current : 0].secondVoltage ? (data.tlp_alarm[current ? current : 0].offCSPdc ? data.tlp_alarm[current ? current : 0].offCSPdc : 0) : 'NA'}</Typography>
								</>:null}

							<Typography> {langdata && langdata.BatteryV ? langdata.BatteryV : 'Battery (V)'}: {data.tlp_alarm[current ? current : 0].vbat ? data.tlp_alarm[current ? current : 0].vbat : 0}</Typography>
							<Typography> {langdata && langdata.CurrentmA ? langdata.CurrentmA : 'Current (mA)'}: {data.tlp_alarm[current ? current : 0].current ? data.tlp_alarm[current ? current : 0].current : 0}</Typography>
							<Typography> {langdata && langdata.CurrentACmA ? langdata.CurrentACmA : 'Current AC (mA)'}: {data.tlp_alarm[current ? current : 0].currentAc ? data.tlp_alarm[current ? current : 0].currentAc : 0}</Typography>
							{offPSPEnabled ?
								<Typography> {langdata && langdata.CurrentDCmA ? langdata.CurrentDCmA : 'Current DC (mA)'}: {data.tlp_alarm[current ? current : 0].currentDc ? data.tlp_alarm[current ? current : 0].currentDc : 0}</Typography>:
								null}

							<Typography>{langdata && langdata.Temp ? langdata.Temp : 'Temp'}: {data.tlp_alarm[current ? current : 0].temp ? data.tlp_alarm[current ? current : 0].temp : 0}</Typography>
							<Typography>{langdata && langdata.Solarpanelavailable ? langdata.Solarpanelavailable : 'Solar panel available'}: {data.tlp_alarm[current ? current : 0].hasSolarPanel == true ? 'YES' : 'NO' }</Typography>
							<Typography>{langdata && langdata.Anomalydetected ? langdata.Anomalydetected : 'Anomaly detected'}: {data.tlp_alarm[current ? current : 0].anomaly == true ? 'YES' : 'NO'}</Typography>
							<Typography>{langdata && langdata.Isvulnerable ? langdata.Isvulnerable : 'Is vulnerable'}: {data.tlp_alarm[current ? current : 0].isVulnerable == true ? 'YES' : 'NO' }</Typography>
							<Typography>{langdata && langdata.IsTRU ? langdata.IsTRU : 'Is TRU'}: {data.tlp_alarm[current ? current : 0].isTRU == true ? 'YES' : 'NO'}</Typography>
							<Typography> {langdata && langdata.TRUvoltageV ? langdata.TRUvoltageV : 'TRU voltage (V)'}: {data.tlp_alarm[current ? current : 0].truVoltage ? data.tlp_alarm[current ? current : 0].truVoltage : 0 }</Typography>
							<Typography> {langdata && langdata.TRUcorrectionvoltageV ? langdata.TRUcorrectionvoltageV : 'TRU correction voltage (V)'}: {data.tlp_alarm[current ? current : 0].truCorrectionVoltage ? data.tlp_alarm[current ? current : 0].truCorrectionVoltage  : 0 }</Typography>
							<Typography> {langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'}: {data.tlp_alarm[current ? current : 0].latitude ? data.tlp_alarm[current ? current : 0].latitude:0}</Typography>
							<Typography> {langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'}: {data.tlp_alarm[current ? current : 0].longitude ? data.tlp_alarm[current ? current : 0].longitude : 0}</Typography>
						</Card>
					</Col>
					: null}

				{data && data.tlp_alarm.length > 1 && (next ==0 || next) ? 
					<Col span={8}>
						<Card title="Next Chainage" bordered={true} >
							<Typography>{langdata && langdata.ChainageKms ? langdata.ChainageKms : 'Chainage (Kms)'}: {parseFloat(data.tlp_alarm[next ? next : 0].pipelineChainage)/100	}</Typography>
							<Typography >{langdata && langdata.DeviceID ? langdata.DeviceID : 'Device id'}: {data.tlp_alarm[next ? next : 0].deviceId}</Typography>
							<Typography> {langdata && langdata.InstallatonDate ? langdata.InstallatonDate : 'Installaton date'}: {data.tlp_alarm[next ? next : 0].installedDate ?   moment.utc(data.tlp_alarm[next ? next : 0].installedDate).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[next ? next : 0].installedDate).local().format(settingstimeformat) : '' }</Typography>
							<Typography> {langdata && langdata.LastSync ? langdata.LastSync : 'Last Sync'}: {data.tlp_alarm[next ? next : 0].lastSync ?   moment.utc(data.tlp_alarm[next ? next : 0].lastSync).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[next ? next : 0].lastSync).local().format(settingstimeformat) : '' }</Typography>
							<Typography> {langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)'}:{data.tlp_alarm[next ? next : 0].vac2}</Typography>
							<Typography> {langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)'}: {data.tlp_alarm[next ? next : 0].vdc2 ? data.tlp_alarm[next ? next : 0].vdc2  : 0 }</Typography>
							<Typography> {langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)'}: {data.tlp_alarm[next ? next : 0].secondVoltage ? (data.tlp_alarm[next ? next : 0].vac1 ? data.tlp_alarm[next ? next : 0].vac1 :0) : 'NA' }</Typography>
							<Typography> {langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)'}:{data.tlp_alarm[next ? next : 0].secondVoltage ? (data.tlp_alarm[next ? next : 0].vdc1 ? data.tlp_alarm[next ? next : 0].vdc1 : 0) :'NA'}</Typography>
							{offPSPEnabled ?
								<>
									<Typography> {langdata && langdata.OFFPSPACPICKUPV ? langdata.OFFPSPACPICKUPV : 'OFF PSP AC PICKUP (V)'}:{data.tlp_alarm[next ? next : 0].offPSPac ? data.tlp_alarm[next ? next : 0].offPSPac : 0}</Typography>
									<Typography> {langdata && langdata.OFFPSPV ? langdata.OFFPSPV : 'OFF PSP (V)'}: {data.tlp_alarm[next ? next : 0].offPSPdc ? data.tlp_alarm[next ? next : 0].offPSPdc  : 0 }</Typography>
								</>
								: null }
							{offCSPEnabled?
								<>
									<Typography> {langdata && langdata.OFFCSPACPICKUP ? langdata.OFFCSPACPICKUP : 'OFF CSP AC PICKUP (V)'}: {data.tlp_alarm[next ? next : 0].secondVoltage ? (data.tlp_alarm[next ? next : 0].offCSPac ? data.tlp_alarm[next ? next : 0].offCSPac :0):'NA' }</Typography>
									<Typography> {langdata && langdata.OFFCSP ? langdata.OFFCSP : 'OFF CSP (V)'}:{data.tlp_alarm[next ? next : 0].secondVoltage ? (data.tlp_alarm[next ? next : 0].offCSPdc ? data.tlp_alarm[next ? next : 0].offCSPdc : 0) : 'NA'}</Typography>
								</>:null}

							<Typography> {langdata && langdata.BatteryV ? langdata.BatteryV : 'Battery (V)'}: {data.tlp_alarm[next ? next : 0].vbat ? data.tlp_alarm[next ? next : 0].vbat : 0}</Typography>
							<Typography> {langdata && langdata.CurrentmA ? langdata.CurrentmA : 'Current (mA)'}: {data.tlp_alarm[next ? next : 0].current ? data.tlp_alarm[next ? next : 0].current : 0}</Typography>
							<Typography> {langdata && langdata.CurrentACmA ? langdata.CurrentACmA : 'Current AC (mA)'}: {data.tlp_alarm[next ? next : 0].currentAc ? data.tlp_alarm[next ? next : 0].currentAc : 0}</Typography>
							{offPSPEnabled ?
								<Typography> {langdata && langdata.CurrentDCmA ? langdata.CurrentDCmA : 'Current DC (mA)'}: {data.tlp_alarm[next ? next : 0].currentDc ? data.tlp_alarm[next ? next : 0].currentDc : 0}</Typography>:
								null}

							<Typography>{langdata && langdata.Temp ? langdata.Temp : 'Temp'}: {data.tlp_alarm[next ? next : 0].temp ? data.tlp_alarm[next ? next : 0].temp : 0}</Typography>
							<Typography>{langdata && langdata.Solarpanelavailable ? langdata.Solarpanelavailable : 'Solar panel available'}: {data.tlp_alarm[next ? next : 0].hasSolarPanel == true ? 'YES' : 'NO' }</Typography>
							<Typography>{langdata && langdata.Anomalydetected ? langdata.Anomalydetected : 'Anomaly detected'}: {data.tlp_alarm[next ? next : 0].anomaly == true ? 'YES' : 'NO'}</Typography>
							<Typography>{langdata && langdata.Isvulnerable ? langdata.Isvulnerable : 'Is vulnerable'}: {data.tlp_alarm[next ? next : 0].isVulnerable == true ? 'YES' : 'NO' }</Typography>
							<Typography>{langdata && langdata.IsTRU ? langdata.IsTRU : 'Is TRU'}: {data.tlp_alarm[next ? next : 0].isTRU == true ? 'YES' : 'NO'}</Typography>
							<Typography> {langdata && langdata.TRUvoltageV ? langdata.TRUvoltageV : 'TRU voltage (V)'}: {data.tlp_alarm[next ? next : 0].truVoltage ? data.tlp_alarm[next ? next : 0].truVoltage : 0 }</Typography>
							<Typography> {langdata && langdata.TRUcorrectionvoltageV ? langdata.TRUcorrectionvoltageV : 'TRU correction voltage (V)'}: {data.tlp_alarm[next ? next : 0].truCorrectionVoltage ? data.tlp_alarm[next ? next : 0].truCorrectionVoltage  : 0 }</Typography>
							<Typography> {langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'}: {data.tlp_alarm[next ? next : 0].latitude ? data.tlp_alarm[next ? next : 0].latitude:0}</Typography>
							<Typography> {langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'}: {data.tlp_alarm[next ? next : 0].longitude ? data.tlp_alarm[next ? next : 0].longitude : 0}</Typography>
						</Card>
					</Col>
					: null}

			</Row>:
			<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
	);
}
