// import moment from 'moment';
import { PIPELINE_CONFIG_LIST, PIPELINE_CONFIG_LIST_SUCCESS, PIPELINE_CONFIG_LIST_ERROR }  from './constants';
import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';
import { TLP_DETAILS, TLP_DETAILS_SUCCESS, TLP_DETAILS_ERROR }  from './constants';
import { SAVE_TLP, SAVE_TLP_SUCCESS, SAVE_TLP_ERROR } from './constants';
import { UPDATE_TLP, UPDATE_TLP_SUCCESS, UPDATE_TLP_ERROR } from './constants';
import { DELETE_TLP, DELETE_TLP_SUCCESS, DELETE_TLP_ERROR } from './constants';
import { REPLACE_TLP, REPLACE_TLP_SUCCESS, REPLACE_TLP_ERROR } from './constants';
import { SET_TRU_VOLTAGE, SET_TRU_VOLTAGE_SUCCESS, SET_TRU_VOLTAGE_ERROR } from './constants';
import { UPDATE_TLP_FOTA, UPDATE_TLP_FOTA_SUCCESS, UPDATE_TLP_FOTA_ERROR } from './constants';
import { CANCEL_MANUAL_OVERRIDE, CANCEL_MANUAL_OVERRIDE_SUCCESS, CANCEL_MANUAL_OVERRIDE_ERROR } from './constants';
import { datetimeCovertertolocal } from '../../datetime-converter';

export default function tlpManagementReducer(state, action) {
	let pagination = {};
	let tlps = [];
	let chainages = [];
  
	switch (action.type) {
	case PIPELINE_CONFIG_LIST:
		return { ...state, loading: true, error: false };

	case PIPELINE_CONFIG_LIST_SUCCESS:
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				if (item.rouMarker === 'TLP') {
					let data = {};

					data.id = item.id ? parseInt(item.id) : 0;
					data.latitude = item.latitude ? item.latitude : 0;
					data.longitude = item.longitude ? item.longitude : 0;
					data.rouMarker = item.rouMarker ? item.rouMarker : '';
					data.smarttlp = item.smarttlp ? item.smarttlp : null;
					data.tlpId = item.tlpId ? item.tlpId : null;
					data.pipelineChainage = item.pipelineChainage ? item.pipelineChainage : 0;
					data.regioninfoId = item.regioninfoId;

					return chainages.push(data);
				} else {
					return false;
				}
			});

			let sortedPipelineChainages = chainages.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, chainages: sortedPipelineChainages, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, chainages: chainages, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case PIPELINE_CONFIG_LIST_ERROR:
		return { ...state, loading: false, error: true };
      
	case TLPS_LIST:
		return { ...state, loading: true, error: false };

	case TLPS_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				
				const offPSPdatetime = datetimeCovertertolocal(item ?  item.offPSPDateTime : '<Not Set>');
				const newoffPSPdatetime = offPSPdatetime.localdate + ' ' +  offPSPdatetime.localtime;				
				
				let data = {};

				data.id = item.id ? parseInt(item.id) : 0;
				data.regioninfoId = item.regioninfoId ? item.regioninfoId : 0;
				data.pipelineChainageId = item.pipelineChainageId ? item.pipelineChainageId : 0;
				data.pipelineChainageName = item.pipelineChainage ? item.pipelineChainage : '';
				data.pipelineChainage = item.pipelineChainage ?  parseFloat(item.pipelineChainage)/1000 : 0;
				data.deviceId = item.deviceId ?  item.deviceId : 0;
				data.deviceType = item.deviceType ? item.deviceType : 'tlp';
				data.lastSync = item.lastSync ?  item.lastSync : '';
				data.vac1 = item.vac1 ? parseFloat(item.vac1).toFixed(4) : 0;
				data.vac2 = item.vac2 ? parseFloat(item.vac2).toFixed(4) : 0;
				data.vdc1 = item.vdc1 ? parseFloat(item.vdc1).toFixed(4) : 0;
				data.vdc2 = item.vdc2 ? parseFloat(item.vdc2).toFixed(4) : 0;
				data.vac1Normal = item.vac1Normal ?(item.vac1Normal) : '0:0';
				data.vac2Normal = item.vac2Normal ? (item.vac2Normal) : '0:0';
				data.vdc1Normal = item.vdc1Normal ? (item.vdc1Normal) : '0:0';
				data.vdc2Normal = item.vdc2Normal ? (item.vdc2Normal): '0:0';
				data.vbat = item.vbat ? parseFloat(item.vbat).toFixed(2) : 0;
				data.temp = item.temp ? parseFloat(item.temp).toFixed(2) : 0;
				data.current = item.current ? parseFloat(item.current).toFixed(2) : 0;
				data.pollFrequencyInMinutes = item.pollFrequencyInMinutes ? item.pollFrequencyInMinutes : 0;
				data.anomalyPollFrequencyInMinutes = item.anomalyPollFrequencyInMinutes ? item.anomalyPollFrequencyInMinutes : 0;
				data.duration = item.duration ? item.duration : 0;
				data.retryCount = item.retryCount ? item.retryCount : 0;
				data.secondVoltage = item.secondVoltage ? item.secondVoltage : 0;
				data.anomaly = item.anomaly === true  ?  'Anomaly' : 'Normal';
				data.baseURL = item.baseURL ? item.baseURL : '';
				data.otaURL = item.otaURL ? item.otaURL : '';
				data.otaBinaryURL = item.otaBinaryURL ? item.otaBinaryURL : '';
				data.otaVersion = item.otaVersion ? item.otaVersion : 0;
				data.deviceOTAVersion = item.deviceOTAVersion ? item.deviceOTAVersion : 0;
				data.otaUpdateAvailable = item.otaUpdateAvailable ? item.otaUpdateAvailable : 0;
				data.hasSolarPanel = item.hasSolarPanel ? item.hasSolarPanel : false;
				data.installedDate = item.installedDate ? item.installedDate : null;
				data.isTRU = item.isTRU ? item.isTRU : false;
				data.midpointTLP = item.midpointTLP ? item.midpointTLP : 0;
				data.truVoltage = item.truVoltage ? item.truVoltage : 0;
				data.truCorrectionVoltage = item.truCorrectionVoltage ? item.truCorrectionVoltage : 0;
				data.isVulnerable = item.isVulnerable ? item.isVulnerable : false;
				data.latitude = item.latitude ? item.latitude : 0;
				data.longitude = item.longitude ? item.longitude : 0;
				data.createdAt = item.createdAt ? item.createdAt : null;
				data.updatedAt = item.updatedAt ? item.updatedAt : null;
				data.measureCurrent = item.measureCurrent ? item.measureCurrent : false;

				data.offPSPacNormal = item.offPSPacNormal ? (item.offPSPacNormal) : '0:0';
				data.offPSPdcNormal = item.offPSPdcNormal ? (item.offPSPdcNormal) : '0:0';
				data.offPSPDateTime = item.offPSPDateTime ? newoffPSPdatetime : null;
				data.onPSPTime = item.onPSPTime ? item.onPSPTime  : null;
				data.num1 = item.smsNumbers && item.smsNumbers.numbers ? item.smsNumbers.numbers[0] : null;
				data.num2 = item.smsNumbers && item.smsNumbers.numbers ? item.smsNumbers.numbers[1] : null;
				data.num3 = item.smsNumbers && item.smsNumbers.numbers ? item.smsNumbers.numbers[2] : null;
				data.offPSPEnabled = item.offPSPEnabled;
				data.offCSPEnabled = item.offCSPEnabled;
				data.offCSPacNormal = item.offCSPacNormal ? (item.offCSPacNormal) : '0:0';
				data.offCSPdcNormal = item.offCSPdcNormal ? (item.offCSPdcNormal) : '0:0';
				data.offPSPIntervalEnabled = item.offPSPIntervalEnabled;
				data.offPSPpollFrequencyMinutesEnabled = item.offPSPpollFrequencyMinutesEnabled;
				data.offPSPpollFrequencyMinutes = item.offPSPpollFrequencyMinutes;
				data.dataLoggingMode = item.dataLoggingMode;
				data.dataLoggingModePollFrequencyMinutes = item.dataLoggingModePollFrequencyMinutes;
				data.couponFactorAC = item.couponFactorAC;
				data.couponFactorDC = item.couponFactorDC;

				return tlps.push(data);
			});

			return { ...state, tlps: tlps, pagination: pagination, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, tlps: tlps, pagination: pagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case TLPS_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case TLP_DETAILS:
		return { ...state, loading: true, error: false };

	case TLP_DETAILS_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case TLP_DETAILS_ERROR:
		return { ...state, loading: false, error: true };

	case SAVE_TLP:
		return { ...state, loading: true, error: false };

	case SAVE_TLP_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case SAVE_TLP_ERROR:
		return { ...state, loading: false, error: true };

	case UPDATE_TLP:
		return { ...state, loading: true, error: false };

	case UPDATE_TLP_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case UPDATE_TLP_ERROR:
		return { ...state, loading: false, error: true };

	case DELETE_TLP:
		return { ...state, loading: true, error: false };

	case DELETE_TLP_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case DELETE_TLP_ERROR:
		return { ...state, loading: false, error: true };

	case CANCEL_MANUAL_OVERRIDE:
		return { ...state, loading: true, error: false };

	case CANCEL_MANUAL_OVERRIDE_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case CANCEL_MANUAL_OVERRIDE_ERROR:
		return { ...state, loading: false, error: true };

	case REPLACE_TLP:
		return { ...state, loading: true, error: false };

	case REPLACE_TLP_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case REPLACE_TLP_ERROR:
		return { ...state, loading: false, error: true };

	case SET_TRU_VOLTAGE:
		return { ...state, loading: true, error: false };

	case SET_TRU_VOLTAGE_SUCCESS:
		return { ...state, truDetails: action.payload, loading: false };

	case SET_TRU_VOLTAGE_ERROR:
		return { ...state, loading: false, error: true };

	case UPDATE_TLP_FOTA:
		return { ...state, loading: true, error: false };

	case UPDATE_TLP_FOTA_SUCCESS:
		return { ...state, loading: false };

	case UPDATE_TLP_FOTA_ERROR:
		return { ...state, loading: false, error: true };

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}