/* eslint-disable react/prop-types */
import React from 'react';
import { Document, Page, View, StyleSheet } from '@react-pdf/renderer';
import InvoiceComponent from './InvoiceComponent';

const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		backgroundColor: '#FFFFFF',
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
});

const MyPDF = (props) => {
		
	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.section}>
					<InvoiceComponent invoice={props.jsonData}/>
				</View>
			</Page>
		</Document>
	);
};

export default MyPDF;