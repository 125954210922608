/* eslint-disable react/prop-types */
import React from 'react';
import { StyleSheet, Image, Text, View } from '@react-pdf/renderer';
import InvoiceNo from './InvoiceNo';
import InvoiceItemsTable from './InvoiceItemsTable';
import logo from './favicon.png';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import frenchlan from '../../../french.json';
import malayalamlan from '../../../malayalam.json';

const styles = StyleSheet.create({
	page: {
		backgroundColor: '#fff',
		fontFamily: 'Helvetica',
		fontSize: 11,
		paddingTop: 30,
		paddingLeft: 50,
		paddingRight: 50,
		lineHeight: 1.5,
		flexDirection: 'column'
	},
	logo: {
		width: 55,
		height: 50
	},
	mainHeader: {
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop:'2px',
		fontSize:10
	},
	Header: {
		display: 'flex',
		flexDirection: 'row-reverse',
		// justifyContent: "space-between",
		alignItems: 'center',
		justifyContent:'center',
		alignContent:'center',
		marginLeft:'35%'
	}
});

const InvoiceComponent = (props) => {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? englan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? englan : langid == 6 ? malayalamlan : englan;

	return (
		<>
			<View>
				<Text style={styles.Header}>{langdata && langdata.STSAlarmReport ? langdata.STSAlarmReport:'STS Alarm Report'}</Text>
				<View style={styles.mainHeader}>
					<Image
						style={styles.logo}
						src={logo}
					/>
					<Text>{langdata && langdata.CompanyName ? langdata.CompanyName:'Company Name'}: {localStorage.getItem(`${dashboardtype.toUpperCase()}_PIPELINE_NAME`).toUpperCase().replace(/['"]/g,'')}</Text>

				</View>
				{/* <View style={styles.mainHeader}> */}

				<Text style={styles.mainHeader}>{langdata && langdata.Support ? langdata.Support:'Support'}: {'support@tranzmeo.com'}</Text>
				{/* </View> */}

				<InvoiceNo/>
				<InvoiceItemsTable invoice={props.invoice} />
			</View>
		</>
	);
};

export default InvoiceComponent;