import { React, useState, useEffect, useReducer } from 'react';
import { useAuth } from '../../Context';

import { Empty, DatePicker, Row, Col, Select, Typography, Button, Space, Table, Tooltip, Form, Input, Modal, Spin, QRCode, Checkbox, message, Popover, Avatar, Card, Switch } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PlusOutlined, EditOutlined, EyeOutlined, RotateLeftOutlined, QrcodeOutlined, UploadOutlined, DeleteOutlined, RotateRightOutlined, ControlOutlined } from '@ant-design/icons';

import { PIPELINE_CONFIG_LIST, PIPELINE_CONFIG_LIST_SUCCESS, PIPELINE_CONFIG_LIST_ERROR }  from './constants';
import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';
import { TLP_DETAILS, TLP_DETAILS_SUCCESS, TLP_DETAILS_ERROR } from './constants';
import { SAVE_TLP, SAVE_TLP_SUCCESS, SAVE_TLP_ERROR } from './constants';
import { UPDATE_TLP, UPDATE_TLP_SUCCESS, UPDATE_TLP_ERROR } from './constants';
import { DELETE_TLP, DELETE_TLP_SUCCESS, DELETE_TLP_ERROR } from './constants';
import { REPLACE_TLP, REPLACE_TLP_SUCCESS, REPLACE_TLP_ERROR } from './constants';
import { SET_TRU_VOLTAGE, SET_TRU_VOLTAGE_SUCCESS, SET_TRU_VOLTAGE_ERROR } from './constants';
import { UPDATE_TLP_FOTA, UPDATE_TLP_FOTA_SUCCESS, UPDATE_TLP_FOTA_ERROR } from './constants';
import { CANCEL_MANUAL_OVERRIDE, CANCEL_MANUAL_OVERRIDE_SUCCESS, CANCEL_MANUAL_OVERRIDE_ERROR } from './constants';

import tlpManagementReducer from './reducer';

import axios from 'axios';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { ToolOutlined, InfoOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const { Title, Text } = Typography;

export default function TLPManagement() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const tlp_feature = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES` , null));
	const {getRegion, regiondata} = useAuth();
	const [regionId, setRegionId] = useState(null);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const initalState = { chainages: [], tlps: [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, details: {}, truDetails: {}, loading: true, error: false };
	const [data, dispatch] = useReducer(tlpManagementReducer, initalState);

	const [showAddTLPModal, setShowAddTLPModal] = useState(false);
	const [showEditTLPModal, setShowEditTLPModal] = useState(false);
	const [showDeleteTLPModal, setShowDeleteTLPModal] = useState(false);
	const [showCancelManualOverrideModal, setShowCancelManualOverrideModal] = useState(false);
	const [showQRCodeModal, setShowQRCodeModal] = useState(false);
	const [showTRUVoltageModal, setShowTRUVoltageModal] = useState(false);
	const [showUploadFrimwareModal, setShowUploadFrimwareModal] = useState(false);
	const [showReplaceTLPModal, setShowReplaceTLPModal] = useState(false);

	const [chainage, setPipelineChiange] = useState(false);
	const [chainageId, setPipelineChiangeId] = useState(false);
	const [addRegionId, setAddRegionId] = useState();

	const [saveoffPSPDateTime, setSaveoffPSPDateTime] = useState();
	// const [saveonPSPTime, setSaveonPSPTime] = useState();
	const [updateoffPSPDateTime, setUpdateoffPSPDateTime] = useState();
	// const [updateonPSPTime, setUpdateonPSPTime] = useState();

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));
	const navigate = useNavigate();
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;

	const offPSPEnabled = featuresModule.offPSPEnabled;
	const offCSPEnabled = featuresModule.offCSPEnabled;
	const currentDensityEnabled = featuresModule.currentDensityEnabled;

	const columns = [
		{
			title: langdata && langdata.ChainageNameKms ? langdata.ChainageNameKms :  'Chainage Name (Kms)',
			dataIndex: 'pipelineChainage',
			key: 'pipelineChainage',
			render: (_, record) => (
				<>
					{/* TRU */}
					{ record.isTRU  && 
            <Space size="middle"><span style={{ float: 'left', backgroundColor: 'red' ,width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} /><span onClick={editTLPDetails}>{record.pipelineChainage}</span></Space>
					}

					{/* TLP with solar panel */}
					{ !record.isTRU && record.hasSolarPanel &&
            <Space size="middle"><span style={{ float: 'left', backgroundColor: 'green' ,width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} /><span onClick={editTLPDetails}>{record.pipelineChainage}</span></Space>
					}

					{/* TLP  with battery */}
					{ !record.isTRU && !record.hasSolarPanel &&
            <Space size="middle"><span style={{ float: 'left', backgroundColor: 'gold' ,width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} /><span onClick={editTLPDetails}>{record.pipelineChainage}</span></Space>
					}
				</>
			)
		},
		{
			title: langdata && langdata.DeviceID ? langdata.DeviceID : 'Device ID',
			dataIndex: 'deviceId',
			key: 'deviceId',
		},
		{
			title: langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)',
			dataIndex: 'vac2Normal',
			key: 'vac2Normal',
		},
		{
			title: langdata && langdata.OFFPSPACPICKUPV ? langdata.OFFPSPACPICKUPV : 'OFF PSP AC PICKUP (V)',
			dataIndex: 'offPSPacNormal',
			key: 'offPSPacNormal',
			hidden: offPSPEnabled ? false : true	
		},
		{
			title: langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)',
			dataIndex: 'vdc2Normal',
			key: 'vdc2Normal',
		},
		{
			title: langdata && langdata.OFFPSPV ? langdata.OFFPSPV : 'OFF PSP (V)',
			dataIndex: 'offPSPdcNormal',
			key: 'offPSPdcNormal',
			hidden: offPSPEnabled ? false : true,				
		},
		{
			title: langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)',
			dataIndex: 'vac1Normal',
			key: 'vac1Normal',
		},
		{
			title: langdata && langdata.OFFCSPACPICKUP ? langdata.OFFCSPACPICKUP : 'OFF CSP AC PICKUP (V)',
			dataIndex: 'offCSPacNormal',
			key: 'offCSPacNormal',
			hidden: offCSPEnabled ? false : true,				
		},
		{
			title: langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)',
			dataIndex: 'vdc1Normal',
			key: 'vdc1Normal',
		},
		{
			title: langdata && langdata.OFFCSP ? langdata.OFFCSP : 'OFF CSP (V)',
			dataIndex: 'offCSPdcNormal',
			key: 'offCSPdcNormal',
			hidden: offCSPEnabled ? false : true,				

		},
		{
			title: langdata && langdata.PollFrequency ? langdata.PollFrequency : 'Poll Frequency',
			dataIndex: 'pollFrequencyInMinutes',
			key: 'pollFrequencyInMinutes',
		},
		{
			title: langdata && langdata.FOTAVersion ? langdata.FOTAVersion : 'FOTA Version',
			dataIndex: 'otaVersion',
			key: 'otaVersion',
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (_, record) => (
				permission === 'superadmin' || permission === 'admin' ? (
					<Space size="middle">
						<a title={langdata && langdata.EditTLP ? langdata.EditTLP : 'Edit TLP'} onClick={editTLPDetails}><EditOutlined /></a>
						<a title={langdata && langdata.DeleteTLP ? langdata.DeleteTLP : 'Delete TLP'} onClick={openDeleteTLPModal}><DeleteOutlined /></a>
						<a title={langdata && langdata.ViewQRCode ? langdata.ViewQRCode : 'View QR Code'} onClick={openQRCodeDetails}><QrcodeOutlined /></a>
					
						{record.isTRU ? (
							<a title={langdata && langdata.SetTRUVoltage ? langdata.SetTRUVoltage : 'Set TRU Voltage'} onClick={openTRUVoltageModal}><ControlOutlined /></a>
						
						) : (
							<a title={langdata && langdata.SetTRUVoltage ? langdata.SetTRUVoltage : 'Set TRU Voltage'} disabled><ControlOutlined /></a>
						)}

						{record.isTRU ? (
							<a title={langdata && langdata.CancelManualOverride ? langdata.CancelManualOverride : 'Cancel Manual Override'} onClick={openCancelManualOverrideModal}><RotateLeftOutlined /></a>
						
						) : (
							<a title={langdata && langdata.CancelManualOverride ? langdata.CancelManualOverride : 'Cancel Manual Override'} disabled><RotateLeftOutlined /></a>
						)}

						<a title={langdata && langdata.UploadFOTA ? langdata.UploadFOTA : 'Upload FOTA'} onClick={openUploadFrimwareModal}><UploadOutlined /></a>
						<a title={langdata && langdata.ReplaceTLP ? langdata.ReplaceTLP : 'Replace TLP'} onClick={openRelaceTLPModal}><RotateRightOutlined /></a>
					</Space>
				) : (
					<Space size="middle">
						<a title={langdata && langdata.ViewTLP ? langdata.ViewTLP : 'View TLP'} onClick={editTLPDetails}><EyeOutlined /></a>
						<a title={langdata && langdata.ViewQRCode ? langdata.ViewQRCode : 'View QR Code'} onClick={openQRCodeDetails}><QrcodeOutlined /></a>
					</Space>
				)
			)
		}
	];

	let regions = [{ value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'}];
	let chainages = [];
	let unallocatedChainages = [];
	let midpointTLPs = [];
	let tlps = [];

	useEffect(() => {
		if(featuresModule.tlpMgmtEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 42);
	}, []);

	useEffect(() => {
		getRegion(authtoken);
		loadPipelineChainges();
		loadTLPs(1);
	}, [regionId]);

	regiondata && regiondata.map((region) => {
		return regions.push({ value: region.id, label: region.regionName.toUpperCase()});
	});

	const handleChangeRegion = (e) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`, e);
		setRegionId(e);
	};

	data && data.chainages && data.chainages.map((chainage) => {
		chainages.push({ value: chainage.id, label: 'CH - ' + chainage.pipelineChainage + ' (TLP)'});
		if (chainage.smarttlp === null) {
			unallocatedChainages.push({ value: chainage.id, label: 'CH - ' + chainage.pipelineChainage + ' (TLP)', pipelineChainage : chainage.pipelineChainage, regionId : chainage.regioninfoId});
		}

		return true;
	});

	data && data.tlps && data.tlps.map((tlp) => {
		if (!tlp.isTRU) {
			midpointTLPs.push({ value: tlp.id, label: 'CH - ' + tlp.pipelineChainage });
		}

		if (!tlp.isTRU) {
			tlps.push({ value: tlp.deviceId, label: 'TLP - ' + tlp.deviceId });
		} else {
			tlps.push({ value: tlp.deviceId, label: 'TRU - ' + tlp.deviceId });
		}

		return true;
	});

	const loadPipelineChainges = () => {

		const pipelineParams = `&pipelineId=${pipelineId}`;

		const url = `${api}/pipelineconfig?rouMarker=TLP&$limit=10000&$sort[pipelineChainage]= 1${pipelineParams}`;


		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		dispatch({ type: PIPELINE_CONFIG_LIST });
    
		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: PIPELINE_CONFIG_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: PIPELINE_CONFIG_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: PIPELINE_CONFIG_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadTLPs = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		dispatch({ type: TLPS_LIST });

		let url = `${api}/smarttlp?$limit=${_pagination.limit}&$skip=${_pagination.skip}&$sort[pipelineChainage]=1`;

		if (regionId && regionId !== null) {
			url = `${api}/smarttlp?$limit=${_pagination.limit}&$skip=${_pagination.skip}&regioninfoId=${regionId}&$sort[pipelineChainage]=1`;
		}

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: TLPS_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize  });
			} else {
				dispatch( { type: TLPS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: TLPS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadTLPDetails = ((id) =>  {
		let details = null;

		dispatch({ type: TLP_DETAILS });

		if (data && data.tlps) {
			data.tlps && data.tlps.map((item) => {
				if (item.id === id) {
					details = item;
				}

				return true;
			});
      
			if (details) {
				let ch = chainages.find(ch => ch.value === details.pipelineChainageId);

				if (ch) {
					details.selectedPipelineChainageId = ch.value;
				}
			}

			dispatch({ type: TLP_DETAILS_SUCCESS, payload: details });
		} else {
			dispatch({ type: TLP_DETAILS_ERROR });
		}
	});

	const closeAddTLPModal = () => {
		setShowAddTLPModal(false);
	};

	const addTLPDetails = () => {
		setShowAddTLPModal(true);
	};

	const closeEditTLPModal = () => {
		setShowEditTLPModal(false);
	};

	const editTLPDetails = () => {
		setShowEditTLPModal(true);
	};

	const openDeleteTLPModal = () => {
		setShowDeleteTLPModal(true);
	};

	const openCancelManualOverrideModal = () => {
		setShowCancelManualOverrideModal(true);
	};

	const closeCancelManualOverrideModal = () => {
		setShowCancelManualOverrideModal(false);
	};

	const closeDeleteTLPModal = () => {
		setShowDeleteTLPModal(false);
	};

	const openQRCodeDetails = () => {
		setShowQRCodeModal(true);
	};

	const closeTLPQRCodeModal = () => {
		setShowQRCodeModal(false);
	};

	const openTRUVoltageModal = () => {
		setShowTRUVoltageModal(true);
	};

	const closeTRUVoltageModal = () => {
		setShowTRUVoltageModal(false);
	};

	const openUploadFrimwareModal = () => {
		setShowUploadFrimwareModal(true);
	};

	const closeUploadFrimwareModal = () => {
		setShowUploadFrimwareModal(false);
	};

	const openRelaceTLPModal = () => {
		setShowReplaceTLPModal(true);
	};

	const closeReplaceTLPModal = () => {
		setShowReplaceTLPModal(false);
	};

	const saveTLPFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			regioninfoId: regionId,
			pipelineChainageId: 0,
			pipelineChainage: 0,
			manuallyAddDevice: true,
			deviceId: 0,
			installedDate: dayjs().format('YYYY/MM/DD'),
			latitude: 0,
			longitude: 0,
			isTRU: false,
			midpointTLP: null,
			vdc1NormalMin: 0,
			vdc1NormalMax: 0,
			vac1NormalMin: 0,
			vac1NormalMax: 0,
			vdc2NormalMin: 0,
			vdc2NormalMax: 0,
			vac2NormalMin: 0,
			vac2NormalMax: 0,
			pollFrequencyInMinutes: 10,
			anomalyPollFrequencyInMinutes: 10,
			retryCount: 1,
			secondVoltage: false,
			hasSolarPanel: false,
			measureCurrent: false,
			otaUpdateAvailable: false,
			otaVersion: 0,
			offPSPacNormalMin: 0,
			offPSPacNormalMax: 0,
			offPSPdcNormalMin: 0,
			offPSPdcNormalMax: 0,
			onPSPTime : null,
			offPSPDateTime : null,
			num1 : null,
			num2 : null,
			num3 : null,
			offPSPEnabled : false,
			offCSPEnabled : false,
			offCSPacNormalMin : 0,
			offCSPacNormalMax : 0,
			offCSPdcNormalMin : 0,
			offCSPdcNormalMax : 0,
			offPSPIntervalEnabled : false,
			offPSPpollFrequencyMinutesEnabled : false,
			offPSPpollFrequencyMinutes : 1,
			dataLoggingMode : false,
			dataLoggingModePollFrequencyMinutes : 1,
			couponFactorAC : null,
			couponFactorDC : null
		},

		onSubmit: (values) => {
			const pipelineChainageId = chainageId ? chainageId : 0;
			const pipelineChainage = chainage ? chainage : 0;
			const deviceId = values.deviceId ? values.deviceId : 0;
			const installedDate = values.installedDate ? values.installedDate : dayjs().format('YYYY/MM/DD');
			const isTRU = values.isTRU ? values.isTRU : false;
			const midpointTLP = values.midpointTLP ? values.midpointTLP : null;
			const vdc1NormalMin = values.vdc1NormalMin ? values.vdc1NormalMin : 0;
			const vdc1NormalMax = values.vdc1NormalMax ? values.vdc1NormalMax : 0;
			const vac1NormalMin = values.vac1NormalMin ? values.vac1NormalMin : 0;
			const vac1NormalMax = values.vac1NormalMax ? values.vac1NormalMax : 0;
			const vdc2NormalMin = values.vdc2NormalMin ? values.vdc2NormalMin : 0;
			const vdc2NormalMax = values.vdc2NormalMax ? values.vdc2NormalMax : 0;
			const vac2NormalMin = values.vac2NormalMin ? values.vac2NormalMin : 0;
			const vac2NormalMax = values.vac2NormalMax ? values.vac2NormalMax : 0;
			const pollFrequencyInMinutes = values.pollFrequencyInMinutes ? values.pollFrequencyInMinutes : 10;
			const anomalyPollFrequencyInMinutes = values.anomalyPollFrequencyInMinutes ? values.anomalyPollFrequencyInMinutes : 10;
			const retryCount = values.retryCount ? values.retryCount : 1;
			const secondVoltage = values.secondVoltage ? values.secondVoltage : false;
			const hasSolarPanel = values.hasSolarPanel ? values.hasSolarPanel : false;
			// const measureCurrent = values.measureCurrent ? values.measureCurrent : false;
			const otaUpdateAvailable = values.otaUpdateAvailable ? values.otaUpdateAvailable : false;
			const otaVersion = values.otaVersion ? values.otaVersion : 0;
			const regioninfoId = addRegionId ? addRegionId : 0;
			const offPSPacNormalMin = values.offPSPacNormalMin ? values.offPSPacNormalMin : 0;
			const offPSPacNormalMax = values.offPSPacNormalMax ? values.offPSPacNormalMax : 0;
			const offPSPdcNormalMin = values.offPSPdcNormalMin ? values.offPSPdcNormalMin : 0;
			const offPSPdcNormalMax = values.offPSPdcNormalMax ? values.offPSPdcNormalMax : 0;
			// const onPSPTime = '';			
			const offPSPDateTime = saveoffPSPDateTime ?  moment.utc(saveoffPSPDateTime).toISOString() : null;		
			const num1 = values.num1;
			const num2 = values.num2;						
			const num3 = values.num3;

			const offPSPEnabledValue = values.offPSPEnabled;
			const offCSPEnabledValue  = values.offCSPEnabled;
			const offCSPacNormalMin  = values.offCSPacNormalMin;
			const offCSPacNormalMax = values.offCSPacNormalMax;
			const offCSPdcNormalMin = values.offCSPdcNormalMin;
			const offCSPdcNormalMax = values.offCSPdcNormalMax;
			const offPSPIntervalEnabled = values.offPSPIntervalEnabled;
			const offPSPpollFrequencyMinutesEnabled = values.offPSPpollFrequencyMinutesEnabled;
			let offPSPpollFrequencyMinutes = values.offPSPpollFrequencyMinutes;
			const dataLoggingMode = values.dataLoggingMode;
			const dataLoggingModePollFrequencyMinutes = values.dataLoggingModePollFrequencyMinutes;
			const couponFactorAC = values.couponFactorAC;
			const couponFactorDC = values.couponFactorDC;

			if( !offPSPpollFrequencyMinutesEnabled && offPSPpollFrequencyMinutes > 180 ){				
				Modal.warning({ title: langdata && langdata.ThemaximumOFFPSPpollfrequencyis180days ? langdata.ThemaximumOFFPSPpollfrequencyis180days : 'The maximum OFF PSP poll frequency is 180 days.' });
				return false;
			}

			if(offPSPpollFrequencyMinutesEnabled == false){
				offPSPpollFrequencyMinutes = values.offPSPpollFrequencyMinutes*24*60 ;
			}

			// Latitude and longitude will be obtained from the slected pipeline chainage.
			let latitude = 0;
			let longitude = 0;
      
			if (!pipelineChainageId || parseInt(pipelineChainageId) <= 0) {
				Modal.warning({ title: langdata && langdata.Pleaseselectavalidpipelinechainage ? langdata.Pleaseselectavalidpipelinechainage : 'Please select a valid pipeline chainage.' });
				return false;
			} else {
				let selectedPipelineChainage = data.chainages.find((chainage) => {
					return chainage.id === pipelineChainageId;
				});

				if (selectedPipelineChainage) {
					latitude = selectedPipelineChainage.latitude;
					longitude = selectedPipelineChainage.longitude;
				}
			}

			if (!deviceId || parseInt(deviceId) <= 0) {
				Modal.warning({ title: langdata && langdata.Thedeviceidisinvalid ? langdata.Thedeviceidisinvalid : 'The device id is invalid.' });
				return false;
			}

			if (isTRU && midpointTLP === null) {
				Modal.warning({ title: langdata && langdata.PleaseselectamidpointTLPfortheTRU ? langdata.PleaseselectamidpointTLPfortheTRU : 'Please select a mid point TLP for the TRU.' });
				return false;
			}

			let parseData = {
				regioninfoId: regioninfoId,
				pipelineChainageId: pipelineChainageId,
				pipelineChainage: pipelineChainage.toString(),
				deviceId: deviceId.toString(),
				device_id: deviceId.toString(),
				installedDate: installedDate,
				latitude: latitude,
				longitude: longitude,
				isTRU: isTRU,
				midpointTLP: midpointTLP,
				vdc1Normal: vdc1NormalMin + ':' + vdc1NormalMax,
				vac1Normal: vac1NormalMin + ':' + vac1NormalMax,
				vdc2Normal: vdc2NormalMin + ':' + vdc2NormalMax,
				vac2Normal: vac2NormalMin + ':' + vac2NormalMax,
				pollFrequencyInMinutes: pollFrequencyInMinutes,
				anomalyPollFrequencyInMinutes: anomalyPollFrequencyInMinutes,
				retryCount: retryCount,
				secondVoltage: secondVoltage,
				hasSolarPanel: hasSolarPanel,
				// measureCurrent: measureCurrent,
				otaUpdateAvailable: otaUpdateAvailable,
				otaVersion: otaVersion,
				pipelineId: localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`)) : null,
				smsNumbers : {
					numbers: [
						num1,
						num2,
						num3
					]
				},
				dataLoggingMode : dataLoggingMode,
				dataLoggingModePollFrequencyMinutes : dataLoggingModePollFrequencyMinutes
			};

			if(offPSPEnabled) {
				parseData['offPSPacNormal'] = offPSPacNormalMin + ':' + offPSPacNormalMax;
				parseData['offPSPdcNormal'] = offPSPdcNormalMin + ':' + offPSPdcNormalMax;
				// parseData['onPSPTime'] = onPSPTime;
				parseData['offPSPDateTime'] = offPSPDateTime;			
				parseData['offPSPEnabled'] = offPSPEnabledValue;
				parseData['offPSPIntervalEnabled'] = offPSPIntervalEnabled;
				parseData['offPSPpollFrequencyMinutesEnabled'] = offPSPpollFrequencyMinutesEnabled;
				parseData['offPSPpollFrequencyMinutes'] = offPSPpollFrequencyMinutes;
			}		
			
			if(offCSPEnabled){
				parseData['offCSPEnabled'] = offCSPEnabledValue;
				parseData['offCSPacNormal'] = offCSPacNormalMin + ':' + offCSPacNormalMax;
				parseData['offCSPdcNormal'] = offCSPdcNormalMin + ':' + offCSPdcNormalMax;
			}

			if(currentDensityEnabled) {
				parseData['couponFactorAC'] = couponFactorAC;
				parseData['couponFactorDC'] = couponFactorDC;
			}

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/smarttlp`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: parseData
			};
      
			dispatch( { type: SAVE_TLP });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: SAVE_TLP_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.Deviceconfigurationsavedsuccessfully ? langdata.Deviceconfigurationsavedsuccessfully : 'Device configuration saved successfully.' });
						closeAddTLPModal();
						loadTLPs({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					} else {
						dispatch({ type: SAVE_TLP_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletosavethedeviceconfiguration ? langdata.Unabletosavethedeviceconfiguration : 'Unable to save the device configuration.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: SAVE_TLP_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}else{
						Modal.warning({ title: langdata && langdata.Unabletoadd ? langdata.Unabletoadd : 'Unable to add.' });
					}
				});
		}
	});

	const updateTLPFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			chainages: data.chainages ? data.chainages : [],
			pipelineChainageId: data.details ? data.details.pipelineChainageId : 0,
			installedDate: data.details ? data.details.installedDate : 0,
			deviceId: data.details ? data.details.deviceId : 0,
			isTRU: data.details ? data.details.isTRU : false,
			midpointTLP: data.details ? data.details.midpointTLP : null,
			vdc1NormalMin: data.details && data.details.vdc1Normal ? data.details.vdc1Normal.split(':')[0] : 0,
			vdc1NormalMax: data.details && data.details.vdc1Normal ? data.details.vdc1Normal.split(':')[1] : 0,
			vac1NormalMin: data.details && data.details.vac1Normal ? data.details.vac1Normal.split(':')[0] : 0,
			vac1NormalMax: data.details && data.details.vac1Normal ? data.details.vac1Normal.split(':')[1] : 0,
			vdc2NormalMin: data.details && data.details.vdc2Normal ? data.details.vdc2Normal.split(':')[0] : 0,
			vdc2NormalMax: data.details && data.details.vdc2Normal ? data.details.vdc2Normal.split(':')[1] : 0,
			vac2NormalMin: data.details && data.details.vac2Normal ? data.details.vac2Normal.split(':')[0] : 0,
			vac2NormalMax: data.details && data.details.vac2Normal ? data.details.vac2Normal.split(':')[1] : 0,
			pollFrequencyInMinutes: data.details ? data.details.pollFrequencyInMinutes : 0,
			anomalyPollFrequencyInMinutes: data.details ? data.details.anomalyPollFrequencyInMinutes : 0,
			retryCount: data.details ? data.details.retryCount : 0,
			secondVoltage: data.details ? data.details.secondVoltage : false,
			hasSolarPanel: data.details ? data.details.hasSolarPanel : false,
			measureCurrent: data.details ? data.details.measureCurrent : false,
			otaUpdateAvailable: data.details ? data.details.otaUpdateAvailable : false,
			otaVersion: data.details ? data.details.otaVersion : 0,
			offPSPacNormalMin: data.details && data.details.offPSPacNormal ? data.details.offPSPacNormal.split(':')[0] : 0,
			offPSPacNormalMax: data.details && data.details.offPSPacNormal ? data.details.offPSPacNormal.split(':')[1] : 0,
			offPSPdcNormalMin: data.details && data.details.offPSPdcNormal ? data.details.offPSPdcNormal.split(':')[0] : 0,
			offPSPdcNormalMax: data.details && data.details.offPSPdcNormal ? data.details.offPSPdcNormal.split(':')[1] : 0,
			offPSPDateTime : data.details ? data.details.offPSPDateTime : null,
			onPSPTime : data.details && data.details.onPSPTime ? data.details.onPSPTime : null,
			num1 : data.details && data.details.num1 ? data.details.num1 :  null,
			num2 : data.details && data.details.num2 ? data.details.num2 : null,
			num3 : data.details && data.details.num3 ? data.details.num3 : null,
			offPSPEnabled : data.details && data.details.offPSPEnabled ? data.details.offPSPEnabled : false,
			offCSPEnabled : data.details && data.details.offCSPEnabled ? data.details.offCSPEnabled : false,
			offCSPacNormalMin : data.details && data.details.offCSPacNormal ? data.details.offCSPacNormal.split(':')[0] : 0,
			offCSPacNormalMax : data.details && data.details.offCSPacNormal ? data.details.offCSPacNormal.split(':')[1] : 0,
			offCSPdcNormalMin : data.details && data.details.offCSPdcNormal ? data.details.offCSPdcNormal.split(':')[0] : 0,
			offCSPdcNormalMax : data.details && data.details.offCSPdcNormal ? data.details.offCSPdcNormal.split(':')[1] : 0,
			offPSPIntervalEnabled : data.details && data.details.offPSPIntervalEnabled ? data.details.offPSPIntervalEnabled : false,
			offPSPpollFrequencyMinutesEnabled : data.details && data.details.offPSPpollFrequencyMinutesEnabled ? data.details.offPSPpollFrequencyMinutesEnabled : false,
			offPSPpollFrequencyMinutes : data.details && data.details.offPSPpollFrequencyMinutes ? data.details.offPSPpollFrequencyMinutes : 1,
			dataLoggingMode : data.details && data.details.dataLoggingMode ? data.details.dataLoggingMode : false,
			dataLoggingModePollFrequencyMinutes : data.details && data.details.dataLoggingModePollFrequencyMinutes ? data.details.dataLoggingModePollFrequencyMinutes : 1,
			couponFactorAC : data.details && data.details.couponFactorAC ? data.details.couponFactorAC : null,
			couponFactorDC : data.details && data.details.couponFactorDC ? data.details.couponFactorDC : null
		},

		onSubmit: (values) => {
			const id = data.details ? data.details.id : 0;
			const deviceId = values.deviceId ? values.deviceId : 0;
			const isTRU = values.isTRU ? values.isTRU : false;
			const midpointTLP = values.midpointTLP ? values.midpointTLP : null;
			const vdc1NormalMin = values.vdc1NormalMin ? values.vdc1NormalMin : 0;
			const vdc1NormalMax = values.vdc1NormalMax ? values.vdc1NormalMax : 0;
			const vac1NormalMin = values.vac1NormalMin ? values.vac1NormalMin : 0;
			const vac1NormalMax = values.vac1NormalMax ? values.vac1NormalMax : 0;
			const vdc2NormalMin = values.vdc2NormalMin ? values.vdc2NormalMin : 0;
			const vdc2NormalMax = values.vdc2NormalMax ? values.vdc2NormalMax : 0;
			const vac2NormalMin = values.vac2NormalMin ? values.vac2NormalMin : 0;
			const vac2NormalMax = values.vac2NormalMax ? values.vac2NormalMax : 0;
			const pollFrequencyInMinutes = values.pollFrequencyInMinutes ? values.pollFrequencyInMinutes : 0;
			const anomalyPollFrequencyInMinutes = values.anomalyPollFrequencyInMinutes ? values.anomalyPollFrequencyInMinutes : 0;
			const retryCount = values.retryCount ? values.retryCount : 0;
			const secondVoltage = values.secondVoltage ? values.secondVoltage : false;
			const hasSolarPanel = values.hasSolarPanel ? values.hasSolarPanel : false;
			// const measureCurrent = values.measureCurrent ? values.measureCurrent : false;
			const otaUpdateAvailable = values.otaUpdateAvailable ? values.otaUpdateAvailable : false;
			const otaVersion = values.otaVersion ? values.otaVersion : 0;

			const offPSPacNormalMin = values.offPSPacNormalMin ? values.offPSPacNormalMin : 0;
			const offPSPacNormalMax = values.offPSPacNormalMax ? values.offPSPacNormalMax : 0;
			const offPSPdcNormalMin = values.offPSPdcNormalMin ? values.offPSPdcNormalMin : 0;
			const offPSPdcNormalMax = values.offPSPdcNormalMax ? values.offPSPdcNormalMax : 0;
			// const onPSPTime = values.onPSPTime;
			const offPSPDateTime = updateoffPSPDateTime ? moment.utc(updateoffPSPDateTime).toISOString() :  moment.utc(updateTLPFormik.values && updateTLPFormik.values.offPSPDateTime).toISOString();		
			const num1 = values.num1 ? values.num1 : null;
			const num2 = values.num2 ? values.num2 : null;						
			const num3 = values.num3 ? values.num3 : null;

			const offPSPEnabledValue = values.offPSPEnabled;
			const offCSPEnabledValue  = values.offCSPEnabled;
			const offCSPacNormalMin  = values.offCSPacNormalMin;
			const offCSPacNormalMax = values.offCSPacNormalMax;
			const offCSPdcNormalMin = values.offCSPdcNormalMin;
			const offCSPdcNormalMax = values.offCSPdcNormalMax;
			const offPSPIntervalEnabled = values.offPSPIntervalEnabled;
			const offPSPpollFrequencyMinutesEnabled = values.offPSPpollFrequencyMinutesEnabled;
			let offPSPpollFrequencyMinutes = values.offPSPpollFrequencyMinutes;
			const dataLoggingMode = values.dataLoggingMode;
			const dataLoggingModePollFrequencyMinutes = values.dataLoggingModePollFrequencyMinutes;
			const couponFactorAC = values.couponFactorAC;
			const couponFactorDC = values.couponFactorDC;

			if( !offPSPpollFrequencyMinutesEnabled && offPSPpollFrequencyMinutes > 180 ){				
				Modal.warning({ title: langdata && langdata.ThemaximumOFFPSPpollfrequencyis180days ? langdata.ThemaximumOFFPSPpollfrequencyis180days : 'The maximum OFF PSP poll frequency is 180 days.' });
				return false;
			}

			if(offPSPpollFrequencyMinutesEnabled == false){
				offPSPpollFrequencyMinutes = values.offPSPpollFrequencyMinutes*24*60 ;
			}

			if (!deviceId || parseInt(deviceId) <= 0) {
				Modal.warning({ title: langdata && langdata.Thedeviceidisinvalid ? langdata.Thedeviceidisinvalid : 'The device id is invalid.' });
				return false;
			}

			if (isTRU && midpointTLP === null) {
				Modal.warning({ title: langdata && langdata.PleaseselectamidpointTLPfortheTRU ? langdata.PleaseselectamidpointTLPfortheTRU : 'Please select a mid point TLP for the TRU.' });
				return false;
			}

			let parseData = {				
				isTRU: isTRU,
				midpointTLP: midpointTLP,
				vdc1Normal: vdc1NormalMin + ':' + vdc1NormalMax,
				vac1Normal: vac1NormalMin + ':' + vac1NormalMax,
				vdc2Normal: vdc2NormalMin + ':' + vdc2NormalMax,
				vac2Normal: vac2NormalMin + ':' + vac2NormalMax,
				pollFrequencyInMinutes: pollFrequencyInMinutes,
				anomalyPollFrequencyInMinutes: anomalyPollFrequencyInMinutes,
				retryCount: retryCount,
				secondVoltage: secondVoltage,
				hasSolarPanel: hasSolarPanel,
				// measureCurrent: measureCurrent,
				otaUpdateAvailable: otaUpdateAvailable,
				otaVersion: otaVersion,
				smsNumbers : {
					numbers: [
						num1,
						num2,
						num3
					]
				},
				dataLoggingMode : dataLoggingMode,
				dataLoggingModePollFrequencyMinutes : dataLoggingModePollFrequencyMinutes
			};

			if(offPSPEnabled) {
				parseData['offPSPacNormal'] = offPSPacNormalMin + ':' + offPSPacNormalMax;
				parseData['offPSPdcNormal'] = offPSPdcNormalMin + ':' + offPSPdcNormalMax;
				// parseData['onPSPTime'] = onPSPTime;
				parseData['offPSPDateTime'] = offPSPDateTime;			
				parseData['offPSPEnabled'] = offPSPEnabledValue;
				parseData['offPSPIntervalEnabled'] = offPSPIntervalEnabled;
				parseData['offPSPpollFrequencyMinutesEnabled'] = offPSPpollFrequencyMinutesEnabled;
				parseData['offPSPpollFrequencyMinutes'] = offPSPpollFrequencyMinutes;
			}		
			
			if(offCSPEnabled){
				parseData['offCSPEnabled'] = offCSPEnabledValue;
				parseData['offCSPacNormal'] = offCSPacNormalMin + ':' + offCSPacNormalMax;
				parseData['offCSPdcNormal'] = offCSPdcNormalMin + ':' + offCSPdcNormalMax;
			}

			if(currentDensityEnabled) {
				parseData['couponFactorAC'] = couponFactorAC;
				parseData['couponFactorDC'] = couponFactorDC;
			}		

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/smarttlp/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: parseData
			};

			dispatch( { type: UPDATE_TLP });
      
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: UPDATE_TLP_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.Deviceconfigurationupdatedsuccessfully ? langdata.Deviceconfigurationupdatedsuccessfully :  'Device configuration updated successfully.' });
						closeEditTLPModal();
						loadTLPs({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					} else {
						dispatch({ type: UPDATE_TLP_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletoupdatethedeviceconfiguration ? langdata.Unabletoupdatethedeviceconfiguration : 'Unable to update the device configuration.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: UPDATE_TLP_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}else{
						Modal.warning({ title: langdata && langdata.Unabletoupdate ? langdata.Unabletoupdate : 'Unable to update.' });
					}
				});
		}
	});

	const deleteTLP = () => {
		let id = data.details ? data.details.id : 0;
    
		if (!id || parseInt(id) <= 0) {
			Modal.warning({ title: langdata && langdata.ThedeviceidisinvalidPleasetryagain ? langdata.ThedeviceidisinvalidPleasetryagain : 'The device id is invalid. Please try again.' });
		} else {
			dispatch( { type: DELETE_TLP });

			let config = {
				method: 'delete',
				maxBodyLength: Infinity,
				url: `${api}/smarttlp/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${authtoken}`
				},
				data: {}
			};


			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: DELETE_TLP_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.Devicedeletedsuccessfully ? langdata.Devicedeletedsuccessfully : 'Device deleted successfully.' });
						closeDeleteTLPModal();
						loadTLPs({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					} else {
						dispatch({ type: DELETE_TLP_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletodeletethedevice ? langdata.Unabletodeletethedevice : 'Unable to delete the device.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: DELETE_TLP_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	};

	const cancelManualOverride = () => {
		let deviceId = data.details ? data.details.deviceId : 0;
    
		if (!deviceId || parseInt(deviceId) <= 0) {
			Modal.warning({ title: langdata && langdata.ThedeviceidisinvalidPleasetryagain ? langdata.ThedeviceidisinvalidPleasetryagain : 'The device id is invalid. Please try again.' });
		} else {
			dispatch( { type: CANCEL_MANUAL_OVERRIDE });

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/smarttru-cancel-manual-override`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${authtoken}`
				},
				data: {
					'deviceId': deviceId
				}
			};

			console.log('# Config: ', config);

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: CANCEL_MANUAL_OVERRIDE_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.TRUmanualoverridemodeisturnedoff ? langdata.TRUmanualoverridemodeisturnedoff : 'TRU manual override mode is turned off.' });
						closeCancelManualOverrideModal();
						loadTLPs({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					} else {
						dispatch({ type: CANCEL_MANUAL_OVERRIDE_ERROR });
						closeCancelManualOverrideModal();
						Modal.warning({ title: langdata && langdata.UnabletoturnoffthemanauloverridemodePleasetryagain ? langdata.UnabletoturnoffthemanauloverridemodePleasetryagain : 'Unable to turn off the manaul override mode. Please try again.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					
					dispatch({ type: CANCEL_MANUAL_OVERRIDE_ERROR });
					closeCancelManualOverrideModal();
						
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	};

	const uploadFOTAFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			frimwareJSONFile: '',
			frimwareBinaryFile: '',
			frimwareVersion: data.details.otaVersion ? data.details.otaVersion : 0
		},

		onSubmit: async (values) => {
			const id = data.details ? data.details.id : 0;
			const frimwareJSONFile = values.frimwareJSONFile ? values.frimwareJSONFile : '';
			const frimwareBinaryFile = values.frimwareBinaryFile ? values.frimwareBinaryFile : '';
			const frimwareVersion = values.frimwareVersion ? values.frimwareVersion : 0;

			if (!id || parseInt(id) <= 0) {
				Modal.warning({ title: langdata && langdata.ThedeviceidisinvalidPleasetryagain ? langdata.ThedeviceidisinvalidPleasetryagain :  'The device id is invalid. Please try again.' });
				return;
			}
			
			if (!frimwareJSONFile || frimwareJSONFile.length <= 0) {
				Modal.warning({ title: langdata && langdata.PleasespecifyavalidFOTAfile ? langdata.PleasespecifyavalidFOTAfile :  'Please specify a valid FOTA file' });
				return;
			}

			if (!frimwareBinaryFile || frimwareBinaryFile.length <= 0) {
				Modal.warning({ title: langdata && langdata.PleasespecifyavalidFOTAbinaryfile ? langdata.PleasespecifyavalidFOTAbinaryfile :  'Please specify a valid FOTA binary file' });
				return;
			} else {
				const formDataJSON = new FormData();
				const formDataBin = new FormData();
				const url = `${api}/fileupload`;
				
				let jsonPath = '';
				let binPath = '';

				formDataJSON.append('file', frimwareJSONFile);
				formDataBin.append('file', frimwareBinaryFile);

				dispatch({ type: UPDATE_TLP_FOTA });

				await fetch(url, {
					method: 'POST',
					body: formDataJSON,
					headers: {
						'Authorization': `Bearer ${authtoken}`
					},
				}).then((response) => response.json()).then((result) => {
					if(result.success) {
						jsonPath = result.fileUrl;

						dispatch({ type: UPDATE_TLP_FOTA_SUCCESS });
					} else {
						dispatch({ type: UPDATE_TLP_FOTA_ERROR });
					}
				}).catch((error) => {
					console.error('Error:', error);
					dispatch({ type: UPDATE_TLP_FOTA_ERROR });
				});

				dispatch({ type: UPDATE_TLP_FOTA });

				await fetch(url, {
					method: 'POST',
					body: formDataBin,
					headers: {
						'Authorization': `Bearer ${authtoken}`
					},
				}).then((response) => response.json()).then((result) => {
					if(result.success) {
						binPath = result.fileUrl;

						dispatch({ type: UPDATE_TLP_FOTA_SUCCESS });
					} else {
						dispatch({ type: UPDATE_TLP_FOTA_ERROR });
					}
				}).catch((error) => {
					console.error('Error:', error);
					dispatch({ type: UPDATE_TLP_FOTA_ERROR });
				});

				let config = {
					method: 'patch',
					maxBodyLength: Infinity,
					url: `${api}/smarttlp/${id}`,
					timeout: timeout,
					headers: {
						accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization:`Bearer ${authtoken}`
					},
					data: {
						otaVersion: frimwareVersion,
						otaURL: jsonPath,
						otaBinaryURL: binPath
					}
				};

				dispatch({ type: UPDATE_TLP_FOTA });

				axios.request(config)
					.then((response) => {
						if (response.status === 200 || response.status === 201) {
							dispatch({ type: UPDATE_TLP_FOTA_SUCCESS });
							Modal.success({ title: langdata && langdata.Devicefrimwareupdatedsuccessfully ? langdata.Devicefrimwareupdatedsuccessfully : 'Device firmware updated successfully.' });
							closeUploadFrimwareModal();
						} else {
							dispatch({ type: UPDATE_TLP_FOTA_ERROR });
							Modal.warning({ title: langdata && langdata.Unabletoupdatethedevicefrimware ? langdata.Unabletoupdatethedevicefrimware : 'Unable to update the device firmware.' });  
						}
					})
					.catch((error) => {
						console.log(error);
						dispatch({ type: UPDATE_TLP_FOTA_ERROR });
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						}
					});
			} 
		}
	});

	const replaceTLPFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			existingDeviceId: data.details ? data.details.deviceId : 0,
			newDeviceId: 0
		},

		onSubmit: (values) => {
			const existingDeviceId = values.existingDeviceId ? values.existingDeviceId : 0;
			const newDeviceId = values.newDeviceId ? values.newDeviceId : 0;

			if (!existingDeviceId || parseInt(existingDeviceId) <= 0) {
				Modal.warning({ title: langdata && langdata.Theexistingdeviceidisinvalid ? langdata.Theexistingdeviceidisinvalid : 'The existing device id is invalid.' });
			} else {
				if (!newDeviceId || parseInt(newDeviceId) <= 0) {
					Modal.warning({ title: langdata && langdata.Thenewdeviceidisinvalid ? langdata.Thenewdeviceidisinvalid :  'The new device id is invalid.' });
				} else {
					if (newDeviceId === existingDeviceId || parseInt(newDeviceId) === parseInt(existingDeviceId)) {
						Modal.warning({ title: langdata && langdata.Theexistingdeviceidandnewdeviceidcannotbesame ? langdata.Theexistingdeviceidandnewdeviceidcannotbesame :  'The existing device id and new device id cannot be same.' });
					} else {
						let config = {
							method: 'patch',
							maxBodyLength: Infinity,
							url: `${api}/replace-smarttlp`,
							timeout: timeout,
							headers: {
								accept: 'application/json',
								'Content-Type': 'application/json',
								Authorization:`Bearer ${authtoken}`
							},
							data: {
								existingDeviceId: existingDeviceId,
								newDeviceId: newDeviceId
							}
						};

						dispatch( { type: REPLACE_TLP });
      
						axios
							.request(config)
							.then((response) => {
								if (response.status === 200 || response.status === 201) {
									dispatch({type: REPLACE_TLP_SUCCESS, payload:response});
									Modal.success({ title: langdata && langdata.Devicereplacedsuccessfully ? langdata.Devicereplacedsuccessfully : 'Device replaced successfully.' });
									closeReplaceTLPModal();
									loadTLPs({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
								} else {
									dispatch({ type: REPLACE_TLP_ERROR });
									Modal.warning({ title: langdata && langdata.Unabletoreplacethedevice ? langdata.Unabletoreplacethedevice : 'Unable to replace the device.' });  
								}
							})
							.catch((error) => {
								console.log(error);
								dispatch({ type: REPLACE_TLP_ERROR });
								if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
									message.config({
										duration : 5,
										maxCount : 1,
									});
									message.open({
										type: 'error',
										content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
									});
								}
							});
					}
				}
			}
		}
	});

	const truVoltageFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			truVoltage: data.details ? data.details.truVoltage : 0
		},

		onSubmit: (values) => {
			const deviceId = data.details ? data.details.deviceId : 0;
			const truVoltage = values.truVoltage ? values.truVoltage : 0;
			const truVoltageManuallySet = true;
			
			if (!deviceId || parseInt(deviceId) <= 0) {
				Modal.warning({ title: langdata && langdata.Theselecteddeviceidisinvalid ? langdata.Theselecteddeviceidisinvalid : 'The selected device id is invalid.' });
				return;
			} else {
				let config = {
					method: 'patch',
					maxBodyLength: Infinity,
					url: `${api}/smarttru-voltage/${deviceId}`,
					timeout: timeout,
					headers: {
						accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization:`Bearer ${authtoken}`
					},
					data: {
						voltage: truVoltage,
						deviceid: deviceId,
						truVoltageManuallySet: truVoltageManuallySet
					}
				};

				dispatch( { type: SET_TRU_VOLTAGE });
        
				axios
					.request(config)
					.then((response) => {
						if (response.status === 200 || response.status === 201) {
							dispatch({type: SET_TRU_VOLTAGE_SUCCESS, payload: response});
							Modal.success({ title: langdata && langdata.TRUvoltagesetsuccessfully ? langdata.TRUvoltagesetsuccessfully : 'TRU voltage set successfully.' });
							closeTRUVoltageModal();
						} else {
							dispatch({ type: SET_TRU_VOLTAGE_ERROR });
							Modal.warning({ title: langdata && langdata.UnabletosettheTRUvoltage ? langdata.UnabletosettheTRUvoltage : 'Unable to set the TRU voltage.' });  
						}
					})
					.catch((error) => {
						console.log(error);
						dispatch({ type: SET_TRU_VOLTAGE_ERROR });
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						}
					});
			} 
		}
	});

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handleChainage = (e, options) => {	
		setPipelineChiange(options.pipelineChainage);
		setPipelineChiangeId(e);
		setAddRegionId(options.regionId);
	};

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.TlpManagementInfo1 ? langdata.TlpManagementInfo1 : 'Smart TLP device details are displayed. Filter option available to filter by region.'}</p>
			{ permission === 'superadmin' || permission === 'admin' ?
				<>
					< br/>
					<p>{langdata && langdata.TlpManagementInfo2 ? langdata.TlpManagementInfo2 : 'New Smart TLP devices can be added using the Add New button.'}</p> </> : null }
		</Card>
	);

	const handleSaveoffPSPDateTime = (value) => {		
		setSaveoffPSPDateTime(new Date(value && value.$d));		
	};

	// const handleSaveonPSPTime = (time, timeString) => {
	// 	setSaveonPSPTime(timeString);		
	// };	

	const handleUpdateoffPSPDateTime = (value) => {		
		setUpdateoffPSPDateTime(new Date(value && value.$d));		
	};

	// const handleUpdateonPSPTime = (time, timeString) => {
	// 	setUpdateonPSPTime(timeString);		
	// };	

	// console.log('updateonPSPTime', updateonPSPTime);
	

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={8}>
						<Row>
							<Space>
								<ToolOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.TLPManagement ? langdata.TLPManagement : 'TLP Management'}</Title>
						</Row>
					</Col>
					
					<Col span={16}>
						<Row justify="end">
							<Col>
								{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Region:</Text> */}
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion : 'Filter by region'}>
									<Space>
										<Select
											style={{ width: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
											optionFilterProp="children"
											defaultValue={null}
											value={regionId}
											options={regions}
											onSelect={handleChangeRegion}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									{ permission === 'superadmin' || permission === 'admin' ? <Button style={{margin:'5px'}} type="primary" onClick={addTLPDetails} ><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button> : null }
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.TLPManagement ? langdata.TLPManagement : 'TLP Management'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						<Row style={{ marginTop: '20px', marginBottom: '20px' }}>
							<Col span={4}>
								<Text type="secondary" style={{ borderLeft: '10px solid gold', paddingLeft: '10px' }}>{langdata && langdata.TLPwithBattery ? langdata.TLPwithBattery : 'TLP with Battery'}</Text>
							</Col>
							<Col span={4}>
								<Text type="secondary" style={{ borderLeft: '10px solid green', paddingLeft: '10px' }}>{langdata && langdata.TLPwithSolarPanel ? langdata.TLPwithSolarPanel : 'TLP with Solar Panel'}</Text>
							</Col>
							<Col span={4}>
								<Text type="secondary" style={{ borderLeft: '10px solid crimson', paddingLeft: '10px' }}>{langdata && langdata.TRU ? langdata.TRU : 'TRU'}</Text>
							</Col>
						</Row>
						<Row style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col span={24}>
								{ data && data.tlps ? (
									<Table
										scroll={{
											x: 900,
										}} 
										columns = { columns } 
										dataSource = { data.tlps } 
										pagination = { data.tlps.length > 0 ? data.pagination : 0 }
										onChange = { loadTLPs }
										onRow={(record) => {
											return {
												onClick: () => { 
													loadTLPDetails(record.id);
												}
											};
										}} 
									/>
								)
									:
									(
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Content>

			<Spin fullscreen spinning={data.loading} />

			{/* Add TLP Starts */}
			<Modal
				title={langdata && langdata.AddTLP ? langdata.AddTLP : 'Add TLP'}
				centered
				open={showAddTLPModal}
				onOk={{closeAddTLPModal}}
				onCancel={closeAddTLPModal}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeAddTLPModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={saveTLPFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
					</Space>
				]}
				width={800}
			> 
				<Form layout="vertical" onSubmit={saveTLPFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Pipelinechainage ? langdata.Pipelinechainage : 'Pipeline chainage'} rules={[{ required: true, message: langdata && langdata.Selectthepipelinechainage ? langdata.Selectthepipelinechainage : 'Please select the pipeline chainage' }]} required>
								<Select
									name="pipelineChainageId" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Selectthepipelinechainage ? langdata.Selectthepipelinechainage : 'Pipeline chainage'}
									optionFilterProp="children"
									options={unallocatedChainages}
									// onChange={(value, options) => saveTLPFormik.setFieldValue('pipelineChainageId', value, 'pipelineChainage',options.pipelineChainage)}
									onChange={(value, options) => {handleChainage(value, options);}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Installationdate ? langdata.Installationdate : 'Installation date'} rules={[{ required: true, message: langdata && langdata.Pleaseselecttheinstallationdate ? langdata.Pleaseselecttheinstallationdate : 'Please select the installation date' }]} >
								<DatePicker name="installedDate" style={{ width: '100%'}} allowClear={false} defaultValue={dayjs()} onChange={(date, dateString) => saveTLPFormik.setFieldValue('installedDate', dateString)} format={settingsdateformat}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Manuallyadddeviceid ? langdata.Manuallyadddeviceid : 'Manually add device id'} >
								<Checkbox name="manuallyAddDevice" onChange={saveTLPFormik.handleChange} checked={saveTLPFormik.values.manuallyAddDevice}/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.DeviceID ? langdata.DeviceID : 'Device ID'} rules={[{ message: langdata && langdata.Pleaseenterthedeviceid ? langdata.Pleaseenterthedeviceid : 'Please enter the device id' }]} required>
								{ saveTLPFormik.values.manuallyAddDevice === true ? ( 
									<Input name="deviceId" type="number" placeholder={langdata && langdata.Pleaseenterthedeviceid ? langdata.Pleaseenterthedeviceid : 'Please enter the device id'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.deviceId} />
								) : (
									<Select
										name="deviceId" 
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Selectdevice ? langdata.Selectdevice : 'Select device'}
										optionFilterProp="children"
										options={tlps}
										onChange={(value) => saveTLPFormik.setFieldValue('deviceId', value)}
									/>
								)}
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.IsTRU ? langdata.IsTRU : 'Is TRU?'} >
								<Checkbox name='isTRU' onChange={saveTLPFormik.handleChange} checked={saveTLPFormik.values.isTRU} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.MidpointTLP ? langdata.MidpointTLP : 'Midpoint TLP'} rules={[{ message: langdata && langdata.PleaseselectthemidpointTLP ? langdata.PleaseselectthemidpointTLP : 'Please select the midpoint TLP' }]} >
								<Select
									name="midpointTLP"
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.SelectTLP ? langdata.SelectTLP : 'Select TLP'}
									optionFilterProp="children"
									options={midpointTLPs}
									onChange={(value) => saveTLPFormik.setFieldValue('midpointTLP', value)}
									disabled = {!saveTLPFormik.values.isTRU ? true : false}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Pollfrequencyinminutes ? langdata.Pollfrequencyinminutes : 'Poll frequency in minutes'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthepollfrequencyinminutes ? langdata.Pleaseenterthepollfrequencyinminutes : 'Please enter the poll frequency in minutes' }]} >
								<Input name="pollFrequencyInMinutes" type="number" placeholder={langdata && langdata.Pleaseenterthepollfrequencyinminutes ? langdata.Pleaseenterthepollfrequencyinminutes :'Please enter the poll frequency in minutes'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.pollFrequencyInMinutes} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Anomalypollfrequencyinminutes ? langdata.Anomalypollfrequencyinminutes : 'Anomaly poll frequency in minutes'} rules={[{ required: true, message: langdata && langdata.Anomalypollfrequencyinminutes ? langdata.Anomalypollfrequencyinminutes : 'Please enter the anomaly poll frequency in minutes' }]} >
								<Input name="anomalyPollFrequencyInMinutes" type="number" placeholder={langdata && langdata.Anomalypollfrequencyinminutes ? langdata.Anomalypollfrequencyinminutes : 'Please enter the anomaly poll frequency in minutes'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.anomalyPollFrequencyInMinutes} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Retrycount ? langdata.Retrycount : 'Retry count'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheretrycount ? langdata.Pleaseentertheretrycount : 'Please enter the retry count' }]}  >
								<Input name="retryCount" type="number" placeholder={langdata && langdata.Pleaseentertheretrycount ? langdata.Pleaseentertheretrycount :'Please enter the retry count'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.retryCount} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Enablecasing ? langdata.Enablecasing : 'Enable casing?'} >
								<Checkbox name="secondVoltage" onChange={saveTLPFormik.handleChange} checked={saveTLPFormik.values.secondVoltage}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						{offPSPEnabled ?
							<Col span={12}>
								<Form.Item label={langdata && langdata.EnableOFFPSP ? langdata.EnableOFFPSP : 'Enable OFF PSP?'} >
									<Checkbox name="offPSPEnabled" onChange={saveTLPFormik.handleChange} checked={saveTLPFormik.values.offPSPEnabled}/>
								</Form.Item>
							</Col>
							: null }
						{offCSPEnabled ? 
							<Col span={12}>
								<Form.Item label={langdata && langdata.EnableOFFCSP ? langdata.EnableOFFCSP : 'Enable OFF CSP?'} >
									<Checkbox name="offCSPEnabled" onChange={saveTLPFormik.handleChange} checked={saveTLPFormik.values.offCSPEnabled}/>
								</Form.Item>
							</Col>
							: null }
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.PSPMinV ? langdata.PSPMinV : 'PSP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseenterthePSPMinV ? langdata.PleaseenterthePSPMinV : 'Please enter the PSP Min (V)' }]} >
								<Input name="vdc2NormalMin" type="number" placeholder={langdata && langdata.PleaseenterthePSPMinV ? langdata.PleaseenterthePSPMinV : 'Please enter the PSP Min (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.vdc2NormalMin} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.PSPMaxV ? langdata.PSPMaxV : 'PSP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseenterthePSPMaxV ? langdata.PleaseenterthePSPMaxV : 'Please enter the PSP Max (V)' }]} >
								<Input name="vdc2NormalMax" type="number" placeholder={langdata && langdata.PleaseenterthePSPMaxV ? langdata.PleaseenterthePSPMaxV : 'Please enter the PSP Max (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.vdc2NormalMax} />
							</Form.Item>
						</Col>
					</Row>
					{offPSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFPSPMinV ? langdata.OFFPSPMinV : 'OFF PSP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFPSPMinV ? langdata.PleaseentertheOFFPSPMinV : 'Please enter the OFF PSP Min (V)' }]} >
									<Input name="offPSPdcNormalMin" type="number" placeholder={langdata && langdata.PleaseentertheOFFPSPMinV ? langdata.PleaseentertheOFFPSPMinV : 'Please enter the OFF PSP Min (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.offPSPdcNormalMin} disabled={!saveTLPFormik.values.offPSPEnabled}/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFPSPMaxV ? langdata.OFFPSPMaxV : 'OFF PSP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFPSPMaxV ? langdata.PleaseentertheOFFPSPMaxV : 'Please enter the OFF PSP Max (V)' }]} >
									<Input name="offPSPdcNormalMax" type="number" placeholder={langdata && langdata.PleaseentertheOFFPSPMaxV ? langdata.PleaseentertheOFFPSPMaxV : 'Please enter the OFF PSP Max (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.offPSPdcNormalMax} disabled={!saveTLPFormik.values.offPSPEnabled}/>
								</Form.Item>
							</Col>
						</Row>
						: null }
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ACPICKUPMinV ? langdata.ACPICKUPMinV : 'AC PICKUP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheACPICKUPMinV ? langdata.PleaseentertheACPICKUPMinV : 'Please enter the AC PICKUP Min (V)' }]} >
								<Input name="vac2NormalMin" type="number" placeholder={langdata && langdata.PleaseentertheACPICKUPMinV ? langdata.PleaseentertheACPICKUPMinV : 'Please enter the AC PICKUP Min (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.vac2NormalMin} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ACPICKUPMaxV ? langdata.ACPICKUPMaxV : 'AC PICKUP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheACPICKUPMaxV ? langdata.PleaseentertheACPICKUPMaxV : 'Please enter the AC PICKUP Max (V)' }]} >
								<Input name="vac2NormalMax" type="number" placeholder={langdata && langdata.PleaseentertheACPICKUPMaxV ? langdata.PleaseentertheACPICKUPMaxV : 'Please enter the AC PICKUP Max (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.vac2NormalMax} />
							</Form.Item>
						</Col>
					</Row>
					{offPSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFPSPACPICKUPMinV ? langdata.OFFPSPACPICKUPMinV : 'OFF PSP AC PICKUP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFPSPACPICKUPMinV ? langdata.PleaseentertheOFFPSPACPICKUPMinV : 'Please enter the OFF PSP AC PICKUP Min (V)' }]} >
									<Input name="offPSPacNormalMin" type="number" placeholder={langdata && langdata.PleaseentertheOFFPSPACPICKUPMinV ? langdata.PleaseentertheOFFPSPACPICKUPMinV : 'Please enter the OFF PSP AC PICKUP Min (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.offPSPacNormalMin} disabled={!saveTLPFormik.values.offPSPEnabled}/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFPSPACPICKUPMaxV ? langdata.OFFPSPACPICKUPMaxV : 'OFF PSP AC PICKUP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFPSPACPICKUPMaxV ? langdata.PleaseentertheOFFPSPACPICKUPMaxV : 'Please enter the OFF PSP AC PICKUP Max (V)' }]} >
									<Input name="offPSPacNormalMax" type="number" placeholder={langdata && langdata.PleaseentertheOFFPSPACPICKUPMaxV ? langdata.PleaseentertheOFFPSPACPICKUPMaxV : 'Please enter the OFF PSP AC PICKUP Max (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.offPSPacNormalMax} disabled={!saveTLPFormik.values.offPSPEnabled}/>
								</Form.Item>
							</Col>
						</Row>
						: null }
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.CSPMinV ? langdata.CSPMinV : 'CSP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheCSPMinV ? langdata.PleaseentertheCSPMinV : 'Please enter the CSP Min (V)' }]} >
								<Input name="vdc1NormalMin" type="number" placeholder={langdata && langdata.PleaseentertheCSPMinV ? langdata.PleaseentertheCSPMinV : 'Please enter the CSP Min (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.vdc1NormalMin} disabled={!saveTLPFormik.values.secondVoltage} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.CSPMaxV ? langdata.CSPMaxV : 'CSP Max (V)'} rules={[{ required: true, message:langdata && langdata.PleaseentertheCSPMaxV ? langdata.PleaseentertheCSPMaxV : 'Please enter the CSP Max (V)' }]} >
								<Input name="vdc1NormalMax" type="number" placeholder={langdata && langdata.PleaseentertheCSPMaxV ? langdata.PleaseentertheCSPMaxV : 'Please enter the CSP Max (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.vdc1NormalMax} disabled={!saveTLPFormik.values.secondVoltage} />
							</Form.Item>
						</Col>
					</Row>
					{offCSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFCSPMinV ? langdata.OFFCSPMinV : 'OFF CSP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFCSPMinV ? langdata.PleaseentertheOFFCSPMinV : 'Please enter the OFF CSP Min (V)' }]} >
									<Input name="offCSPdcNormalMin" type="number" placeholder={langdata && langdata.PleaseentertheOFFCSPMinV ? langdata.PleaseentertheOFFCSPMinV : 'Please enter the OFF CSP Min (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.offCSPdcNormalMin} disabled={!saveTLPFormik.values.secondVoltage || !saveTLPFormik.values.offCSPEnabled} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFCSPMaxV ? langdata.OFFCSPMaxV : 'OFF CSP Max (V)'} rules={[{ required: true, message:langdata && langdata.PleaseentertheOFFCSPMaxV ? langdata.PleaseentertheOFFCSPMaxV : 'Please enter the OFF CSP Max (V)' }]} >
									<Input name="offCSPdcNormalMax" type="number" placeholder={langdata && langdata.PleaseentertheOFFCSPMaxV ? langdata.PleaseentertheOFFCSPMaxV : 'Please enter the OFF CSP Max (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.offCSPdcNormalMax} disabled={!saveTLPFormik.values.secondVoltage || !saveTLPFormik.values.offCSPEnabled} />
								</Form.Item>
							</Col>
						</Row>
						: null}
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.CASINGACPICKUPMinV ? langdata.CASINGACPICKUPMinV : 'CASING AC PICKUP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheCASINGACPICKUPMinV ? langdata.PleaseentertheCASINGACPICKUPMinV : 'Please enter the CASING AC PICKUP Min (V)' }]} >
								<Input name="vac1NormalMin" type="number" placeholder={langdata && langdata.PleaseentertheCASINGACPICKUPMinV ? langdata.PleaseentertheCASINGACPICKUPMinV : 'Please enter the CASING AC PICKUP Min (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.vac1NormalMin} disabled={!saveTLPFormik.values.secondVoltage} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.CASINGACPICKUPMaxV ? langdata.CASINGACPICKUPMaxV : 'CASING AC PICKUP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheCASINGACPICKUPMaxV ? langdata.PleaseentertheCASINGACPICKUPMaxV :  'Please enter the CASING AC PICKUP Max (V)' }]} >
								<Input name="vac1NormalMax" type="number" placeholder={langdata && langdata.PleaseentertheCASINGACPICKUPMaxV ? langdata.PleaseentertheCASINGACPICKUPMaxV : 'Please enter the CASING AC PICKUP Max (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.vac1NormalMax} disabled={!saveTLPFormik.values.secondVoltage} />
							</Form.Item>
						</Col>
					</Row>
					{offCSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFCSPACPICKUPMinV ? langdata.OFFCSPACPICKUPMinV : 'OFF CSP AC PICKUP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFCSPACPICKUPMinV ? langdata.PleaseentertheOFFCSPACPICKUPMinV : 'Please enter the OFF CSP AC PICKUP Min (V)' }]} >
									<Input name="offCSPacNormalMin" type="number" placeholder={langdata && langdata.PleaseentertheOFFCSPACPICKUPMinV ? langdata.PleaseentertheOFFCSPACPICKUPMinV : 'Please enter the OFF CSP AC PICKUP Min (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.offCSPacNormalMin} disabled={!saveTLPFormik.values.secondVoltage || !saveTLPFormik.values.offCSPEnabled} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFCSPACPICKUPMaxV ? langdata.OFFCSPACPICKUPMaxV : 'OFF CSP AC PICKUP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFCSPACPICKUPMaxV ? langdata.PleaseentertheOFFCSPACPICKUPMaxV :  'Please enter the OFF CSP AC PICKUP Max (V)' }]} >
									<Input name="offCSPacNormalMax" type="number" placeholder={langdata && langdata.PleaseentertheOFFCSPACPICKUPMaxV ? langdata.PleaseentertheOFFCSPACPICKUPMaxV : 'Please enter the OFF CSP AC PICKUP Max (V)'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.offCSPacNormalMax} disabled={!saveTLPFormik.values.secondVoltage || !saveTLPFormik.values.offCSPEnabled} />
								</Form.Item>
							</Col>
						</Row>
						: null}

					{offPSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.EnableOFFPSPInterval ? langdata.EnableOFFPSPInterval : 'Enable OFF PSP Interval?'} >
									<Checkbox name="offPSPIntervalEnabled" onChange={saveTLPFormik.handleChange} checked={saveTLPFormik.values.offPSPIntervalEnabled} disabled={!saveTLPFormik.values.offPSPEnabled}/>
								</Form.Item>
							</Col>
							{saveTLPFormik.values.offPSPIntervalEnabled ?
								<>
									<Col span={11}>
										<Form.Item label={saveTLPFormik.values.offPSPpollFrequencyMinutesEnabled ? langdata && langdata.OFFPSPPollFrequencyMinutes ? langdata.OFFPSPPollFrequencyMinutes : 'OFF PSP Poll Frequency Minutes' :  langdata && langdata.OFFPSPPollFrequencyDays ? langdata.OFFPSPPollFrequencyDays : 'OFF PSP Poll Frequency Days'} rules={[{ required: true, message: saveTLPFormik.values.offPSPpollFrequencyMinutesEnabled ? langdata && langdata.PleaseentertheOFFPSPpollfrequencyminutes ? langdata.PleaseentertheOFFPSPpollfrequencyminutes : 'Please enter the OFF PSP poll frequency minutes' : langdata && langdata.PleaseentertheOFFPSPpollfrequencydays ? langdata.PleaseentertheOFFPSPpollfrequencydays : 'Please enter the OFF PSP poll frequency days' }]} >
											<Input name="offPSPpollFrequencyMinutes" type="number" placeholder={saveTLPFormik.values.offPSPpollFrequencyMinutesEnabled ? langdata && langdata.PleaseentertheOFFPSPpollfrequencyminutes ? langdata.PleaseentertheOFFPSPpollfrequencyminutes : 'Please enter the OFF PSP poll frequency minutes' : langdata && langdata.PleaseentertheOFFPSPpollfrequencydays ? langdata.PleaseentertheOFFPSPpollfrequencydays : 'Please enter the OFF PSP poll frequency days'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.offPSPpollFrequencyMinutes} disabled={!saveTLPFormik.values.offPSPEnabled} 
												max={180}/>
										</Form.Item>
									</Col>
									<Col span={1}>
										<Form.Item
											label={' '} >
											<Tooltip placement="bottom" title={langdata && langdata.OFFPSPPollFrequencyMinutesEnabled ? langdata.OFFPSPPollFrequencyMinutesEnabled : 'OFF PSP Poll Frequency Minutes Enabled'}>
												<Switch size="small" onClick={(value) => { saveTLPFormik.setFieldValue('offPSPpollFrequencyMinutesEnabled', value); }}
													defaultChecked={saveTLPFormik.values.offPSPpollFrequencyMinutesEnabled} disabled={!saveTLPFormik.values.offPSPEnabled}/>
											</Tooltip>
										</Form.Item>
									</Col>
								</>
								:
								<Col span={12}>
									<Form.Item label={langdata && langdata.OFFPSPDateTime ? langdata.OFFPSPDateTime : 'OFF PSP Date Time'} rules={[{ required: true, message: langdata && langdata.PleaseselecttheOFFPSPdateandtime ? langdata.PleaseselecttheOFFPSPdateandtime : 'Please select the OFF PSP date and time' }]} >
										<DatePicker style={{ width : '100%'}} showTime name="offPSPDateTime"  placeholder={langdata && langdata.PleaseselecttheOFFPSPdateandtime ? langdata.PleaseselecttheOFFPSPdateandtime : 'Please select the OFF PSP date and time'} onChange={handleSaveoffPSPDateTime} disabled={!saveTLPFormik.values.offPSPEnabled}/>
									</Form.Item>
								</Col>
							}
						</Row>
						: null }

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.EnableDataLoggingMode ? langdata.EnableDataLoggingMode : 'Enable Data Logging Mode?'} rules={[{ required: true }]} >
								<Checkbox name="dataLoggingMode" onChange={saveTLPFormik.handleChange} checked={saveTLPFormik.values.dataLoggingMode} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.DataLoggingModePollFrequencyMinutes ? langdata.DataLoggingModePollFrequencyMinutes : 'Data Logging Mode Poll Frequency Minutes'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthedataloggingmodepollfrequencyminutes ? langdata.Pleaseenterthedataloggingmodepollfrequencyminutes :  'Please enter the data logging mode poll frequency minutes' }]} >
								<Input name="dataLoggingModePollFrequencyMinutes" type="number" placeholder={langdata && langdata.Pleaseenterthedataloggingmodepollfrequencyminutes ? langdata.Pleaseenterthedataloggingmodepollfrequencyminutes : 'Please enter the data logging mode poll frequency minutes'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.dataLoggingModePollFrequencyMinutes} disabled={!saveTLPFormik.values.dataLoggingMode}/>
							</Form.Item>
						</Col>
					</Row>
					{currentDensityEnabled ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item  label={langdata && langdata.CouponFactorAC ? langdata.CouponFactorAC : 'Coupon Factor AC'} rules={[{ message: langdata && langdata.PleaseenterthecouponfactorAC ? langdata.PleaseenterthecouponfactorAC : 'Please enter the coupon factor AC'}]}>
									<Input name="couponFactorAC" type='number'  placeholder={langdata && langdata.PleaseenterthecouponfactorAC ? langdata.PleaseenterthecouponfactorAC : 'Please enter the coupon factor AC'} onChange={saveTLPFormik.handleChange} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.CouponFactorDC ? langdata.CouponFactorDC : 'Coupon Factor DC'} rules={[{ message: langdata && langdata.PleaseenterthecouponfactorDC ? langdata.PleaseenterthecouponfactorDC : 'Please enter the coupon factor DC' }]} >
									<Input name="couponFactorDC" type='number'  placeholder={langdata && langdata.PleaseenterthecouponfactorDC ? langdata.PleaseenterthecouponfactorDC : 'Please enter the coupon factor DC'} onChange={saveTLPFormik.handleChange} />
								</Form.Item>
							</Col>
						</Row>
						: null }
					
					{/* {offPSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFPSPDateTime ? langdata.OFFPSPDateTime : 'OFF PSP Date Time'} rules={[{ required: true, message: langdata && langdata.PleaseentertheCASINGACPICKUPMinV ? langdata.PleaseentertheCASINGACPICKUPMinV : 'Please enter the CASING AC PICKUP Min (V)' }]} >
									<DatePicker style={{ width : '100%'}} showTime name="offPSPDateTime"  placeholder={langdata && langdata.PleaseselecttheOFFPSPdateandtime ? langdata.PleaseselecttheOFFPSPdateandtime : 'Please select the OFF PSP date and time'} onChange={handleSaveoffPSPDateTime} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={ langdata && langdata.ONPSPTime ? langdata.ONPSPTime : 'ON PSP Time'} rules={[{ required: true, message: langdata && langdata.PleaseentertheCASINGACPICKUPMaxV ? langdata.PleaseentertheCASINGACPICKUPMaxV :  'Please enter the CASING AC PICKUP Max (V)' }]} >
									<TimePicker name="onPSPTime" style={{ width : '100%'}}  placeholder={langdata && langdata.PleaseselecttheONPSPTime ? langdata.PleaseselecttheONPSPTime : 'Please select the ON PSP Time'} onChange={handleSaveonPSPTime} />
								</Form.Item>
							</Col>
						</Row>
						:
						null } */}
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Solarpanelavailable ? langdata.Solarpanelavailable : 'Solar panel available?'} >
								<Checkbox name="hasSolarPanel" onChange={saveTLPFormik.handleChange} checked={saveTLPFormik.values.hasSolarPanel} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Measurecurrent ? langdata.Measurecurrent : 'Measure current?'} >
								<Checkbox name="measureCurrent" onChange={saveTLPFormik.handleChange} checked={saveTLPFormik.values.measureCurrent} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.Frimwareversionupdateavailable ? langdata.Frimwareversionupdateavailable : 'Firmware version update available?'} >
								<Checkbox name="otaUpdateAvailable" onChange={saveTLPFormik.handleChange} checked={saveTLPFormik.values.otaUpdateAvailable} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Frimwareversion ? langdata.Frimwareversion : 'Firmware version'} rules={[{ message: langdata && langdata.Pleaseenterthefrimwareversion ? langdata.Pleaseenterthefrimwareversion : 'Please enter the firmware version' }]} >
								<Input name="otaVersion" type="number" placeholder={langdata && langdata.Pleaseenterthefrimwareversion ? langdata.Pleaseenterthefrimwareversion : 'Please enter the firmware version'} onChange={saveTLPFormik.handleChange} value={saveTLPFormik.values.otaVersion} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.SMSNumber1 ? langdata.SMSNumber1 : 'SMS Number 1'} rules={[{ message: langdata && langdata.Pleaseenterthefirstnumber ? langdata.Pleaseenterthefirstnumber : 'Please enter the first number'}]}>
								<Input name="num1"  placeholder={langdata && langdata.Pleaseenterthefirstnumber ? langdata.Pleaseenterthefirstnumber : 'Please enter the first number'} onChange={saveTLPFormik.handleChange} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.SMSNumber2 ? langdata.SMSNumber2 : 'SMS Number 2'} rules={[{ message: langdata && langdata.Pleaseenterthesecondnumber ? langdata.Pleaseenterthesecondnumber : 'Please enter the second number' }]} >
								<Input name="num2"  placeholder={langdata && langdata.Pleaseenterthesecondnumber ? langdata.Pleaseenterthesecondnumber : 'Please enter the second number'} onChange={saveTLPFormik.handleChange} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.SMSNumber3 ? langdata.SMSNumber3 : 'SMS Number 3'} rules={[{ message: langdata && langdata.Pleaseenterthethirdnumber ? langdata.Pleaseenterthethirdnumber : 'Please enter the third number' }]} >
								<Input name="num3"  placeholder={langdata && langdata.Pleaseenterthethirdnumber ? langdata.Pleaseenterthethirdnumber : 'Please enter the third number'} onChange={saveTLPFormik.handleChange} />
							</Form.Item>
						</Col>
					</Row>

					<Row type="flex">
						<p style={{fontSize: '12', paddingTop: '10'}}>
							{tlp_feature && tlp_feature.refElectrodeType && tlp_feature.refElectrodeType == 2 ?
								<span>{langdata && langdata.ThevoltagerangehastobeenteredwithrespecttotheCuCuSO4referencecell ? langdata.ThevoltagerangehastobeenteredwithrespecttotheCuCuSO4referencecell : 'The voltage range has to be entered with respect to the Cu-CuSO4 reference cell.'}</span> :
								<span>{langdata && langdata.ThevoltagerangehastobeenteredwithrespecttotheZincreferencecell ? langdata.ThevoltagerangehastobeenteredwithrespecttotheZincreferencecell : 'The voltage range has to be entered with respect to the Zinc reference cell.'}</span> }
							<br/>
							{langdata && langdata.PSPAC2PSPDC2Hardwarewiring ? langdata.PSPAC2PSPDC2Hardwarewiring : 'PSP AC2, PSP DC2 - Hardware wiring'} - <span  style={{color: 'green'}}>{langdata && langdata.GREEN ? langdata.GREEN : 'GREEN'}</span>
							<br/>
							{langdata && langdata.CSPAC1CSPDC1Hardwarewiring ? langdata.CSPAC1CSPDC1Hardwarewiring : 'CSP AC1, CSP DC1 - Hardware wiring'} - <span  style={{color: 'red'}}>{langdata && langdata.RED ? langdata.RED : 'RED'}</span>
							<br/>
							{tlp_feature && tlp_feature.refElectrodeType && tlp_feature.refElectrodeType == 2 ? 'Cu-CuSO4' : 'Zinc'} {langdata && langdata.ReferenceHardwarewiring ? langdata.ReferenceHardwarewiring : 'Reference - Hardware wiring'} - <span  style={{color: 'black'}}>{langdata && langdata.BLACK ? langdata.BLACK : 'BLACK'}</span>
							<br/>
							{langdata && langdata.CurrentHardwarewiring ? langdata.CurrentHardwarewiring : 'Current - Hardware wiring'} - <span  style={{color: 'blue'}}>{langdata && langdata.BLUE ? langdata.BLUE : 'BLUE'}</span>
						</p>
					</Row>
				</Form>       
			</Modal>
			{/* Add TLP Ends */}

			{/* Edit TLP Starts */}
			<Modal
				title={langdata && langdata.EditTLP ? langdata.EditTLP : 'Edit TLP'}
				centered
				open={showEditTLPModal}
				onOk={{closeEditTLPModal}}
				onCancel={closeEditTLPModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeEditTLPModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={updateTLPFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" onSubmit={updateTLPFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Pipelinechainage ? langdata.Pipelinechainage : 'Pipeline chainage'} rules={[{ required: true, message: langdata && langdata.Pleaseselectthepipelinechainage ? langdata.Pleaseselectthepipelinechainage : 'Please select the pipeline chainage' }]} required>
								<Select
									name="pipelineChainageId" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Pleaseselectthepipelinechainage ? langdata.Pleaseselectthepipelinechainage : 'Pipeline chainage'}
									optionFilterProp="children"
									initialValue= {data && data.details && data.details.selectedPipelineChainageId}
									value= {data && data.details && data.details.selectedPipelineChainageId}
									options={chainages}
									disabled
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Installationdate ? langdata.Installationdate : 'Installation date'} rules={[{ required: true, message: 'Please select the installation date' }]} >
								<DatePicker allowClear={false} style={{ width: '100%'}} value={dayjs(updateTLPFormik.values.installedDate)} format={settingsdateformat}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Manuallyadddeviceid ? langdata.Manuallyadddeviceid : 'Manually add device id'} >
								<Checkbox checked={true} disabled/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.DeviceID ? langdata.DeviceID : 'Device ID'} rules={[{ message: langdata && langdata.Pleaseenterthedeviceid ? langdata.Pleaseenterthedeviceid :'Please enter the device id' }]} required>
								<Input name="deviceId" type="number" placeholder={langdata && langdata.Pleaseenterthedeviceid ? langdata.Pleaseenterthedeviceid : 'Please enter the device id'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.deviceId} disabled />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.IsTRU ? langdata.IsTRU : 'Is TRU?'} >
								<Checkbox name="isTRU" onChange={updateTLPFormik.handleChange} checked={updateTLPFormik.values.isTRU} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.MidpointTLP ? langdata.MidpointTLP :'Midpoint TLP'} rules={[{ message: langdata && langdata.PleaseselectthemidpointTLP ? langdata.PleaseselectthemidpointTLP : 'Please select the midpoint TLP' }]} >
								<Select
									name="midpointTLP" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.PleaseselectthemidpointTLP ? langdata.PleaseselectthemidpointTLP : 'Select TLP'}
									optionFilterProp="children"
									options={midpointTLPs}
									initialValue= {updateTLPFormik.values.midpointTLP}
									value= {updateTLPFormik.values.midpointTLP}
									onChange={(value) => updateTLPFormik.setFieldValue('midpointTLP', value)}
									disabled = {updateTLPFormik.values.isTRU ? false : true}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Pollfrequencyinminutes ? langdata.Pollfrequencyinminutes : 'Poll frequency in minutes'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthepollfrequencyinminutes ? langdata.Pleaseenterthepollfrequencyinminutes :  'Please enter the poll frequency in minutes' }]} >
								<Input name="pollFrequencyInMinutes" type="number" placeholder={langdata && langdata.Pleaseenterthepollfrequencyinminutes ? langdata.Pleaseenterthepollfrequencyinminutes : 'Please enter the poll frequency in minutes'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.pollFrequencyInMinutes} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Anomalypollfrequencyinminutes ? langdata.Anomalypollfrequencyinminutes : 'Anomaly poll frequency in minutes'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheanomalypollfrequencyinminutes ? langdata.Pleaseentertheanomalypollfrequencyinminutes : 'Please enter the anomaly poll frequency in minutes' }]} >
								<Input name="anomalyPollFrequencyInMinutes" type="number" placeholder={langdata && langdata.Pleaseentertheanomalypollfrequencyinminutes ? langdata.Pleaseentertheanomalypollfrequencyinminutes : 'Please enter the anomaly poll frequency in minutes'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.anomalyPollFrequencyInMinutes} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Retrycount ? langdata.Retrycount : 'Retry count'} rules={[{ required: true, message:  langdata && langdata.Pleaseentertheretrycount ? langdata.Pleaseentertheretrycount : 'Please enter the retry count' }]}  >
								<Input name="retryCount" type="number" placeholder={langdata && langdata.Pleaseentertheretrycount ? langdata.Pleaseentertheretrycount : 'Please enter the retry count'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.retryCount} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Enablecasing ? langdata.Enablecasing : 'Enable casing?'} >
								<Checkbox name="secondVoltage" onChange={updateTLPFormik.handleChange} checked={updateTLPFormik.values.secondVoltage}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						{offPSPEnabled ?
							<Col span={12}>
								<Form.Item label={langdata && langdata.EnableOFFPSP ? langdata.EnableOFFPSP : 'Enable OFF PSP?'} >
									<Checkbox name="offPSPEnabled" onChange={updateTLPFormik.handleChange} checked={updateTLPFormik.values.offPSPEnabled}/>
								</Form.Item>
							</Col>
							: null }
						{offCSPEnabled ? 
							<Col span={12}>
								<Form.Item label={langdata && langdata.EnableOFFCSP ? langdata.EnableOFFCSP : 'Enable OFF CSP?'} >
									<Checkbox name="offCSPEnabled" onChange={updateTLPFormik.handleChange} checked={updateTLPFormik.values.offCSPEnabled}/>
								</Form.Item>
							</Col>
							: null }
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.PSPMinV ? langdata.PSPMinV :'PSP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseenterthePSPMinV ? langdata.PleaseenterthePSPMinV :  'Please enter the PSP Min (V)' }]} >
								<Input name="vdc2NormalMin" type="number" placeholder={langdata && langdata.PleaseenterthePSPMinV ? langdata.PleaseenterthePSPMinV : 'Please enter the PSP Min (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.vdc2NormalMin} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.PSPMaxV ? langdata.PSPMaxV : 'PSP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseenterthePSPMaxV ? langdata.PleaseenterthePSPMaxV : 'Please enter the PSP Max (V)' }]} >
								<Input name="vdc2NormalMax" type="number" placeholder={langdata && langdata.PleaseenterthePSPMaxV ? langdata.PleaseenterthePSPMaxV : 'Please enter the PSP Max (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.vdc2NormalMax} />
							</Form.Item>
						</Col>
					</Row>
					{offPSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFPSPMinV ? langdata.OFFPSPMinV : 'OFF PSP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFPSPMinV ? langdata.PleaseentertheOFFPSPMinV : 'Please enter the OFF PSP Min (V)' }]} >
									<Input name="offPSPdcNormalMin" type="number" placeholder={langdata && langdata.PleaseentertheOFFPSPMinV ? langdata.PleaseentertheOFFPSPMinV : 'Please enter the OFF PSP Min (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.offPSPdcNormalMin} disabled={!updateTLPFormik.values.offPSPEnabled}/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFPSPMaxV ? langdata.OFFPSPMaxV : 'OFF PSP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFPSPMaxV ? langdata.PleaseentertheOFFPSPMaxV : 'Please enter the OFF PSP Max (V)' }]} >
									<Input name="offPSPdcNormalMax" type="number" placeholder={langdata && langdata.PleaseentertheOFFPSPMaxV ? langdata.PleaseentertheOFFPSPMaxV : 'Please enter the OFF PSP Max (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.offPSPdcNormalMax} disabled={!updateTLPFormik.values.offPSPEnabled} />
								</Form.Item>
							</Col>
						</Row>
						: null }
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ACPICKUPMinV ? langdata.ACPICKUPMinV : 'AC PICKUP Min (V)'} rules={[{ required: true, message:langdata && langdata.PleaseentertheACPICKUPMinV ? langdata.PleaseentertheACPICKUPMinV : 'Please enter the AC PICKUP Min (V)' }]} >
								<Input name="vac2NormalMin" type="number" placeholder={langdata && langdata.PleaseentertheACPICKUPMinV ? langdata.PleaseentertheACPICKUPMinV : 'Please enter the AC PICKUP Min (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.vac2NormalMin} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ACPICKUPMaxV ? langdata.ACPICKUPMaxV : 'AC PICKUP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheACPICKUPMaxV ? langdata.PleaseentertheACPICKUPMaxV : 'Please enter the AC PICKUP Max (V)' }]} >
								<Input name="vac2NormalMax" type="number" placeholder={langdata && langdata.PleaseentertheACPICKUPMaxV ? langdata.PleaseentertheACPICKUPMaxV : 'Please enter the AC PICKUP Max (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.vac2NormalMax} />
							</Form.Item>
						</Col>
					</Row>
					{offPSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFPSPACPICKUPMinV ? langdata.OFFPSPACPICKUPMinV : 'OFF PSP AC PICKUP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFPSPACPICKUPMinV ? langdata.PleaseentertheOFFPSPACPICKUPMinV : 'Please enter the OFF PSP AC PICKUP Min (V)' }]} >
									<Input name="offPSPacNormalMin" type="number" placeholder={langdata && langdata.PleaseentertheOFFPSPACPICKUPMinV ? langdata.PleaseentertheOFFPSPACPICKUPMinV : 'Please enter the OFF PSP AC PICKUP Min (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.offPSPacNormalMin} disabled={!updateTLPFormik.values.offPSPEnabled}/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFPSPACPICKUPMaxV ? langdata.OFFPSPACPICKUPMaxV : 'OFF PSP AC PICKUP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFPSPACPICKUPMaxV ? langdata.PleaseentertheOFFPSPACPICKUPMaxV : 'Please enter the OFF PSP AC PICKUP Max (V)' }]} >
									<Input name="offPSPacNormalMax" type="number" placeholder={langdata && langdata.PleaseentertheOFFPSPACPICKUPMaxV ? langdata.PleaseentertheOFFPSPACPICKUPMaxV : 'Please enter the OFF PSP AC PICKUP Max (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.offPSPacNormalMax} disabled={!updateTLPFormik.values.offPSPEnabled} />
								</Form.Item>
							</Col>
						</Row>
						: null }
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.CSPMinV ? langdata.CSPMinV : 'CSP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheCSPMinV ? langdata.PleaseentertheCSPMinV :  'Please enter the CSP Min (V)' }]} >
								<Input name="vdc1NormalMin" type="number" placeholder={langdata && langdata.PleaseentertheCSPMinV ? langdata.PleaseentertheCSPMinV : 'Please enter the CSP Min (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.vdc1NormalMin} disabled={updateTLPFormik.values.secondVoltage ? false : true} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.CSPMaxV ? langdata.CSPMaxV : 'CSP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheCSPMaxV ? langdata.PleaseentertheCSPMaxV : 'Please enter the CSP Max (V)' }]} >
								<Input name="vdc1NormalMax" type="number" placeholder={langdata && langdata.PleaseentertheCSPMaxV ? langdata.PleaseentertheCSPMaxV : 'Please enter the CSP Max (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.vdc1NormalMax} disabled={updateTLPFormik.values.secondVoltage ? false : true} />
							</Form.Item>
						</Col>
					</Row>
					{offCSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFCSPMinV ? langdata.OFFCSPMinV : 'OFF CSP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFCSPMinV ? langdata.PleaseentertheOFFCSPMinV : 'Please enter the OFF CSP Min (V)' }]} >
									<Input name="offCSPdcNormalMin" type="number" placeholder={langdata && langdata.PleaseentertheOFFCSPMinV ? langdata.PleaseentertheOFFCSPMinV : 'Please enter the OFF CSP Min (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.offCSPdcNormalMin} disabled={!updateTLPFormik.values.secondVoltage || !updateTLPFormik.values.offCSPEnabled} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFCSPMaxV ? langdata.OFFCSPMaxV : 'OFF CSP Max (V)'} rules={[{ required: true, message:langdata && langdata.PleaseentertheOFFCSPMaxV ? langdata.PleaseentertheOFFCSPMaxV : 'Please enter the OFF CSP Max (V)' }]} >
									<Input name="offCSPdcNormalMax" type="number" placeholder={langdata && langdata.PleaseentertheOFFCSPMaxV ? langdata.PleaseentertheOFFCSPMaxV : 'Please enter the OFF CSP Max (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.offCSPdcNormalMax} disabled={!updateTLPFormik.values.secondVoltage || !updateTLPFormik.values.offCSPEnabled} />
								</Form.Item>
							</Col>
						</Row>
						: null}

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.CASINGACPICKUPMinV ? langdata.CASINGACPICKUPMinV : 'CASING AC PICKUP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheCASINGACPICKUPMinV ? langdata.PleaseentertheCASINGACPICKUPMinV :  'Please enter the CASING AC PICKUP Min (V)' }]} >
								<Input name="vac1NormalMin" type="number" placeholder={langdata && langdata.PleaseentertheCASINGACPICKUPMinV ? langdata.PleaseentertheCASINGACPICKUPMinV : 'Please enter the CASING AC PICKUP Min (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.vac1NormalMin} disabled={updateTLPFormik.values.secondVoltage ? false : true} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.CASINGACPICKUPMaxV ? langdata.CASINGACPICKUPMaxV : 'CASING AC PICKUP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheCASINGACPICKUPMaxV ? langdata.PleaseentertheCASINGACPICKUPMaxV : 'Please enter the CASING AC PICKUP Max (V)' }]} >
								<Input name="vac1NormalMax" type="number" placeholder={langdata && langdata.PleaseentertheCASINGACPICKUPMaxV ? langdata.PleaseentertheCASINGACPICKUPMaxV : 'Please enter the CASING AC PICKUP Max (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.vac1NormalMax} disabled={updateTLPFormik.values.secondVoltage ? false : true} />
							</Form.Item>
						</Col>
					</Row>

					{offCSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFCSPACPICKUPMinV ? langdata.OFFCSPACPICKUPMinV : 'OFF CSP AC PICKUP Min (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFCSPACPICKUPMinV ? langdata.PleaseentertheOFFCSPACPICKUPMinV : 'Please enter the OFF CSP AC PICKUP Min (V)' }]} >
									<Input name="offCSPacNormalMin" type="number" placeholder={langdata && langdata.PleaseentertheOFFCSPACPICKUPMinV ? langdata.PleaseentertheOFFCSPACPICKUPMinV : 'Please enter the OFF CSP AC PICKUP Min (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.offCSPacNormalMin} disabled={!updateTLPFormik.values.secondVoltage || !updateTLPFormik.values.offCSPEnabled} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFCSPACPICKUPMaxV ? langdata.OFFCSPACPICKUPMaxV : 'OFF CSP AC PICKUP Max (V)'} rules={[{ required: true, message: langdata && langdata.PleaseentertheOFFCSPACPICKUPMaxV ? langdata.PleaseentertheOFFCSPACPICKUPMaxV :  'Please enter the OFF CSP AC PICKUP Max (V)' }]} >
									<Input name="offCSPacNormalMax" type="number" placeholder={langdata && langdata.PleaseentertheOFFCSPACPICKUPMaxV ? langdata.PleaseentertheOFFCSPACPICKUPMaxV : 'Please enter the OFF CSP AC PICKUP Max (V)'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.offCSPacNormalMax} disabled={!updateTLPFormik.values.secondVoltage || !updateTLPFormik.values.offCSPEnabled} />
								</Form.Item>
							</Col>
						</Row>
						: null}

					{offPSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.EnableOFFPSPInterval ? langdata.EnableOFFPSPInterval : 'Enable OFF PSP Interval?'} >
									<Checkbox name="offPSPIntervalEnabled" onChange={updateTLPFormik.handleChange} checked={updateTLPFormik.values.offPSPIntervalEnabled} disabled={!updateTLPFormik.values.offPSPEnabled}/>
								</Form.Item>
							</Col>
							{updateTLPFormik.values.offPSPIntervalEnabled ?
								<>
									<Col span={11}>
										<Form.Item label={updateTLPFormik.values.offPSPpollFrequencyMinutesEnabled ? langdata && langdata.OFFPSPPollFrequencyMinutes ? langdata.OFFPSPPollFrequencyMinutes : 'OFF PSP Poll Frequency Minutes' :  langdata && langdata.OFFPSPPollFrequencyDays ? langdata.OFFPSPPollFrequencyDays : 'OFF PSP Poll Frequency Days'} rules={[{ required: true, message: updateTLPFormik.values.offPSPpollFrequencyMinutesEnabled ? langdata && langdata.PleaseentertheOFFPSPpollfrequencyminutes ? langdata.PleaseentertheOFFPSPpollfrequencyminutes : 'Please enter the OFF PSP poll frequency minutes' : langdata && langdata.PleaseentertheOFFPSPpollfrequencydays ? langdata.PleaseentertheOFFPSPpollfrequencydays : 'Please enter the OFF PSP poll frequency days' }]} >
											<Input name="offPSPpollFrequencyMinutes" type="number" placeholder={updateTLPFormik.values.offPSPpollFrequencyMinutesEnabled ? langdata && langdata.PleaseentertheOFFPSPpollfrequencyminutes ? langdata.PleaseentertheOFFPSPpollfrequencyminutes : 'Please enter the OFF PSP poll frequency minutes' : langdata && langdata.PleaseentertheOFFPSPpollfrequencydays ? langdata.PleaseentertheOFFPSPpollfrequencydays : 'Please enter the OFF PSP poll frequency days'} onChange={updateTLPFormik.handleChange} 
												value={updateTLPFormik.values.offPSPpollFrequencyMinutes} disabled={!updateTLPFormik.values.offPSPEnabled} 
												max={180}/>
										</Form.Item>
									</Col>
									<Col span={1}>
										<Form.Item
											label={' '} >
											<Tooltip placement="bottom" title={langdata && langdata.OFFPSPPollFrequencyMinutesEnabled ? langdata.OFFPSPPollFrequencyMinutesEnabled : 'OFF PSP Poll Frequency Minutes Enabled'}>
												<Switch size="small" onClick={(value) => { updateTLPFormik.setFieldValue('offPSPpollFrequencyMinutesEnabled', value); }}
													defaultChecked={updateTLPFormik.values.offPSPpollFrequencyMinutesEnabled} disabled={!updateTLPFormik.values.offPSPEnabled}/>
											</Tooltip>
										</Form.Item>
									</Col>
								</>
								:
								<Col span={12}>
									<Form.Item label={langdata && langdata.OFFPSPDateTime ? langdata.OFFPSPDateTime : 'OFF PSP Date Time'} rules={[{ required: true, message: langdata && langdata.PleaseselecttheOFFPSPdateandtime ? langdata.PleaseselecttheOFFPSPdateandtime : 'Please select the OFF PSP date and time' }]} >
										<DatePicker style={{ width : '100%'}} showTime name="offPSPDateTime"  placeholder={langdata && langdata.PleaseselecttheOFFPSPdateandtime ? langdata.PleaseselecttheOFFPSPdateandtime : 'Please select the OFF PSP date and time'} onChange={handleUpdateoffPSPDateTime}
											value={updateoffPSPDateTime ? dayjs(updateoffPSPDateTime) : updateTLPFormik.values && updateTLPFormik.values.offPSPDateTime ? dayjs(new Date(updateTLPFormik.values && updateTLPFormik.values.offPSPDateTime)) : null} 
											disabled={!updateTLPFormik.values.offPSPEnabled}/>
									</Form.Item>
								</Col>
							}
						</Row>
						: null }

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.EnableDataLoggingMode ? langdata.EnableDataLoggingMode : 'Enable Data Logging Mode?'} rules={[{ required: true }]} >
								<Checkbox name="dataLoggingMode" onChange={updateTLPFormik.handleChange} checked={updateTLPFormik.values.dataLoggingMode} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.DataLoggingModePollFrequencyMinutes ? langdata.DataLoggingModePollFrequencyMinutes : 'Data Logging Mode Poll Frequency Minutes'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthedataloggingmodepollfrequencyminutes ? langdata.Pleaseenterthedataloggingmodepollfrequencyminutes :  'Please enter the data logging mode poll frequency minutes' }]} >
								<Input name="dataLoggingModePollFrequencyMinutes" type="number" placeholder={langdata && langdata.Pleaseenterthedataloggingmodepollfrequencyminutes ? langdata.Pleaseenterthedataloggingmodepollfrequencyminutes : 'Please enter the data logging mode poll frequency minutes'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.dataLoggingModePollFrequencyMinutes} disabled={!updateTLPFormik.values.dataLoggingMode}/>
							</Form.Item>
						</Col>
					</Row>
					{currentDensityEnabled ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item  label={langdata && langdata.CouponFactorAC ? langdata.CouponFactorAC : 'Coupon Factor AC'} rules={[{ message: langdata && langdata.PleaseenterthecouponfactorAC ? langdata.PleaseenterthecouponfactorAC : 'Please enter the coupon factor AC'}]}>
									<Input name="couponFactorAC" type='number'  placeholder={langdata && langdata.PleaseenterthecouponfactorAC ? langdata.PleaseenterthecouponfactorAC : 'Please enter the coupon factor AC'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.couponFactorAC}/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.CouponFactorDC ? langdata.CouponFactorDC : 'Coupon Factor DC'} rules={[{ message: langdata && langdata.PleaseenterthecouponfactorDC ? langdata.PleaseenterthecouponfactorDC : 'Please enter the coupon factor DC' }]} >
									<Input name="couponFactorDC" type='number'  placeholder={langdata && langdata.PleaseenterthecouponfactorDC ? langdata.PleaseenterthecouponfactorDC : 'Please enter the coupon factor DC'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.couponFactorDC}/>
								</Form.Item>
							</Col>
						</Row>
						: null }

					{/* {offPSPEnabled == true ?
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.OFFPSPDateTime ? langdata.OFFPSPDateTime : 'OFF PSP Date Time'} rules={[{ required: true, message: langdata && langdata.PleaseentertheCASINGACPICKUPMinV ? langdata.PleaseentertheCASINGACPICKUPMinV : 'Please enter the CASING AC PICKUP Min (V)' }]} >
									<DatePicker style={{ width : '100%'}} showTime name="offPSPDateTime"  placeholder={langdata && langdata.PleaseselecttheOFFPSPdateandtime ? langdata.PleaseselecttheOFFPSPdateandtime : 'Please select the OFF PSP date and time'} onChange={handleUpdateoffPSPDateTime}
										value={updateoffPSPDateTime ? dayjs(updateoffPSPDateTime) : updateTLPFormik.values && updateTLPFormik.values.offPSPDateTime ? dayjs(new Date(updateTLPFormik.values && updateTLPFormik.values.offPSPDateTime)) : null} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={ langdata && langdata.ONPSPTime ? langdata.ONPSPTime : 'ON PSP Time'} rules={[{ required: true, message: langdata && langdata.PleaseentertheCASINGACPICKUPMaxV ? langdata.PleaseentertheCASINGACPICKUPMaxV :  'Please enter the CASING AC PICKUP Max (V)' }]} >
									<TimePicker name="onPSPTime" style={{ width : '100%'}}  placeholder={langdata && langdata.PleaseselecttheONPSPTime ? langdata.PleaseselecttheONPSPTime : 'Please select the ON PSP Time'} onChange={(value, dateString) => updateTLPFormik.setFieldValue('onPSPTime', dateString)}
										value={updateTLPFormik.values && updateTLPFormik.values.onPSPTime ? dayjs(updateTLPFormik.values && updateTLPFormik.values.onPSPTime, 'HH:mm:ss') : null} />
								</Form.Item>
							</Col>
						</Row>
						:
						null } */}
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Solarpanelavailable ? langdata.Solarpanelavailable : 'Solar panel available?'} >
								<Checkbox name="hasSolarPanel" onChange={updateTLPFormik.handleChange} checked={updateTLPFormik.values.hasSolarPanel} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Measurecurrent ? langdata.Measurecurrent : 'Measure current?'} >
								<Checkbox name="measureCurrent" onChange={updateTLPFormik.handleChange} checked={updateTLPFormik.values.measureCurrent} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.Frimwareversionupdateavailable ? langdata.Frimwareversionupdateavailable : 'Firmware version available?'} >
								<Checkbox name="otaUpdateAvailable" onChange={updateTLPFormik.handleChange} checked={updateTLPFormik.values.otaUpdateAvailable} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Frimwareversion ? langdata.Frimwareversion : 'Firmware version'} rules={[{ message: langdata && langdata.Pleaseenterthefrimwareversion ? langdata.Pleaseenterthefrimwareversion : 'Please enter the firmware version' }]} >
								<Input name="otaVersion" type="number" placeholder={langdata && langdata.Pleaseenterthefrimwareversion ? langdata.Pleaseenterthefrimwareversion : 'Please enter the firmware version'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.otaVersion} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.SMSNumber1 ? langdata.SMSNumber1 : 'SMS Number 1'} rules={[{ message: langdata && langdata.Pleaseenterthefirstnumber ? langdata.Pleaseenterthefirstnumber : 'Please enter the first number'}]}>
								<Input name="num1"  placeholder={langdata && langdata.Pleaseenterthefirstnumber ? langdata.Pleaseenterthefirstnumber : 'Please enter the first number'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.num1} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.SMSNumber2 ? langdata.SMSNumber2 : 'SMS Number 2'} rules={[{ message: langdata && langdata.Pleaseenterthesecondnumber ? langdata.Pleaseenterthesecondnumber : 'Please enter the second number' }]} >
								<Input name="num2"  placeholder={langdata && langdata.Pleaseenterthesecondnumber ? langdata.Pleaseenterthesecondnumber : 'Please enter the second number'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.num2} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.SMSNumber3 ? langdata.SMSNumber3 : 'SMS Number 3'} rules={[{ message: langdata && langdata.Pleaseenterthethirdnumber ? langdata.Pleaseenterthethirdnumber : 'Please enter the third number' }]} >
								<Input name="num3"  placeholder={langdata && langdata.Pleaseenterthethirdnumber ? langdata.Pleaseenterthethirdnumber : 'Please enter the third number'} onChange={updateTLPFormik.handleChange} value={updateTLPFormik.values.num3} />
							</Form.Item>
						</Col>
					</Row>
					<Row type="flex">
						<p style={{fontSize: '12', paddingTop: '10'}}>
							{tlp_feature && tlp_feature.refElectrodeType && tlp_feature.refElectrodeType == 2 ?
								<span>{langdata && langdata.ThevoltagerangehastobeenteredwithrespecttotheCuCuSO4referencecell ? langdata.ThevoltagerangehastobeenteredwithrespecttotheCuCuSO4referencecell : 'The voltage range has to be entered with respect to the Cu-CuSO4 reference cell.'}</span> :
								<span>{langdata && langdata.ThevoltagerangehastobeenteredwithrespecttotheZincreferencecell ? langdata.ThevoltagerangehastobeenteredwithrespecttotheZincreferencecell : 'The voltage range has to be entered with respect to the Zinc reference cell.'}</span> }
							<br/>
							{langdata && langdata.PSPAC2PSPDC2Hardwarewiring ? langdata.PSPAC2PSPDC2Hardwarewiring : 'PSP AC2, PSP DC2 - Hardware wiring'} - <span  style={{color: 'green'}}>{langdata && langdata.GREEN ? langdata.GREEN : 'GREEN'}</span>
							<br/>
							{langdata && langdata.CSPAC1CSPDC1Hardwarewiring ? langdata.CSPAC1CSPDC1Hardwarewiring : 'CSP AC1, CSP DC1 - Hardware wiring'} - <span  style={{color: 'red'}}>{langdata && langdata.RED ? langdata.RED : 'RED'}</span>
							<br/>
							{tlp_feature && tlp_feature.refElectrodeType && tlp_feature.refElectrodeType == 2 ? 'Cu-CuSO4' : 'Zinc'} {langdata && langdata.ReferenceHardwarewiring ? langdata.ReferenceHardwarewiring : 'Reference - Hardware wiring'} - <span  style={{color: 'black'}}>{langdata && langdata.BLACK ? langdata.BLACK : 'BLACK'}</span>
							<br/>
							{langdata && langdata.CurrentHardwarewiring ? langdata.CurrentHardwarewiring : 'Current - Hardware wiring'} - <span  style={{color: 'blue'}}>{langdata && langdata.BLUE ? langdata.BLUE : 'BLUE'}</span>
						</p>
					</Row>
				</Form>
			</Modal>
			{/* Edit TLP Ends */}

			{/* Set TRU Voltage Starts */}
			<Modal
				title={langdata && langdata.SetTRUVoltage ? langdata.SetTRUVoltage : 'Set TRU Voltage'}
				centered
				open={showTRUVoltageModal}
				onOk={{closeTRUVoltageModal}}
				onCancel={closeTRUVoltageModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeTRUVoltageModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ?  <Button key="submit" type="primary" onClick={truVoltageFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
					</Space>
				]}
				width={640}
			>
				<Form layout="vertical" onSubmit={truVoltageFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item  label={langdata && langdata.TRUvoltage ? langdata.TRUvoltage : 'TRU voltage'} rules={[{ message: langdata && langdata.PleasespecifytheTRUvoltage ? langdata.PleasespecifytheTRUvoltage : 'Please specify the TRU voltage' }]} >
								<Input name="truVoltage" placeholder={langdata && langdata.PleasespecifytheTRUvoltage ? langdata.PleasespecifytheTRUvoltage : 'Please specify the TRU voltage'} onChange={truVoltageFormik.handleChange} value={truVoltageFormik.values.truVoltage} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Set TRU Voltage Modal Ends */}

			{/* QRCode Modal Starts */}
			<Modal
				title={langdata && langdata.TLPQRCode ? langdata.TLPQRCode : 'TLP QR Code'}
				centered
				open={showQRCodeModal}
				onOk={{closeTLPQRCodeModal}}
				onCancel={closeTLPQRCodeModal}
				footer={[]}
				width={480}
			>
				<center>
					<Row gutter={16}>
						<Col span={24}>
							<QRCode size={120} value = {data.details.deviceId} />
						</Col>
						<Col span={24}>&nbsp;</Col>
						<Col span={24}>
							<Typography>TLP ID: {data.details.deviceId}</Typography>
						</Col>
					</Row>
				</center>
          
			</Modal>
			{/* QRCode Modal Ends */}

			{/* Delete Modal Starts */}
			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteTLPModal} onOk={deleteTLP} onCancel={closeDeleteTLPModal}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeDeleteTLPModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button key="submit" type="primary" onClick={deleteTLP}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button> 
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>{langdata && langdata.AreyousureyouwanttodeletethisTLPdevice ? langdata.AreyousureyouwanttodeletethisTLPdevice : 'Are you sure you want to delete this TLP device?'}</Col>
				</Row>
			</Modal>
			{/* Delete Modal Ends */}

			{/* Cancel Manual Override Modal Starts */}
			<Modal title={langdata && langdata.Confirm ? langdata.Confirm : 'Confirm'} open={showCancelManualOverrideModal} onOk={cancelManualOverride} onCancel={closeCancelManualOverrideModal}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeCancelManualOverrideModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button key="submit" type="primary" onClick={deleteTLP}>{langdata && langdata.OK ? langdata.OK : 'OK'}</Button> 
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<RotateLeftOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>{langdata && langdata.Areyousureyouwanttoturnoffthemanualoverridemode ? langdata.Areyousureyouwanttoturnoffthemanualoverridemode : 'Are you sure you want to turn off the manual override mode?'}</Col>
				</Row>
			</Modal>
			{/* Cancel Manual Override Modal Ends */}
      
			{/* Upload Frimware Modal Starts */}
			<Modal
				title={langdata && langdata.UploadFrimware ? langdata.UploadFrimware : 'Upload Firmware'}
				centered
				open={showUploadFrimwareModal}
				onOk={{closeUploadFrimwareModal}}
				onCancel={closeUploadFrimwareModal}
				footer={[
					<Button key="submit" type="primary" onClick={uploadFOTAFormik.handleSubmit}>{langdata && langdata.UPLOAD ? langdata.UPLOAD : 'UPLOAD'}</Button>
				]}
				width={640}
			>
				<Form layout="vertical" onSubmit={uploadFOTAFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.FrimwareJSONfile ? langdata.FrimwareJSONfile : 'Firmware JSON file'} rules={[{ message: langdata && langdata.PleaseuploadthefrimwareJSONfile ? langdata.PleaseuploadthefrimwareJSONfile : 'Please upload the firmware JSON file' }]} >
								<Input name="frimwareJSONFile" type="file" placeholder={langdata && langdata.PleaseuploadthefrimwareJSONfile ? langdata.PleaseuploadthefrimwareJSONfile : 'Please upload the firmware JSON file'} onChange={(event) => uploadFOTAFormik.setFieldValue('frimwareJSONFile', event.currentTarget.files[0])} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Frimwarebinaryfile ? langdata.Frimwarebinaryfile : 'Firmware binary file'} rules={[{ message: langdata && langdata.Pleaseuploadthefrimwarebinaryfile ? langdata.Pleaseuploadthefrimwarebinaryfile : 'Please upload the firmware binary file' }]} >
								<Input name="frimwareBinaryFile" type="file" placeholder={langdata && langdata.Pleaseuploadthefrimwarebinaryfile ? langdata.Pleaseuploadthefrimwarebinaryfile : 'Please upload the firmware binary file'} onChange={(event) => uploadFOTAFormik.setFieldValue('frimwareBinaryFile', event.currentTarget.files[0])} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item  label={langdata && langdata.Frimwareversion ? langdata.Frimwareversion : 'Firmware version'} rules={[{ message: langdata && langdata.Pleasespecifythefrimwareversionnumber ? langdata.Pleasespecifythefrimwareversionnumber :  'Please specify the firmware version number' }]} >
								<Input name="frimwareVersion" type="number" placeholder={langdata && langdata.Pleasespecifythefrimwareversionnumber ? langdata.Pleasespecifythefrimwareversionnumber : 'Please specify the firmware version number'} onChange={uploadFOTAFormik.handleChange} value={uploadFOTAFormik.values.frimwareVersion} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Upload Frimware Modal Ends */}

			{/* Replace TLP Starts */}
			<Modal
				title={langdata && langdata.ReplaceTLP ? langdata.ReplaceTLP : 'Replace TLP'}
				centered
				open={showReplaceTLPModal}
				onOk={{closeReplaceTLPModal}}
				onCancel={closeReplaceTLPModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeReplaceTLPModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={replaceTLPFormik.handleSubmit}>{langdata && langdata.REPLACE ? langdata.REPLACE : 'REPLACE'}</Button> : null }
					</Space>
				]}
				width={640}
			>
				<Form layout="vertical" onSubmit={replaceTLPFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item  label={langdata && langdata.Existingdeviceid ? langdata.Existingdeviceid :'Existing device id'} rules={[{ message: langdata && langdata.Pleasespecifytheexistingdeviceid ? langdata.Pleasespecifytheexistingdeviceid :  'Please specify the existing device id' }]} >
								<Input name="existingDeviceId" placeholder={langdata && langdata.Pleasespecifytheexistingdeviceid ? langdata.Pleasespecifytheexistingdeviceid : 'Please specify the existing device id'} onChange={replaceTLPFormik.handleChange} value={replaceTLPFormik.values.existingDeviceId} disabled/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Newdeviceid ? langdata.Newdeviceid : 'New device id'} rules={[{ message: langdata && langdata.Pleasespecifythenewdeviceid ? langdata.Pleasespecifythenewdeviceid : 'Please specify the new device id' }]} >
								<Input name="newDeviceId"  placeholder={langdata && langdata.Pleasespecifythenewdeviceid ? langdata.Pleasespecifythenewdeviceid : 'Please specify the new device id'} onChange={replaceTLPFormik.handleChange} value={replaceTLPFormik.values.newDeviceId}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Replace TLP Modal Ends */}
		</>
	);
}