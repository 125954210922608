import { jsPDF } from 'jspdf';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import frenchlan from '../../../french.json';
import malayalamlan from '../../../malayalam.json';

const loadImageAsBase64 = (urls1, urls2) => {
	// Load images from both arrays concurrently
	const loadFromArray = (urls) => {		
		return Promise.all(
			urls && urls.map((url) => {
				return new Promise((resolve, reject) => {
					const img = new Image();
					img.crossOrigin = 'Anonymous';  // Allow cross-origin images
					img.onload = () => {
						const canvas = document.createElement('canvas');
						canvas.width = img.width;
						canvas.height = img.height;
						const ctx = canvas.getContext('2d');
						ctx.drawImage(img, 0, 0);
						const base64Data = canvas.toDataURL('image/jpeg'); // You can change to PNG or other formats
						resolve(base64Data);
					};
					img.onerror = (error) => reject(error);
					img.src = url;
				});
			})
		);
	};
  
	// Load both arrays of images concurrently
	return Promise.all([loadFromArray(urls1), loadFromArray(urls2)]);
};

const DownloadPDF = (jsonData, batchNo) => {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? englan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? englan : langid == 6 ? malayalamlan : englan;

	const generatePDF = async () => {
		if (!jsonData || jsonData.length === 0) return;
	
		// Create a new jsPDF instance
		const pdf = new jsPDF();
	
		// Define the initial Y position
		let yPosition = 20;
		const pageHeight = pdf.internal.pageSize.height;
		// const pageHeight = 250;
		
		// Title and initial setup
		pdf.setFontSize(20);
		pdf.text( langdata && langdata.AlarmReport ? langdata.AlarmReport : 'Alarm Report', 10, yPosition);
		yPosition += 10; // Adjust the starting position for content
			
		
		// Set font for table data
		pdf.setFontSize(10);
	
		// Create the table headers (if necessary)
		
		// Prepare an array of promises to load images
		const imagePromises = jsonData.map((item) => {
			return loadImageAsBase64(item && item.acknowledgedimageUrl && item.acknowledgedimageUrl,  item && item.resolutionimageUrl && item.resolutionimageUrl).then(([acknowledgedbase64Image, resolutionbase64Image]) => {				
				return {
					id: item.alarm_prm_id.toString(),
					eventType: item.eventType,
					description: item.description,
					acknowledgedbase64Image,
					date: item.date,
					PIDS: item.pidsname,
					AcknowledgedActivity: item && item.acknowledgedActivity && item.acknowledgedActivity,
					AcknowledgedDateTime: item.acknowledgedDateTime,
					AcknowledgedBy: item.acknowledgedBy,
					AcknowledgedRemark: item.acknowledgedRemark,
					ResolutionReportedEvent: item.resolutionreportedEvent,
					Status: item.status,
					ResolutionAddedby: item.resolutionAddedby,
					ResolutionDateTime: item.resolutionDateTime,
					ResolutionRemark: item.resolutionRemark,
					resolutionbase64Image
				};
			}).catch(() => {
				// Handle image load failure gracefully
				return {
					id: item.alarm_prm_id.toString(),
					eventType: item.eventType,
					description: item.description,
					acknowledgedbase64Image: null,
					date: item.date,
					PIDS: item.pidsname,
					AcknowledgedActivity: item && item.acknowledgedActivity && item.acknowledgedActivity,
					AcknowledgedDateTime: item.acknowledgedDateTime,
					AcknowledgedBy: item.acknowledgedBy,
					AcknowledgedRemark: item.acknowledgedRemark,
					ResolutionReportedEvent: item.resolutionreportedEvent,
					Status: item.status,
					ResolutionAddedby: item.resolutionAddedby,
					ResolutionDateTime: item.resolutionDateTime,
					ResolutionRemark: item.resolutionRemark,
					resolutionbase64Image : null
				};
			});
		});
	
		// Wait for all image loading promises to complete
		const imageDataArray = await Promise.all(imagePromises);
	
		// Loop through the data and add rows to the table
		for (const {
			id,
			eventType,
			acknowledgedbase64Image,
			date,
			PIDS,
			AcknowledgedActivity,
			AcknowledgedDateTime,
			AcknowledgedBy,
			AcknowledgedRemark,
			ResolutionReportedEvent,
			Status,
			ResolutionAddedby,
			ResolutionDateTime,
			ResolutionRemark,
			resolutionbase64Image
		} of imageDataArray) {
			// Add sections for alarm details
			pdf.setFontSize(16);
			pdf.text(langdata && langdata.AlarmDetails ? langdata.AlarmDetails : 'Alarm Details', 10, yPosition);
			pdf.setFontSize(10);
			yPosition += 10;
				
			// Add data for each section, e.g., Alarm Id, Activity, etc.
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.AlarmId ? langdata.AlarmId + ' :' : 'Alarm Id :', 10, yPosition);
			if (id) {
				pdf.text(id, 30, yPosition);
			}
			yPosition += 10;
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.Activity ? langdata.Activity  + ' :' : 'Activity :', 10, yPosition);
			if (eventType) {
				pdf.text(eventType, 30, yPosition);
			}
			yPosition += 10;
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.Date ? langdata.Date + ' :' : 'Date :', 10, yPosition);
			if (date) {
				pdf.text(date, 30, yPosition);
			}
			yPosition += 10;
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.PIDS ? langdata.PIDS + ' :' : 'PIDS :', 10, yPosition);
			if (PIDS) {
				pdf.text(PIDS, 30, yPosition);
			}
			yPosition += 10;
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.Status ? langdata.Status + ' ;' : 'Status :', 10, yPosition);
			if (Status) {
				pdf.text(Status, 30, yPosition);
			}
			yPosition += 20;
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			// Acknowledgement details section
			pdf.setFontSize(16);
			pdf.text(langdata && langdata.AcknowledgementDetails ? langdata.AcknowledgementDetails : 'Acknowledgement Details', 10, yPosition);
			pdf.setFontSize(10);
			yPosition += 10;
	
			if (yPosition + 10 > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition for the new page
			}
	
			pdf.text(langdata && langdata.Activity ? langdata.Activity + ' :' : 'Activity :', 10, yPosition);
			if (AcknowledgedActivity) {
				pdf.text(AcknowledgedActivity, 30, yPosition);
			}
			yPosition += 10;
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.Date ? langdata.Date + ' :' : 'Date :', 10, yPosition);
			if (AcknowledgedDateTime) {
				pdf.text(AcknowledgedDateTime, 30, yPosition);
			}
			yPosition += 10;
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.AcknowledgedBy ? langdata.AcknowledgedBy + ' :' : 'Acknowledged By :', 10, yPosition);
			if (AcknowledgedBy) {
				pdf.text(AcknowledgedBy, 50, yPosition);
			}
			yPosition += 10;
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.Remark ? langdata.Remark + ' :' : 'Remark :', 10, yPosition);
			if (AcknowledgedRemark) {
				pdf.text(AcknowledgedRemark, 30, yPosition);
			}
			yPosition += 10;
	
			if (yPosition + 30 > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			// Add image if available
			pdf.text(langdata && langdata.Attachments ? langdata.Attachments + ' :' : 'Attachments :', 10, yPosition);
	
			if (acknowledgedbase64Image) {
				if (yPosition + 20 > pageHeight) {
					pdf.addPage();
					yPosition = 10;
				}
	
				// yPosition = 40;
				let xPosition = 40;
				acknowledgedbase64Image && acknowledgedbase64Image.map((image) => {					
					let imageFormat = image.includes('image/png') ? 'PNG' : 'JPEG';
					pdf.addImage(image, imageFormat, xPosition, yPosition - 5, 20, 20);
					xPosition += 40;
					if(xPosition > 160){
						yPosition += 40;
						xPosition = 40;
					}
					if (yPosition + 20 > pageHeight) {
						pdf.addPage();
						yPosition = 10;
					}
				});				
				yPosition += 40;
			} else {
				pdf.text(langdata && langdata.Attachmentsnotfound ? langdata.Attachmentsnotfound : 'Attachments not found', 40, yPosition);
				yPosition += 20;
			}
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			// Resolution details section
			pdf.setFontSize(16);
			pdf.text(langdata && langdata.ResolutionDetails ? langdata.ResolutionDetails : 'Resolution Details', 10, yPosition);
			pdf.setFontSize(10);
			yPosition += 10;
				
	
			if (yPosition + 10 > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition for the new page
			}
	
			pdf.text(langdata && langdata.Activity ? langdata.Activity + ' :' : 'Activity :', 10, yPosition);
			if (ResolutionReportedEvent) {
				pdf.text(ResolutionReportedEvent, 30, yPosition);
			}
			yPosition += 10;
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.ResolutionAddedby ? langdata.ResolutionAddedby + ' :' : 'Resolution Added by :', 10, yPosition);
			if (ResolutionAddedby) {
				pdf.text(ResolutionAddedby, 50, yPosition);
			}
			yPosition += 10;
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.Date ? langdata.Date + ' :' : 'Date :', 10, yPosition);
			if (ResolutionDateTime) {
				pdf.text(ResolutionDateTime, 30, yPosition);
			}
			yPosition += 10;
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.Remark ? langdata.Remark + ' :' : 'Remark :', 10, yPosition);
			if (ResolutionRemark) {
				pdf.text(ResolutionRemark, 30, yPosition);
			}
			yPosition += 10;
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
	
			pdf.text(langdata && langdata.Attachments ? langdata.Attachments + ' :' : 'Attachments :', 10, yPosition);
			if (resolutionbase64Image) {
				if (yPosition + 20 > pageHeight) {
					pdf.addPage();
					yPosition = 10;
				}
	
				// yPosition = 40;
				let xPosition = 40;
				resolutionbase64Image && resolutionbase64Image.map((image) => {
					let imageFormat = image.includes('image/png') ? 'PNG' : 'JPEG';
					pdf.addImage(image, imageFormat, xPosition, yPosition - 5, 20, 20);
					xPosition += 40;
					if(xPosition > 160){
						yPosition += 40;
						xPosition = 40;
					}
					if (yPosition + 20 > pageHeight) {
						pdf.addPage();
						yPosition = 10;
					}
				});				
				yPosition += 40;
			} else {
				pdf.text(langdata && langdata.Attachmentsnotfound ? langdata.Attachmentsnotfound : 'Attachments not found', 40, yPosition);
				yPosition += 20;
			}
	
			if (yPosition > pageHeight) {
				pdf.addPage();
				yPosition = 10; // Reset yPosition when adding a new page
			}
				
	
		}
	
		// Save the generated PDF
		pdf.save(`${langdata && langdata.Alarmreport ? langdata.Alarmreport :'Alarm-report'}-${batchNo + 1}.pdf`);	
	};
	
	generatePDF();

	return null; // No UI rendered for this component
};

export default DownloadPDF;
