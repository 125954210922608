/* eslint-disable react/prop-types */
import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableRow from './InvoiceTableRow';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import frenchlan from '../../../french.json';
import malayalamlan from '../../../malayalam.json';

const borderColor = '#00519C';

const styles = StyleSheet.create({
	tableContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 24,
		borderWidth: 1,
		borderColor: '#3778C2'
	},
	container: {
		flexDirection: 'row',
		borderBottomColor: '#00519C',
		backgroundColor: '#00519C',
		color: '#fff',
		borderBottomWidth: 1,
		alignItems: 'center',
		height: 24,
		textAlign: 'center',
		fontStyle: 'bold',
		flexGrow: 1
	},
	Id: {
		width: '9%',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		fontSize:12,
		textAlign:'center'
	},
	Activity: {
		width: '14%',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		fontSize:12,
		textAlign:'center'


	},
	Date: {
		width: '12%',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		fontSize:12,
		textAlign:'center'

	},
	Remarks: {
		width: '14%',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		fontSize:12,
		textAlign:'center'

	},
	EndRemarks: {
		width: '14%',
		fontSize:12,
		textAlign:'center'

	},
	User: {
		width: '13%',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		fontSize:12,
		textAlign:'center'

	},
});


const InvoiceItemsTable = (props) => {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? englan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? englan : langid == 6 ? malayalamlan : englan;

	return(
		<View style={styles.tableContainer}>
			{/* Invoice Table Header */}
			<View style={styles.container}>
				<Text style={styles.Id}>{langdata && langdata.Id ? langdata.Id:'Id'}</Text>
				<Text style={styles.Activity}>{langdata && langdata.Activity ? langdata.Activity:'Activity'}</Text>
				<Text style={styles.Date}>{langdata && langdata.Date ? langdata.Date:'Date'}</Text>
				<Text style={styles.Id}>{langdata && langdata.Status ? langdata.Status:'Status'}</Text>
				<Text style={styles.User}>{langdata && langdata.Ackby ? langdata.Ackby:'Ack by'}</Text>
				<Text style={styles.Remarks}>{langdata && langdata.Remarks ? langdata.Remarks:'Remarks'}</Text>
				<Text style={styles.User}>{langdata && langdata.Resby ? langdata.Resby:'Res by'}</Text>
				<Text style={styles.EndRemarks}>{langdata && langdata.Remarks ? langdata.Remarks:'Remarks'}</Text>

			</View>
			{/* Invoice Table Rows */}
			<InvoiceTableRow data={props.invoice} />
		</View>
	);
};

export default InvoiceItemsTable;