/* eslint-disable react/prop-types */
import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import frenchlan from '../../../french.json';
import malayalamlan from '../../../malayalam.json';

const borderColor = '#3778C2';
const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		// borderBottomColor: "#3778C2",
		// borderBottomWidth: 1,
		alignItems: 'center',
		height: 80,
		fontStyle: 'bold'
	},

	Id: {
		width: '9%',
		textAlign: 'center',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		paddingLeft: 8,
		fontSize: '8px',
		height:'100%'

	},
	Activity: {
		width: '14%',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		textAlign: 'center',
		paddingRight: 8,
		fontSize: '8px',
		height:'100%'
	},
	Date: {
		width: '12%',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		textAlign: 'right',
		paddingRight: 8,
		fontSize: '8px',
		height:'100%'
	},
	User: {
		width: '13%',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		textAlign: 'center',
		paddingRight: 8,
		fontSize: '8px',
		height:'100%'
	},
	Remarks: {
		width: '14%',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		textAlign: 'center',
		paddingRight: 8,
		fontSize: '8px',
		height:'100%'

	},
	EndRemarks: {
		width: '14%',
		textAlign: 'center',
		paddingRight: 8,
		fontSize: '8px',
		height:'100%'


	},

	attachments: {
		flexDirection: 'row',
		// borderBottomColor: "#3778C2",
		// borderBottomWidth: 1,
		alignItems: 'center',
		height: 80,
		fontStyle: 'bold',
		width: '14%',
		textAlign: 'right',
		paddingRight: 8,
		fontSize: '8px',
		marginTop:'10px'
	},

	endborder: {
		flexDirection: 'row',
		borderBottomColor: '#3778C2',
		borderBottomWidth: 1,
		alignItems: 'center',
		// height: 80,
		fontStyle: 'bold',
		// width: "14%",
		textAlign: 'right',
		paddingRight: 8,
		fontSize: '8px',
		width:'100%'
	},

	ackattachments: {
		flexDirection: 'row',
		// borderBottomColor: "#3778C2",
		// borderBottomWidth: 1,
		alignItems: 'center',
		height: 100,
		fontStyle: 'bold',
		width: '20%',
		textAlign: 'right',
		paddingRight: 8,
		fontSize: '8px',
		marginTop:'50px'
	},

	resattachments: {
		flexDirection: 'row',
		// borderBottomColor: "#3778C2",
		// borderBottomWidth: 1,
		alignItems: 'center',
		height: 100,
		fontStyle: 'bold',
		width: '18%',
		textAlign: 'right',
		paddingRight: 8,
		fontSize: '8px',
		marginTop:'50px'
	},
	logo: {
		width: 'auto',
		height: 75,
		margin:'10px'
	},
});

const InvoiceTableRow = (props) => {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? englan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? englan : langid == 6 ? malayalamlan : englan;

	
	const rows = props.data && props.data.map((item) => (
		<>
			<View style={styles.row} key={item.alarm_prm_id.toString()}>
				<Text style={styles.Id}>{item.alarm_prm_id}</Text>
				<Text style={styles.Activity}>{item.eventType}</Text>
				<Text style={styles.Date}>{item.date}</Text>
				<Text style={styles.Id}>{item.status}</Text>
				<Text style={styles.User}>{item.acknowledgedBy}</Text>
				<Text style={styles.Remarks}>{item.acknowledgedRemark}</Text>
				<Text style={styles.User}>{item.resolutionAddedby}</Text>
				<Text style={styles.EndRemarks}>{item.resolutionRemark}</Text>
			</View>
			<Text style={styles.endborder}></Text>

			{item && item.acknowledgedimageUrl && item.acknowledgedimageUrl ?
				<>
					<Text style={styles.ackattachments}>{langdata && langdata.Acknowledgedattachments ? langdata.Acknowledgedattachments:'Acknowledged attachments'}:</Text>

					{item && item.acknowledgedimageUrl && item.acknowledgedimageUrl.map((inneritem) => (
						// <View style={styles.row} key={item.sno.toString()}>
						<Image key={inneritem}
							style={styles.logo}
							src={inneritem}
						/>
						// <Text key={inneritem} style={styles.attachments}>{inneritem}</Text>
						// </View>
					))}

				</>:null
			}

			{item && item.resolutionimageUrl && item.resolutionimageUrl ?
				<>
					<Text style={styles.resattachments}>{langdata && langdata.Resolutionattachments ? langdata.Resolutionattachments:'Resolution attachments'}:</Text>

					{item && item.resolutionimageUrl && item.resolutionimageUrl.map((inneritem) => (
						// <View style={styles.row} key={item.sno.toString()}>
						<Image key={inneritem}
							style={styles.logo}
							src={inneritem}
						/>				
						// </View>
					))}
				</>:null
			}

			<Text style={styles.endborder}></Text>

		</>
	));
	return <>{rows}</>;
};

export default InvoiceTableRow;