import { datetimeCovertertolocal } from '../../datetime-converter';
import { CPS_REPORT_LIST, CPS_REPORT_LIST_SUCCESS, CPS_REPORT_LIST_ERROR }  from './constants';
import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';

export default function cpsReportReducer(state, action) {
	let pagination = {};
	let cps = [];
	let tlps = [];

	switch (action.type) {
	case TLPS_LIST:
		return { ...state, loading: true, error: false };

	case TLPS_LIST_SUCCESS:
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.deviceId = item.deviceId ? item.deviceId : 0;
				data.pipelineChainage = item.pipelineChainage ? parseFloat(item.pipelineChainage)/1000 : 0;

				tlps.push(data);
			});

			let sortedTLPS = tlps.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, tlps: sortedTLPS, loading: false, error: false };
		} else {
			return { ...state, tlps: [], loading: false, error: true };
		}

	case TLPS_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case CPS_REPORT_LIST:
		return { ...state, loading: true, error: false };

	case CPS_REPORT_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				const datetime = datetimeCovertertolocal(item.smarttlp ?  item.smarttlp.lastSync : '<Not Set>');
				const newdate = datetime.localdate + ' ' +  datetime.localtime;
				const devicedatetime = datetimeCovertertolocal(item ?  item.datetime : '<Not Set>');
				const newdevicedate = devicedatetime.localdate + ' ' +  devicedatetime.localtime;
				let systemStatus = item.smarttlp.systemStatus;
				if(systemStatus) {
					for (let i = 0; i < systemStatus.length; i++) {
						if(systemStatus.includes(',')) {
							systemStatus = systemStatus.replace(',', ' | ');
						}
					}

					if(systemStatus.includes('vdc2')) {
						systemStatus = systemStatus.replace('vdc2', 'PSP');
					}
					if(systemStatus.includes('vdc1')) {
						systemStatus = systemStatus.replace('vdc1', 'CSP');
					}
					if(systemStatus.includes('vac1')) {
						systemStatus = systemStatus.replace('vac1', 'CASING AC PICKUP');
					}
					if(systemStatus.includes('vac2')) {
						systemStatus = systemStatus.replace('vac2', 'AC PICKUP');
					}
				}
						
				let data = {};

				data.id = item.id ? parseInt(item.id) : 0;
				data.pipelineChainage = item.smarttlp ?  parseFloat(item.smarttlp.pipelineChainage)/1000 : 0;
				data.deviceId = item.smarttlp ?  item.smarttlp.deviceId : 0;
				data.lastSync = item.smarttlp && item.smarttlp.lastSync  ? newdate : '-';
				data.vdc1 = item.vdc1 ? parseFloat(item.vdc1).toFixed(4) : 0;
				data.vac1 = item.vac1 ? parseFloat(item.vac1).toFixed(4) : 0;
				data.vdc2 = item.vdc2 ? parseFloat(item.vdc2).toFixed(4) : 0;
				data.vac2 = item.vac2 ? parseFloat(item.vac2).toFixed(4) : 0;
				data.vbat = item.vbat ? parseFloat(item.vbat).toFixed(2) : 0;
				data.temp = item.temp ? parseFloat(item.temp).toFixed(2) : 0;
				data.current = item.current ? parseFloat(item.current).toFixed(2) : 0;
				data.anomaly = item.anomaly == true  ?  'Anomaly' : 'Normal';
				data.systemStatus = systemStatus ? systemStatus : 'Normal' ;
				data.datetime = item.datetime ? newdevicedate : '-';
				data.secondVoltage = item.smarttlp.secondVoltage ? item.smarttlp.secondVoltage : 0;

				data.offPSPac = item.offPSPac ? parseFloat(item.offPSPac).toFixed(4) : 0;
				data.offPSPdc = item.offPSPdc ? parseFloat(item.offPSPdc).toFixed(4) : 0;
				data.currentDc = item.currentDc ? parseFloat(item.currentDc).toFixed(4): 0;
				data.currentAc = item.currentAc ? parseFloat(item.currentAc).toFixed(4) : 0;
				data.offCSPac = item.offCSPac ? parseFloat(item.offCSPac).toFixed(4) : 0;
				data.offCSPdc = item.offCSPdc ? parseFloat(item.offCSPdc).toFixed(4) : 0;
				data.pspType = item.pspType;
				if(item.pspType == 'on'){
					data.offPSPac = 'NA';
					data.offPSPdc = 'NA';
					data.currentDc = 'NA';
					data.currentAc = 'NA';
					data.offCSPac = 'NA';
					data.offCSPdc = 'NA';
				}
				else if(item.pspType=='polarized'){
					data.offPSPac = 'NA';
					data.offPSPdc = 'NA';
					data.currentDc = 'NA';
					data.currentAc = 'NA';
					data.offCSPac = 'NA';
					data.offCSPdc = 'NA';
				}

				if(item.smarttlp.secondVoltage === false) {
					data.vdc1 = 'NA';
					data.vac1 = 'NA';
					data.offCSPac ='NA';
					data.offCSPdc = 'NA';
				}

				if(item.smarttlp && item.smarttlp.secondVoltage === false) {
					data.vdc1 = 'NA';
					data.vac1 = 'NA';
				}

				cps.push(data);
			});

			return { ...state, cps: cps, pagination: pagination, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, cps: [], pagination: pagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case CPS_REPORT_LIST_ERROR:
		return { ...state, loading: false, error: true };

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}