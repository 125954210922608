import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
export default function UnauthorizedError () {
	const navigate = useNavigate();
    
	return (
		<Content>
			<Result
				style={{ marginTop : '100px'}}
				status="500"
				title=""
				subTitle="Sorry, something went wrong."
				extra={<Button type="primary" onClick={() => {navigate('/');}}>Back to Login</Button>}
			/>
		</Content>
	);
}
