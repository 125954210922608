import { React, useState, useEffect, useReducer } from 'react';
import { useAuth } from '../../Context';

import { Avatar, Popover, Empty, Row, Col, Select, Typography, Tooltip, Checkbox, Spin, Space, Card, DatePicker, Modal, message } from 'antd';
import { Content } from 'antd/es/layout/layout';

import Chart from 'react-apexcharts';
import useResizeObserver from 'use-resize-observer';

import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';
import { DAILY_CPS_LIST, DAILY_CPS_LIST_SUCCESS, DAILY_CPS_LIST_ERROR }  from './constants';
import dailyCPSReducer from './reducer';

import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';
import { InfoOutlined, LineChartOutlined } from '@ant-design/icons';
import { enddatetimeCoverter, startdatetimeCoverter } from '../../datetime-converter';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;
const dateFormat = 'YYYY-MM-DD';

export default function DailyCPSReport() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const tlp_feature = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES` , null));
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const [regionId, setRegionId] = useState(null);
	const [tlpId, setTLPId] = useState(null);
	const [filterDate, setFilterDate] = useState(new Date());

	const { ref, width } = useResizeObserver();

	const [filterVDC1, setFilterVDC1] = useState(false);
	const [filterVAC1, setFilterVAC1] = useState(false);
	const [filterVDC2, setFilterVDC2] = useState(false);
	const [filterVAC2, setFilterVAC2] = useState(false);
	const [filterBattery, setFilterBattery] = useState(false);
	// const [filterCurrent, setFilterCurrent] = useState(false);
	const [filterTemp, setFilterTemp] = useState(false);
	const [filterVDC1Min, setFilterVDC1Min] = useState(false);
	const [filterVDC1Max, setFilterVDC1Max] = useState(false);
	const [filterVAC1Min, setFilterVAC1Min] = useState(false);
	const [filterVAC1Max, setFilterVAC1Max] = useState(false);
	const [filterVDC2Min, setFilterVDC2Min] = useState(false);
	const [filterVDC2Max, setFilterVDC2Max] = useState(false);
	const [filterVAC2Min, setFilterVAC2Min] = useState(false);
	const [filterVAC2Max, setFilterVAC2Max] = useState(false);
	
	const [filteroffPSPac, setFilteroffPSPac] = useState(false);
	const [filteroffPSPacMin, setFilteroffPSPacMin] = useState(false);
	const [filteroffPSPacMax, setFilteroffPSPacMax] = useState(false);
	const [filteroffPSPdc, setFilteroffPSPdc] = useState(false);
	const [filteroffPSPdcMin, setFilteroffPSPdcMin] = useState(false);
	const [filteroffPSPdcMax, setFilteroffPSPdcMax] = useState(false);
			
	const [filteroffCSPdc, setFilteroffCSPdc] = useState(false);
	const [filteroffCSPdcMin, setFilteroffCSPdcMin] = useState(false);
	const [filteroffCSPdcMax, setFilteroffCSPdcMax] = useState(false);
	const [filteroffCSPac, setFilteroffCSPac] = useState(false);
	const [filteroffCSPacMin, setFilteroffCSPacMin] = useState(false);
	const [filteroffCSPacMax, setFilteroffCSPacMax] = useState(false);
		
	const [filterCurrentAC, setFilterCurrentAC] = useState(false);
	const [filterCurrentDC, setFilterCurrentDC] = useState(false);
		
	const [currentdensity, setCurrentDensity] = useState(false);
		
	const [referenceCellValue, setReferenceCellValue] = useState(tlp_feature==null ? false : tlp_feature && tlp_feature.refElectrodeType && tlp_feature.refElectrodeType==2 ? false : tlp_feature && tlp_feature.refElectrodeType==2 ? false : true);
	
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	const [series, setSeries] = useState([]);
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

	const initalState = { cps: [], loading: false, error: false };
	const [data, dispatch] = useReducer(dailyCPSReducer, initalState);
  
	let regions = [{ value: null, label:  langdata && langdata.ALL ? langdata.ALL : 'ALL' }];
	let tlps = [];

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [allocationId, setAllocationId] = useState(null);

	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));
	const navigate = useNavigate();

	const offPSPEnabled = featuresModule.offPSPEnabled;
	const offCSPEnabled = featuresModule.offCSPEnabled;
	const currentDensityEnabled = featuresModule.currentDensityEnabled;


	let timestamps = [];
	let vac1s = [];
	let vdc2NoRefs = [];
	let vdc2Refs = [];
	let vdc1NoRefs = [];
	let vdc1Refs = [];
	let vac2s = [];
	let vbats = [];
	let temps = [];
	let currents = [];
	let vac1Mins = [];
	let vac1Maxs = [];
	let vdc1Mins = [];
	let vdc1Maxs = [];
	let vac2Mins = [];
	let vac2Maxs = [];
	let vdc2Mins = [];
	let vdc2Maxs = [];
	const hours = [];

	let offPSPacs = [];
	let offPSPacMins = [];
	let offPSPacMaxs = [];
	let offPSPdcs = [];
	let offPSPdcMins = [];
	let offPSPdcMaxs = [];

	let offCSPacs = [];
	let offCSPacMins = [];
	let offCSPacMaxs = [];
	let offCSPdc = [];
	let offCSPdcMins = [];
	let offCSPdcMaxs = [];

	let currentDcs = [];
	let currentAcs = [];
	let currentAcsDensity = [];
	let currentDcsDensity = [];

	const couponFactorAC = tlp_feature && tlp_feature.couponFactorAC;
	const couponFactorDC = tlp_feature && tlp_feature.couponFactorDC;

	const d = new Date();
	const [currentHour, setCurrentHour] = useState(d && d.getHours() ? d.getHours() : null);

	const from_datetimeobj=[];

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);

	if(filterDate && currentHour) {
		filterDate.setHours(currentHour,0,0);
		let startdatetime=startdatetimeCoverter(filterDate);
		from_datetimeobj.push({startdatetime});

	}

	const start_date =from_datetimeobj && from_datetimeobj[0] ? from_datetimeobj[0].startdatetime.utcdate : moment.utc(filterDate.setHours(0,0,0,0)).format('YYYY-MM-DD');
	const start_time =from_datetimeobj && from_datetimeobj[0] ? `${from_datetimeobj[0].startdatetime.utctime}.000000` : `${moment.utc(filterDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`;

	const to_datetimeobj=[];	

	if(filterDate && (currentHour || currentHour == 0)){
		filterDate.setHours(currentHour,59,59);
		// filterDate.setHours(parseFloat(filterEndTime));
		let enddatetime=enddatetimeCoverter(filterDate);		
		to_datetimeobj.push({enddatetime});

	}
	
	const end_time =to_datetimeobj && to_datetimeobj[0] ? `${to_datetimeobj[0].enddatetime.utctime}.000000` : `${moment.utc(filterDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`;
	const end_date =to_datetimeobj && to_datetimeobj[0] ? to_datetimeobj[0].enddatetime.utcdate : moment.utc(filterDate.setHours(23,59,59,999)).format('YYYY-MM-DD');

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.DailyCPSContent1 ? langdata.DailyCPSContent1 : 'This module is used to plot the values received for a specific Smart TLP device within a particular day and time interval (1 hour).'}</p>
			<br/>
		</Card>
	);

	let options = {
		id: 'dailyCchart',
		dataLabels: {
			enabled: false
		},
		title: {
			text: langdata && langdata.DailyCPSReport ? langdata.DailyCPSReport : 'DAILY CPS CHART',
			align: 'left'
		},
		chart: {
			toolbar: {
				show: false
			}
		},
		legend: {
			show: true,
			position: 'top',
			horizontalAlign: 'left',
			onItemClick: {
				toggleDataSeries: false
			},
			onItemHover: {
				highlightDataSeries: false
			}
		},
		xaxis: {
			title: {
				text: langdata && langdata.TIMESTAMP ? langdata.TIMESTAMP :  'TIMESTAMP'
			},
			categories: timestamps,
			labels: {
				show: true,
				showDuplicates: true,
				rotate: -90,
				formatter: function (val) {
					let _date = dayjs(val, 'DD/MM/YYYY hh:mm/ss A').format('hh:mm:ss A');
					
					return _date;
				}
			}
		},
		markers: {
			size: 5,
			shape: 'square'
		},
		stroke: {
			curve: 'smooth',
			width: 2
		}
	};

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	const handleChangeRegion = (e, options) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(permission == 'superadmin' || permission == 'admin') {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
		}
		setTLPId([null]);
	};

	data && data.tlps && data.tlps.map((tlp) => {
		return tlps.push({ value: tlp.deviceId, label: tlp.pipelineChainage.toString() });
	});

	const handleChangeTLP = (e) => {
		setTLPId(e);
	};

	const handleDate  = (dateString) => {
		setFilterDate(new Date(dateString && dateString.$d));
		setDate(dayjs(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD'));
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	useEffect(() => {
		if(featuresModule.dailyCPSEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 24);
	}, []);

	useEffect(() => {
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
		}
		loadTLPs();
	}, [regionId, allocationId]);

	useEffect(() => {
		loadCPs();
		resetAllFilters();
	}, [tlpId, date, regionId, allocationId, start_date, end_date, start_time,end_time,filterDate]);

	const resetAllFilters = () => {
		setSeries([]);
		setFilterVDC1(false);
		setFilterVAC1(false);
		setFilterVDC2(false);
		setFilterVAC1(false);
		setFilterVAC2(false);
		setFilterBattery(false);
		// setFilterCurrent(false);
		setFilterTemp(false);
		setFilterVDC1Min(false);
		setFilterVDC1Max(false);
		setFilterVAC1Min(false);
		setFilterVAC1Max(false);
		setFilterVDC2Min(false);
		setFilterVDC2Max(false);
		setFilterVAC2Min(false);
		setFilterVAC2Max(false);

		setFilteroffPSPac(false);
		setFilteroffPSPacMin(false);
		setFilteroffPSPacMax(false);
		setFilteroffPSPdc(false);
		setFilteroffPSPdcMin(false);
		setFilteroffPSPdcMax(false);
		setFilteroffCSPdc(false);
		setFilteroffCSPdcMin(false);
		setFilteroffCSPdcMax(false);
		setFilteroffCSPac(false);
		setFilteroffCSPacMin(false);
		setFilteroffCSPacMax(false);
		setFilterCurrentAC(false);
		setFilterCurrentDC(false);	
	};

	const loadTLPs = () => {
		dispatch({ type: TLPS_LIST });

		const limit = 10000;
		const skip = 0;
		let url = `${api}/smarttlp-list?$limit=${limit}&$skip=${skip}&$sort[pipelineChainage]=-1`;

		let filterByregionIdPart = '';
		if(admin_roles.includes(permission)){
			filterByregionIdPart = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			filterByregionIdPart = allocationId ? `&allocationId=${allocationId}` : '';
		}

		if ((regionId && regionId !== null) || (allocationId && allocationId !== null)) {
			url = `${api}/smarttlp-list?$limit=${limit}&$skip=${skip}&$sort[pipelineChainage]=-1${filterByregionIdPart}`;
		}

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config) .then((response) => {
			if (response && response.data) {
				setTLPId(response.data && response.data.data && response.data.data[0] && response.data.data[0].deviceId);
				dispatch( { type: TLPS_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: TLPS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: TLPS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadCPs = () => {
		if (!tlpId && date !== moment().format('YYYY-MM-DD')) {
			Modal.warning({ title: 'Please select a chainage' });
		}
		dispatch({ type: DAILY_CPS_LIST });

		let filterByregionIdPart = '';
		if(admin_roles.includes(permission)){
			filterByregionIdPart = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			filterByregionIdPart = allocationId ? `&allocationId=${allocationId}` : '';
		}
    
		if (tlpId && tlpId !== null) {
			let url = `${api}/tlplogsbulkcpsdatefiltered?$limit=100000&tlpId=${tlpId}&startDate=${start_date}&startTime=${start_time}&endTime=${end_time}&endDate=${end_date}${filterByregionIdPart}`;
			const config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: url,
				timeout: timeout,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${authtoken}`
				}
			};
  
			axios.request(config) .then((response) => {
				if (response && response.data) {
					dispatch( { type: DAILY_CPS_LIST_SUCCESS, payload: response.data });
				} else {
					dispatch( { type: DAILY_CPS_LIST_ERROR });
				}
			}).catch((error) => {
				console.log(error);
				dispatch( { type: DAILY_CPS_LIST_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
		} else {
			dispatch( { type: DAILY_CPS_LIST_ERROR });
		}
	};

	const onChangeVDC2 = () => {
		let newVal = !filterVDC2;
		let fields = series;

		setFilterVDC2(newVal);
		onChangeVDC2Min(newVal);
		onChangeVDC2Max(newVal);

		if (newVal === true) {
			fields.push({name: 'PSP (V)', data: referenceCellValue === true ? vdc2Refs : vdc2NoRefs});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'PSP (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);
	};

	const onChangeVAC2 = () => {
		let newVal = !filterVAC2;
		let fields = series;

		setFilterVAC2(newVal);
		onChangeVAC2Min(newVal);
		onChangeVAC2Max(newVal);

		if (newVal === true) {
			fields.push({name: 'AC PICKUP (V)', data: vac2s});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'AC PICKUP (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);
	};

	const onChangeVDC1 = () => {
		let newVal = !filterVDC1;
		let fields = series;

		setFilterVDC1(newVal);
		onChangeVDC1Min(newVal);
		onChangeVDC1Max(newVal);

		if (newVal === true) {
			fields.push({name: 'CSP (V)', data: referenceCellValue === true ? vdc1Refs : vdc1NoRefs});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'CSP (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);  
	};

	const onChangeVAC1 = () => {      
		let newVal = !filterVAC1;
		let fields = series;

		setFilterVAC1(newVal);
		onChangeVAC1Min(newVal);
		onChangeVAC1Max(newVal);


		if (newVal === true) {
			fields.push({name: 'CASING AC PICKUP (V)', data: vac1s});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'CASING AC PICKUP (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields); 
	};

	const onChangeBattery = () => {
		let newVal = !filterBattery;
		let fields = series;

		setFilterBattery(newVal);

		if (newVal === true) {
			fields.push({name: 'BATTERY (V)', data: vbats});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'BATTERY (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);
	};

	// const onChangeCurrent = () => {  
	// 	let newVal = !filterCurrent;
	// 	let fields = series;

	// 	setFilterCurrent(newVal);

	// 	if (newVal === true) {
	// 		fields.push({name: 'CURRENT', data: currents});
	// 	} else {
	// 		let index = fields.findIndex(function(item) { return item.name === 'CURRENT';});

	// 		if ( index >= 0) {
	// 			fields.splice(index, 1);
	// 		}
	// 	}

	// 	setSeries(fields);  
	// };

	const onChangeTemp = () => {
		let newVal = !filterTemp;
		let fields = series;

		setFilterTemp(newVal);

		if (newVal === true) {
			fields.push({name: 'TEMPERATURE', data: temps});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'TEMPERATURE';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);
	};

	const onChangeVAC1Min = (e) => { 
		let newVal = !filterVAC1Min;
		let fields = series;

		if(e !=='CASING AC PICKUP MIN'){
			setFilterVAC1Min(e);

		}else{
			setFilterVAC1Min(newVal);

		}


		if (newVal === true && e === 'CASING AC PICKUP MIN') {
			fields.push({name: 'CASING AC PICKUP MIN (V)', data: vac1Mins});
		} else if (newVal === false && e === 'CASING AC PICKUP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'CASING AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === 'CASING AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if (e ===true && newVal ===true){
			fields.push({name: 'CASING AC PICKUP MIN (V)', data: vac1Mins});

		}

		setSeries(fields);
	};

	const onChangeVAC1Max = (e) => { 
		let newVal = !filterVAC1Max;
		let fields = series;

		if(e !=='CASING AC PICKUP MAX'){
			setFilterVAC1Max(e);

		}else{
			setFilterVAC1Max(newVal);

		}


		if (newVal === true && e==='CASING AC PICKUP MAX') {
			fields.push({name: 'CASING AC PICKUP MAX (V)', data: vac1Maxs});
		} else if(newVal === false && e==='CASING AC PICKUP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'CASING AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === 'CASING AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if (e ===true && newVal ===true){
			fields.push({name: 'CASING AC PICKUP MAX (V)', data: vac1Maxs});

		}

		setSeries(fields);
	};

	const onChangeVDC1Min = (e) => {
		let newVal = !filterVDC1Min;
		let fields = series;

		if(e !=='CSP MIN'){
			setFilterVDC1Min(e);

		}else{
			setFilterVDC1Min(newVal);

		}

		if (newVal === true && e ==='CSP MIN') {
			fields.push({name: 'CSP MIN (V)', data: vdc1Mins});
		} else if(newVal === false && e ==='CSP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'CSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === 'CSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}

		}else if (e ===true && newVal ===true){
			fields.push({name: 'CSP MIN (V)', data: vdc1Mins});

		}

		setSeries(fields);
	};

	const onChangeVDC1Max = (e) => { 
		let newVal = !filterVDC1Max;
		let fields = series;

		if(e !=='CSP MAX'){
			setFilterVDC1Max(e);

		}else{
			setFilterVDC1Max(newVal);

		}


		if (newVal === true && e ==='CSP MAX') {
			fields.push({name: 'CSP MAX (V)', data: vdc1Maxs});
		} else if(newVal === false && e ==='CSP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'CSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === 'CSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if (e ===true && newVal ===true){
			fields.push({name: 'CSP MAX (V)', data: vdc1Maxs});

		}

		setSeries(fields);
	};

	const onChangeVAC2Min = (e) => { 
		let newVal = !filterVAC2Min;
		let fields = series;

		if(e !=='AC PICKUP MIN'){

			setFilterVAC2Min(e);
		}else{
			setFilterVAC2Min(newVal);

		}


		if (newVal === true && e ==='AC PICKUP MIN') {
			fields.push({name: 'AC PICKUP MIN (V)', data: vac2Mins});
		} else if (newVal === false && e ==='AC PICKUP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === 'AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}else if(e ===true && newVal ===true){
			fields.push({name: 'AC PICKUP MIN (V)', data: vac2Mins});

		}

		setSeries(fields);
	};

	const onChangeVAC2Max = (e) => { 
		let newVal = !filterVAC2Max;
		let fields = series;

		if(e !== 'AC PICKUP MAX'){
			setFilterVAC2Max(e);

		}else{
			setFilterVAC2Max(newVal);

		}


		if (newVal === true && e ==='AC PICKUP MAX') {
			fields.push({name: 'AC PICKUP MAX (V)', data: vac2Maxs});
		} else if(newVal === false && e ==='AC PICKUP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === 'AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e ===true && newVal ===true){
			fields.push({name: 'AC PICKUP MAX (V)', data: vac2Maxs});

		}

		setSeries(fields);
	};

	const onChangeVDC2Min = (e) => { 
		let newVal = !filterVDC2Min;
		let fields = series;
		
		if(e !=='PSP MIN'){
			setFilterVDC2Min(e);

		}else{
			setFilterVDC2Min(newVal);

		}


		if (newVal === true && e === 'PSP MIN') {
			fields.push({name: 'PSP MIN (V)', data: vdc2Mins});
		} else if(newVal === false && e === 'PSP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'PSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === 'PSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if (e ===true && newVal ===true){
			fields.push({name: 'PSP MIN (V)', data: vdc2Mins});

		}
		setSeries(fields);
	};

	const onChangeVDC2Max = (e) => { 
		let newVal = !filterVDC2Max;
		let fields = series;

		if(e !=='PSP MAX'){
			setFilterVDC2Max(e);

		}else{
			setFilterVDC2Max(newVal);

		}


		if (newVal === true && e === 'PSP MAX') {
			fields.push({name: 'PSP MAX (V)', data: vdc2Maxs});
		} else if (newVal === false && e === 'PSP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'PSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === 'PSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}else if (e ===true && newVal ===true){
			fields.push({name: 'PSP MAX (V)', data: vdc2Maxs});

		}

		setSeries(fields);
	};

	const handleReferenceCellValue = () => {
		let newVal  = referenceCellValue === true ? false : true;
		let fields = series;
		// let cspIndex = fields.findIndex(function(item) { return item.name === 'CSP (V)';});
		// let pspIndex = fields.findIndex(function(item) { return item.name === 'PSP (V)';});

		let cspIndex;
		fields.map((item, index) => {
			if(item.name === 'CSP (V)') {
				cspIndex = index;
			}
		});

		let pspIndex;
		fields.map((item, index) => {
			if(item.name === 'PSP (V)') {
				pspIndex = index;
			}
		});

		setReferenceCellValue(newVal);
    
		if (newVal === true) {
			if (cspIndex >= 0) {
				// fields.splice(cspIndex, 1);
				// fields.push({name: 'CSP (V)', data: vdc1Refs});
				fields[cspIndex] = {name: 'CSP (V)', data: vdc1Refs};
			}

			if (pspIndex >= 0) {
				// fields.splice(pspIndex, 1);
				// fields.push({name: 'PSP (V)', data: vdc2Refs});
				fields[pspIndex] = {name: 'PSP (V)', data: vdc2Refs};
			} 
		} else {
			if (cspIndex >= 0) {
				// fields.splice(cspIndex, 1);
				// fields.push({name: 'CSP (V)', data: vdc1NoRefs});
				fields[cspIndex] = {name: 'CSP (V)', data: vdc1NoRefs};

			}

			if (pspIndex >= 0) {
				// fields.splice(pspIndex, 1);
				// fields.push({name: 'PSP (V)', data: vdc2NoRefs});
				fields[pspIndex] = {name: 'PSP (V)', data: vdc2NoRefs};
			}
		}

		setSeries(fields);
	};

	if (data && data.cps) {
		data.cps.map((item) => {
			timestamps.push(moment(item.datetime).format('DD/MM/YYYY hh:mm:ss A'));

			vdc1NoRefs.push(item.vdc1);
			// vdc1Refs.push((referenceCellValue ==false ? parseFloat(item.vdc1) : parseFloat(item.vdc1) + -1.1).toFixed(4));
			vdc1Refs.push((parseFloat(item.vdc1) + -1.1).toFixed(4));

			vdc2NoRefs.push(item.vdc2);
			// vdc2Refs.push((referenceCellValue == false ? parseFloat(item.vdc2) : parseFloat(item.vdc2) + -1.1).toFixed(4));
			vdc2Refs.push((parseFloat(item.vdc2) + -1.1).toFixed(4));

			vac1s.push(item.vac1);
			vac2s.push(item.vac2);
			vbats.push(item.vbat);
			temps.push(item.temp);
			currents.push(item.current);

			// Limit filters
			vac1Mins.push(item.vac1Min);
			vac1Maxs.push(item.vac1Max);
			vdc1Mins.push(item.vdc1Min);
			vdc1Maxs.push(item.vdc1Max);
			vac2Mins.push(item.vac2Min);
			vac2Maxs.push(item.vac2Max);
			vdc2Mins.push(item.vdc2Min);
			vdc2Maxs.push(item.vdc2Max);

			offCSPacs.push(item.offCSPac);
			offCSPacMins.push(item.offCSPacMin);
			offCSPacMaxs.push(item.offCSPacMax);
			offCSPdc.push(item.offCSPdc);
			offCSPdcMins.push(item.offCSPdcMin);
			offCSPdcMaxs.push(item.offCSPdcMax);

			offPSPacs.push(item.offPSPac);
			offPSPacMins.push(item.offPSPacMin);
			offPSPacMaxs.push(item.offPSPacMax);
			offPSPdcs.push(item.offPSPdc);
			offPSPdcMins.push(item.offPSPdcMin);
			offPSPdcMaxs.push(item.offPSPdcMax);

			currentDcs.push(item.currentDc);
			currentAcs.push(item.currentAc);
			currentDcsDensity.push(parseFloat(item.currentDc ? item.currentDc : 0) * couponFactorDC.toFixed(2));
			currentAcsDensity.push(parseFloat(item.currentAc ? item.currentAc : 0) * couponFactorAC.toFixed(2));

		});
	}

	// const handleSetAnomaly = (e) =>{
		
	// 	setAnomalyValue(e);
	// 	if(e==false){
	// 		setTLPParams('&anomaly=false');

	// 	}else{
	// 		setTLPParams('&anomaly=true');
	// 	}

	// };

	let minChartWidth = parseInt(data.cps.length/20).toFixed(2);

	minChartWidth = (minChartWidth < 1) ? 1 : minChartWidth;

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	for (let h = 0; h < 24; h++) {
		hours.push({
			value : h,
			label : `${h}:00 - ${h}:59`
		});
	}

	const handleSetHour = (value) => {
		setCurrentHour(value);
	};

	const onChangeoffPSPdc = () => {
		let newVal = !filteroffPSPdc;
		let fields = series;
    
		setFilteroffPSPdc(newVal);
		onChangeoffPSPdcMin(newVal);
		onChangeoffPSPdcMax(newVal);		

		if (newVal === true) {
			fields.push({name: 'OFF PSP (V)', data: offPSPdcs});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'OFF PSP (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
    
		setSeries(fields);
	};

	const onChangeoffPSPdcMin = (e) => {
		let newVal = !filteroffPSPdcMin;
		let fields = series;

		if(e !== 'OFF PSP MIN'){
			setFilteroffPSPdcMin(e);
		}
		else{
			setFilteroffPSPdcMin(newVal);
		}

		if (newVal === true && e == 'OFF PSP MIN') {
			fields.push({name: 'OFF PSP MIN (V)', data: offPSPdcMins});
		} else if (newVal === false && e == 'OFF PSP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'OFF PSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e=== false && newVal=== false){
			let index = fields.findIndex(function(item) { return item.name === 'OFF PSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e=== true && newVal=== true){
			fields.push({name: 'OFF PSP MIN (V)', data: offPSPdcMins});

		}		
		
		setSeries(fields);
	};

	const onChangeoffPSPdcMax = (e) => {
		let newVal = !filteroffPSPdcMax;
		let fields = series;

		if(e !== 'OFF PSP MAX'){
			setFilteroffPSPdcMax(e);
		}
		else{
			setFilteroffPSPdcMax(newVal);
		}

		if (newVal === true && e == 'OFF PSP MAX') {
			fields.push({name: 'OFF PSP MAX (V)', data: offPSPdcMaxs});
		} else if (newVal === false && e == 'OFF PSP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'OFF PSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e=== false && newVal=== false){
			let index = fields.findIndex(function(item) { return item.name === 'OFF PSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e=== true && newVal=== true){
			fields.push({name: 'OFF PSP MAX (V)', data: offPSPdcMaxs});

		}		
		
		setSeries(fields);
	};

	const onChangeoffPSPac = () => {
		let newVal = !filteroffPSPac;
		let fields = series;
    
		setFilteroffPSPac(newVal);
		onChangeoffPSPacMin(newVal);
		onChangeoffPSPacMax(newVal);		

		if (newVal === true) {
			fields.push({name: 'OFF PSP AC PICKUP (V)', data: offPSPdcs});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'OFF PSP AC PICKUP (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
    
		setSeries(fields);
	};

	const onChangeoffPSPacMin = (e) => {
		let newVal = !filteroffPSPacMin;
		let fields = series;

		if(e !== 'OFF PSP AC PICKUP MIN'){
			setFilteroffPSPacMin(e);
		}
		else{
			setFilteroffPSPacMin(newVal);
		}

		if (newVal === true && e == 'OFF PSP AC PICKUP MIN') {
			fields.push({name: 'OFF PSP AC PICKUP MIN (V)', data: offPSPacMins});
		} else if (newVal === false && e == 'OFF PSP AC PICKUP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'OFF PSP AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e=== false && newVal=== false){
			let index = fields.findIndex(function(item) { return item.name === 'OFF PSP AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e=== true && newVal=== true){
			fields.push({name: 'OFF PSP AC PICKUP MIN (V)', data: offPSPacMins});

		}		
		
		setSeries(fields);
	};

	const onChangeoffPSPacMax = (e) => {
		let newVal = !filteroffPSPacMax;
		let fields = series;

		if(e !== 'OFF PSP AC PICKUP MAX'){
			setFilteroffPSPacMax(e);
		}
		else{
			setFilteroffPSPacMax(newVal);
		}

		if (newVal === true && e == 'OFF PSP AC PICKUP MAX') {
			fields.push({name: 'OFF PSP AC PICKUP MAX (V)', data: offPSPacMaxs});
		} else if (newVal === false && e == 'OFF PSP AC PICKUP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'OFF PSP AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e=== false && newVal=== false){
			let index = fields.findIndex(function(item) { return item.name === 'OFF PSP AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e=== true && newVal=== true){
			fields.push({name: 'OFF PSP AC PICKUP MAX (V)', data: offPSPacMaxs});

		}		
		
		setSeries(fields);
	};

	const onChangeoffCSPdc = () => {
		let newVal = !filteroffCSPdc;
		let fields = series;
    
		setFilteroffCSPdc(newVal);
		onChangeoffCSPdcMin(newVal);
		onChangeoffCSPdcMax(newVal);		

		if (newVal === true) {
			fields.push({name: 'OFF CSP (V)', data: offCSPdc});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'OFF CSP (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
    
		setSeries(fields);
	};

	const onChangeoffCSPdcMin = (e) =>{
		let newVal = !filteroffCSPdcMin;
		let fields = series;

		if(e !== 'OFF CSP MIN'){
			setFilteroffCSPdcMin(e);
		}
		else{
			setFilteroffCSPdcMin(newVal);
		}

		if (newVal === true && e == 'OFF CSP MIN') {
			fields.push({name: 'OFF CSP MIN (V)', data: offCSPdcMins});
		} else if (newVal === false && e == 'OFF CSP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'OFF CSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e=== false && newVal=== false){
			let index = fields.findIndex(function(item) { return item.name === 'OFF CSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e=== true && newVal=== true){
			fields.push({name: 'OFF CSP MIN (V)', data: offCSPdcMins});

		}		
		
		setSeries(fields);
	};

	const onChangeoffCSPdcMax = (e) => {
		let newVal = !filteroffCSPdcMax;
		let fields = series;

		if(e !== 'OFF CSP MAX'){
			setFilteroffCSPdcMax(e);
		}
		else{
			setFilteroffCSPdcMax(newVal);
		}

		if (newVal === true && e == 'OFF CSP MAX') {
			fields.push({name: 'OFF CSP MAX (V)', data: offCSPdcMaxs});
		} else if (newVal === false && e == 'OFF CSP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'OFF CSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e=== false && newVal=== false){
			let index = fields.findIndex(function(item) { return item.name === 'OFF CSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e=== true && newVal=== true){
			fields.push({name: 'OFF CSP MAX (V)', data: offCSPdcMaxs});

		}		
		
		setSeries(fields);
	};

	const onChangoffCSPac = () => {
		let newVal = !filteroffCSPac;
		let fields = series;
    
		setFilteroffCSPac(newVal);
		onChangeoffCSPacMin(newVal);
		onChangeoffCSPacMax(newVal);		

		if (newVal === true) {
			fields.push({name: 'OFF CSP AC PICKUP (V)', data: offCSPacs});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'OFF CSP AC PICKUP (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
    
		setSeries(fields);
	};

	const onChangeoffCSPacMin = (e) => {
		let newVal = !filteroffCSPacMin;
		let fields = series;

		if(e !== 'OFF CSP MIN'){
			setFilteroffCSPacMin(e);
		}
		else{
			setFilteroffCSPacMin(newVal);
		}

		if (newVal === true && e == 'OFF CSP AC PICKUP MIN') {
			fields.push({name: 'OFF CSP AC PICKUP MIN (V)', data: offCSPacMins});
		} else if (newVal === false && e == 'OFF CSP AC PICKUP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'OFF CSP AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e=== false && newVal=== false){
			let index = fields.findIndex(function(item) { return item.name === 'OFF CSP AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e=== true && newVal=== true){
			fields.push({name: 'OFF CSP AC PICKUP MIN (V)', data: offCSPacMins});

		}		
		
		setSeries(fields);
	};

	const onChangeoffCSPacMax = (e) => {
		let newVal = !filteroffCSPacMax;
		let fields = series;

		if(e !== 'OFF CSP AC PICKUP MAX'){
			setFilteroffCSPacMax(e);
		}
		else{
			setFilteroffCSPacMax(newVal);
		}

		if (newVal === true && e == 'OFF CSP AC PICKUP MAX') {
			fields.push({name: 'OFF CSP AC PICKUP MAX (V)', data: offCSPacMaxs});
		} else if (newVal === false && e == 'OFF CSP AC PICKUP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'OFF CSP AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e=== false && newVal=== false){
			let index = fields.findIndex(function(item) { return item.name === 'OFF CSP AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e=== true && newVal=== true){
			fields.push({name: 'OFF CSP AC PICKUP MAX (V)', data: offCSPacMaxs});

		}		
		
		setSeries(fields);
	};

	const onChangecurrentAc = () => {
		let newVal = !filterCurrentAC;
		let fields = series;

		setFilterCurrentAC(newVal);

		if (newVal === true) {
			fields.push({name: 'CURRENT AC', data: currentdensity ? currentAcsDensity  : currentAcs});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'CURRENT AC';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);  
	};
	
	const onChangeCurrentDc = () => {
		let newVal = !filterCurrentDC;
		let fields = series;

		setFilterCurrentDC(newVal);

		if (newVal === true) {
			fields.push({name: 'CURRENT DC', data: currentdensity ? currentDcsDensity : currentDcs});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'CURRENT DC';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}		

		setSeries(fields); 
	};

	const handleCurrentDensity = () => {
		let newVal  = currentdensity === true ? false : true;
		let fields = series;
		
		let currentAcIndex;
		fields.map((item, index) => {
			if(item.name === 'CURRENT AC') {
				currentAcIndex = index;
			}
		});

		let currentDcIndex;
		fields.map((item, index) => {
			if(item.name === 'CURRENT DC') {
				currentDcIndex = index;
			}
		});

		setCurrentDensity(newVal);
    
		if (newVal === true) {
			if (currentAcIndex >= 0) {
				fields[currentAcIndex] = {name: 'CURRENT AC', data: currentAcsDensity};
			}

			if (currentDcIndex >= 0) {
				fields[currentDcIndex] = {name: 'CURRENT DC', data: currentDcsDensity};
			} 
		} else {
			if (currentAcIndex >= 0) {
				fields[currentAcIndex] = {name: 'CURRENT AC', data: currentAcs};

			}

			if (currentDcIndex >= 0) {
				fields[currentDcIndex] = {name: 'CURRENT DC', data: currentDcs};
			}
		}		

		setSeries(fields);
	};
	
	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={6}>
						<Row>
							<Space>
								<LineChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.DailyCPSReport ? langdata.DailyCPSReport :  'Daily CPS Report'}</Title>
						</Row>
					</Col>
					
					<Col span={18}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion :  'Filter by region'}>
									<Space>
										<Select
											style={{ width: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
											optionFilterProp="children"
											defaultValue={null}
											options={regions}
											onSelect={(e, options)=>handleChangeRegion(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbychainage ? langdata.Filterbychainage : 'Filter by chainage'}>
									<Space>
										<Select
											style={{ width: '120px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectChainage ? langdata.SelectChainage : 'Select Chainage'}
											optionFilterProp="children"
											value={tlpId}
											options={tlps}
											onSelect={handleChangeTLP}
											defaultValue={tlps && tlps[0]}
											disabled={tlps.length > 0 ? false : true}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbydate ? langdata.Filterbydate : 'Filter by date'}>
									<Space>
										<DatePicker style={{margin:'5px'}} allowClear={false} defaultValue={dayjs(moment().format('YYYY-MM-DD'), dateFormat)} onChange={handleDate} disabledDate={disabledDate}  format={settingsdateformat}/>
									</Space>
								</Tooltip>
								<Space>&nbsp;</Space>
								<Space>
									<Tooltip placement="bottom" title={langdata && langdata.Filterbyhour ? langdata.Filterbyhour : 'Filter by hour'}>
										<Select
											style={{ width: '120px', margin:'5px', marginLeft : '10px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectHour ? langdata.SelectHour : 'Select Hour'}
											optionFilterProp="children"
											value={currentHour}
											options={hours}
											onSelect={handleSetHour}
										/>
									</Tooltip>
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								<Popover title= {langdata && langdata.DailyCPSReport ? langdata.DailyCPSReport : 'Daily CPS Report'} content={infoContent} trigger="click">
									<Avatar size="small"><InfoOutlined /></Avatar>
								</Popover>
								<Space>&nbsp;&nbsp;</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24} style={{ marginTop: '10px' }} >
						<Row style={{
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col>
								<Checkbox onChange={onChangeVDC2} checked={filterVDC2}>{langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC2Min('PSP MIN');}} checked={filterVDC2Min}>{langdata && langdata.PSPMINV ? langdata.PSPMINV : 'PSP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC2Max('PSP MAX');}} checked={filterVDC2Max}>{langdata && langdata.PSPMAXV ? langdata.PSPMAXV : 'PSP MAX (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={onChangeVAC2} checked={filterVAC2}>{langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVAC2Min('AC PICKUP MIN');}} checked={filterVAC2Min}>{langdata && langdata.ACPICKUPMINV ? langdata.ACPICKUPMINV : 'AC PICKUP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVAC2Max('AC PICKUP MAX');}} checked={filterVAC2Max}>{langdata && langdata.ACPICKUPMAXV ? langdata.ACPICKUPMAXV : 'AC PICKUP MAX (V)'}</Checkbox>
							</Col>
						</Row>
						<br/>
						{offPSPEnabled == true ?
							<>
								<Row style={{
									background: '#FFFFFF',
									alignItems:'center'
								}} >
									<Col>
										<Checkbox onChange={onChangeoffPSPdc} checked={filteroffPSPdc}>{langdata && langdata.OFFPSPV ? langdata.OFFPSPV : 'OFF PSP (V)'}</Checkbox>
									</Col>
									<Col>
										<Checkbox onChange={()=>{onChangeoffPSPdcMin('OFF PSP MIN');}} checked={filteroffPSPdcMin}>{langdata && langdata.OFFPSPMINV ? langdata.OFFPSPMINV : 'OFF PSP MIN (V)'}</Checkbox>
									</Col>
									<Col>
										<Checkbox onChange={()=>{onChangeoffPSPdcMax('OFF PSP MAX');}} checked={filteroffPSPdcMax}>{langdata && langdata.OFFPSPMAXV ? langdata.OFFPSPMAXV : 'OFF PSP MAX (V)'}</Checkbox>
									</Col>
									<Col>
										<Checkbox onChange={onChangeoffPSPac} checked={filteroffPSPac}>{langdata && langdata.OFFPSPACPICKUPV ? langdata.OFFPSPACPICKUPV : 'OFF PSP AC PICKUP (V)'}</Checkbox>  
									</Col>
																			
									<Col>
										<Checkbox onChange={()=>{onChangeoffPSPacMin('OFF PSP AC PICKUP MIN');}} checked={filteroffPSPacMin}>{langdata && langdata.OFFPSPACPICKUPMINV ? langdata.OFFPSPACPICKUPMINV : 'OFF PSP AC PICKUP MIN (V)'}</Checkbox>
									</Col>
									<Col>
										<Checkbox onChange={()=>{onChangeoffPSPacMax('OFF PSP AC PICKUP MAX');}} checked={filteroffPSPacMax}>{langdata && langdata.OFFPSPACPICKUPMAXV ? langdata.OFFPSPACPICKUPMAXV : 'OFF PSP AC PICKUP MAX (V)'}</Checkbox>
									</Col>
																			
																			
								</Row>
								<br/>
							</>
							: null }
						<Row style={{
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col>
								<Checkbox onChange={onChangeVDC1} checked={filterVDC1}>{langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC1Min('CSP MIN');}} checked={filterVDC1Min}>{langdata && langdata.CSPMINV ? langdata.CSPMINV : 'CSP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC1Max('CSP MAX');}} checked={filterVDC1Max}>{langdata && langdata.CSPMAXV ? langdata.CSPMAXV : 'CSP MAX (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={onChangeVAC1} checked={filterVAC1}>{langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVAC1Min('CASING AC PICKUP MIN');}} checked={filterVAC1Min}>{langdata && langdata.CASINGACPICKUPMINV ? langdata.CASINGACPICKUPMINV : 'CASING AC PICKUP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVAC1Max('CASING AC PICKUP MAX');}} checked={filterVAC1Max}>{langdata && langdata.CASINGACPICKUPMAXV ? langdata.CASINGACPICKUPMAXV : 'CASING AC PICKUP MAX (V)'}</Checkbox>
							</Col>
						</Row>
						<br/>
						{offCSPEnabled == true ?
							<>
								<Row style={{
									background: '#FFFFFF',
									alignItems:'center'
								}} >
																			
									<Col>
										<Checkbox onChange={onChangeoffCSPdc} checked={filteroffCSPdc}>{langdata && langdata.OFFCSP ? langdata.OFFCSP : 'OFF CSP (V)'}</Checkbox>
									</Col>
									<Col>
										<Checkbox onChange={()=>{onChangeoffCSPdcMin('OFF CSP MIN');}} checked={filteroffCSPdcMin}>{langdata && langdata.OFFCSPMINV ? langdata.OFFCSPMINV : 'OFF CSP MIN (V)'}</Checkbox>
									</Col>
									<Col>
										<Checkbox onChange={()=>{onChangeoffCSPdcMax('OFF CSP MAX');}} checked={filteroffCSPdcMax}>{langdata && langdata.OFFCSPMAXV ? langdata.OFFCSPMAXV : 'OFF CSP MAX (V)'}</Checkbox>
									</Col>
									<Col>
										<Checkbox onChange={onChangoffCSPac} checked={filteroffCSPac}>{langdata && langdata.OFFCSPACPICKUP ? langdata.OFFCSPACPICKUP : 'OFF CSP AC PICKUP (V)'}</Checkbox>
									</Col>
																			
									<Col>
										<Checkbox onChange={()=>{onChangeoffCSPacMin('OFF CSP AC PICKUP MIN');}} checked={filteroffCSPacMin}>{langdata && langdata.OFFCSPACPICKUPMINV ? langdata.OFFCSPACPICKUPMINV : 'OFF CSP AC PICKUP MIN (V)'}</Checkbox>
									</Col>
									<Col>
										<Checkbox onChange={()=>{onChangeoffCSPacMax('OFF CSP AC PICKUP MAX');}} checked={filteroffCSPacMax}>{langdata && langdata.OFFCSPACPICKUPMAXV ? langdata.OFFCSPACPICKUPMAXV : 'OFF CSP AC PICKUP MAX (V)'}</Checkbox>
									</Col>
								</Row>
								<br/>
							</>
							: null }
						<Row style={{
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col>
								<Checkbox onChange={onChangeBattery} checked={filterBattery}>{langdata && langdata.BATTERY ? langdata.BATTERY : 'BATTERY'}</Checkbox>
							</Col>
							{tlp_feature.current ? 
								<Col>
									{/* <Checkbox onChange={onChangeCurrent} checked={filterCurrent}>{langdata && langdata.CURRENT ? langdata.CURRENT : 'CURRENT'}</Checkbox> */}
									<Checkbox onChange={onChangecurrentAc} checked={filterCurrentAC}>{langdata && langdata.CURRENTAC ? langdata.CURRENTAC : 'CURRENT AC'}</Checkbox>
									
								</Col> : null }
							{offPSPEnabled == true ?
								<Col>
									<Checkbox onChange={onChangeCurrentDc} checked={filterCurrentDC}>{langdata && langdata.CURRENTDC ? langdata.CURRENTDC : 'CURRENT DC'}</Checkbox>						
								</Col>
								: null }
							<Col>
								<Checkbox onChange={onChangeTemp} checked={filterTemp}>{langdata && langdata.TEMPERATURE ? langdata.TEMPERATURE : 'TEMPERATURE'}</Checkbox>
							</Col>
						</Row>
						<Row style={{ marginTop: '10px', marginBottom: '10px' }}>
							{tlp_feature && tlp_feature.refElectrodeType!==2 ?
								<Col span={8}>
									<Text type="secondary">
										<Checkbox checked={referenceCellValue} onChange={handleReferenceCellValue}> * {langdata && langdata.ShowregularisedvaluewrtCuCuSO4referencecell ? langdata.ShowregularisedvaluewrtCuCuSO4referencecell : 'Show regularised value w.r.t Cu-CuSO4 reference cell.'}</Checkbox>
									</Text>
								</Col> : null }
							{currentDensityEnabled ?
								<Col span={8}>
									<Text type="secondary">
										<Checkbox checked={currentdensity} onChange={handleCurrentDensity}>{langdata && langdata.Showcurrentdensityvalue ? langdata.Showcurrentdensityvalue : 'Show current density value'}</Checkbox>
									</Text>
								</Col>:null}
						</Row>
						<Row style={{ marginTop: '10px', marginBottom: '10px' }}>
							<Col span={24}>
								<Text type="secondary">{langdata && langdata.PSPVFieldobservedvalue ? langdata.PSPVFieldobservedvalue : 'PSP (V) = (Field observed value) + (-1.1)'}</Text>
								<br />
								<Text type="secondary">{langdata && langdata.CSPVFieldobservedvalue ? langdata.CSPVFieldobservedvalue : 'CSP (V) = (Field observed value) + (-1.1)'}</Text>
							</Col>
						</Row>
						<div  ref={ref}>
							<Card className="card-Shadow-dashboard" style={{overflow: 'scroll'}}>
								{ data.cps && data.cps.length > 0 && tlps.length >= 1 ? (
									<Chart height={500} width={(width - 40) * minChartWidth} options={ options } series={ series } type="line" />
								) : (
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								)}
							</Card>
						</div>
					</Col>
				</Row>
			</Content>
			<Spin fullscreen spinning={data.loading} />
		</>
	);
}