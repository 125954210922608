/* eslint-disable no-restricted-globals */
import multiplewhite from '../images/activityicons/multiwhite.png';
import multiplegreen from '../images/activityicons/multigreen.png';
import multipleamber from '../images/activityicons/multiamber.png';
import multiplered from '../images/activityicons/multired.png';

import machinewhite from '../images/activityicons/machinewhite.png';
import machinegreen from '../images/activityicons/machinegreen.png';
import machineamber from '../images/activityicons/machineamber.png';
import machinered from '../images/activityicons/machinered.png';

import manuelwhite from '../images/activityicons/manuelwhite.png';
import manuelgreen from '../images/activityicons/manuelgreen.png';
import manuelamber from '../images/activityicons/manuelamber.png';
import manuelred from '../images/activityicons/manuelred.png';

import vechilewhite from '../images/activityicons/vechilewhite.png';
import vechilegreen from '../images/activityicons/vechilegreen.png';
import vechileamber from '../images/activityicons/vechileamber.png';
import vechilered from '../images/activityicons/vechilered.png';

import drumswhite from '../images/activityicons/drumswhite.png';
import drumsgreen from '../images/activityicons/drumsgreen.png';
import drumsamber from '../images/activityicons/drumsamber.png';
import drumsred from '../images/activityicons/drumsred.png';

import manueldeepwhite from '../images/activityicons/manueldeepwhite.png';
import manueldeepgreen from '../images/activityicons/manueldeepgreen.png';
import manueldeepamber from '../images/activityicons/manueldeepamber.png';
import manueldeepred from '../images/activityicons/manueldeepred.png';

import manwhite from '../images/activityicons/manwhite.png';
import mangreen from '../images/activityicons/mangreen.png';
import manamber from '../images/activityicons/manamber.png';
import manred from '../images/activityicons/manred.png';

import tunellingwhite from '../images/activityicons/tunnelingwhite.png';
import tunellingreen from '../images/activityicons/tunnelinggreen.png';
import tunnelingamber from '../images/activityicons/tunnelingamber.png';
import tunnelingred from '../images/activityicons/tunnelingred.png';

import agreewhite from '../images/activityicons/agreewhite.png';
import agreegreen from '../images/activityicons/agreegreen.png';
import agreeamber from '../images/activityicons/agreeamber.png';
import agreered from '../images/activityicons/agreered.png';

import defaultwhite from '../images/activityicons/suspeciouswhite.png';
import defaultred from '../images/activityicons/suspeciousred.png';
import defaultgreen from '../images/activityicons/suspeciousgreen.png';
import defaultamber from '../images/activityicons/suspeciousamber.png';

import suspeciouswhite from '../images/activityicons/suspeciouswhite.png';
import suspeciousred from '../images/activityicons/suspeciousred.png';
import suspeciousgreen from '../images/activityicons/suspeciousgreen.png';
import suspeciousamber from '../images/activityicons/suspeciousamber.png';

// import roadwhite from '../images/activityicons/roadwhite.png';
// import roadred from '../images/activityicons/roadred.png';
// import roadgreen from '../images/activityicons/roadgreen.png';
// import roadamber from '../images/activityicons/roadamber.png';

import clampingwhite from '../images/activityicons/clambingwhite.png';
import clampingred from '../images/activityicons/clambingred.png';
import clampinggreen from '../images/activityicons/clambinggreen.png';
import clampingamber from '../images/activityicons/clambingamber.png';

import sleevingwhite from '../images/activityicons/sleeving_clear.png';
import sleevingred from '../images/activityicons/sleeving_red.png';
import sleevinggreen from '../images/activityicons/sleeving_green.png';
import sleevingamber from '../images/activityicons/sleeving_amber.png';

import trainwhite from '../images/activityicons/trainwhite.png';
import trainred from '../images/activityicons/trainred.png';
import traingreen from '../images/activityicons/traingreen.png';
import trainamber from '../images/activityicons/trainamber.png';

import grasscuttingwhite from '../images/activityicons/grasscutting_clear.png';
import grasscuttinggreen from '../images/activityicons/grasscutting_green.png';
import grasscuttingamber from '../images/activityicons/grasscutting_amber.png';
import grasscuttingred from '../images/activityicons/grasscutting_red.png';

import roadcrossingred from '../images/activityicons/roadcrossred.png';
import roadcrossingamber from '../images/activityicons/roadcrossamber.png';
import roadcrossinggreen from '../images/activityicons/roadcrossgreen.png';
import roadcrossingwhite from '../images/activityicons/roadcrosswhite.png';

import cowred from '../images/activityicons/cattlered.png';
import cowamber from '../images/activityicons/cattleamber.png';
import cowgreen from '../images/activityicons/cattlegreen.png';
import cowwhite from '../images/activityicons/cattlewhite.png';

import riverred from '../images/activityicons/nalaflowred.png';
import riveramber from '../images/activityicons/nalaflowamber.png';
import rivergreen from '../images/activityicons/nalaflowgreen.png';
import riverwhite from '../images/activityicons/nalaflowwhite.png';

import pumpsetred from '../images/activityicons/waterpumpred.png';
import pumpsetamber from '../images/activityicons/waterpumpamber.png';
import pumpsetgreen from '../images/activityicons/waterpumpgreen.png';
import pumpsetwhite from '../images/activityicons/waterpumpwhite.png';

import ploughingred from '../images/activityicons/ploughing_red.png';
import ploughingamber from '../images/activityicons/ploughing_amber.png';
import ploughinggreen from '../images/activityicons/ploughing_green.png';
import ploughingwhite from '../images/activityicons/ploughing_clear.png';

// eslint-disable-next-line no-global-assign
export default event = [
	{
		type: 'Multiple Activities',
		severity: [multiplewhite, multiplegreen, multipleamber, multiplered],
	},
	{
		type: 'Machine Digging',
		severity: [machinewhite, machinegreen, machineamber, machinered],
	},
	{
		type: 'Manual Digging',
		severity: [manuelwhite, manuelgreen, manuelamber, manuelred],
	},
	{
		type: 'Vehicle Movement',
		severity: [vechilewhite, vechilegreen, vechileamber, vechilered],
	},
	{
		type: 'Drums Beating',
		severity: [drumswhite, drumsgreen, drumsamber, drumsred],
	},
	{
		type: 'Manual Digging - Deeper (1.5 to 3 feets)',
		severity: [manueldeepwhite, manueldeepgreen, manueldeepamber, manueldeepred],
	},
	{
		type: 'Man Movement',
		severity: [manwhite, mangreen, manamber, manred],
	},
	{
		type: 'Tunneling',
		severity: [tunellingwhite, tunellingreen, tunnelingamber, tunnelingred],
	},
	{
		type: 'Aggregate Activity',
		severity: [agreewhite, agreegreen, agreeamber, agreered],
	},
	{
		type: 'Unclassified',
		severity: [defaultwhite, defaultgreen, defaultamber, defaultred],
	},
	{
		type: 'Suspicious Activity',
		severity: [suspeciouswhite, suspeciousgreen, suspeciousamber, suspeciousred],
	},
	// {
	// 	type: 'Road Crossing',
	// 	severity: [roadwhite, roadgreen, roadamber, roadred],
	// },
	{
		type: 'Nala Flowing',
		severity: [defaultwhite, defaultgreen, defaultamber, defaultred],
	},
	{
		type: 'Clamping',
		severity: [clampingwhite, clampinggreen, clampingamber, clampingred],
	},
	{
		type: 'Sleeving',
		severity: [sleevingwhite, sleevinggreen, sleevingamber, sleevingred],
	},
	{
		type: 'Train Movement',
		severity: [trainwhite, traingreen, trainamber, trainred],
	},
	{
		type: 'Manual Augering',
		severity: [defaultwhite, defaultgreen, defaultamber, defaultred],
	},
	{
		type: 'Machine Augering',
		severity: [defaultwhite, defaultgreen, defaultamber, defaultred],
	},
	{
		type: 'Ploughing activity',
		severity: [ploughingwhite, ploughinggreen, ploughingamber, ploughingred],
	},
	{
		type: 'Ploughing',
		severity: [ploughingwhite, ploughinggreen, ploughingamber, ploughingred],
	},
	{
		type: 'Water Pump',
		severity: [pumpsetwhite, pumpsetgreen, pumpsetamber, pumpsetred],
	},
	{
		type: 'Grass Cutting',
		severity: [grasscuttingwhite, grasscuttinggreen, grasscuttingamber, grasscuttingred],
	},
	{
		type: 'Road Crossing',
		severity: [roadcrossingwhite, roadcrossinggreen, roadcrossingamber, roadcrossingred],
	},
	{
		type: 'Horse/Cow/Buffalo/Goat tied inside ROU',
		severity: [cowwhite, cowgreen, cowamber, cowred],
	},
	{
		type: 'Sheep / Cattle Grazing',
		severity: [cowwhite, cowgreen, cowamber, cowred],
	},
	{
		type: 'Nala Flowing',
		severity: [riverwhite, rivergreen, riveramber, riverred],
	},
];

