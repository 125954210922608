import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import frenchlan from '../../../french.json';
import malayalamlan from '../../../malayalam.json';

const styles = StyleSheet.create({
	invoiceNoContainer: {
		flexDirection: 'row',
		marginTop: 36,
		justifyContent: 'flex-end'
	},
	invoiceDateContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginTop:'-20px'
	},
	invoiceInvoiceNo: {
		fontSize: 12,
		fontStyle: 'bold'
	},
	invoiceDate: {
		fontSize: 8,
		fontStyle: 'bold',
		// marginTop:'13px'
	},
	label: {
		marginRight: 15,
		fontSize: 10,
		marginTop:'15px'

	}
});



const InvoiceNo = () => {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? englan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? englan : langid == 6 ? malayalamlan : englan;

	return(
		<>
			{/* <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>Invoice No: {''}</Text>
      <Text style={styles.invoiceInvoiceNo}>{invoice.invoice_no}</Text>
    </View> */}
			<View style={styles.invoiceDateContainer}>
				<Text style={styles.label}>{langdata && langdata.Date ? langdata.Date:'Date'}:</Text>
				<Text style={styles.label}>{moment().format(settingsdateformat)}</Text>
			</View>
		</>);
};

export default InvoiceNo;