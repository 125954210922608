import moment from 'moment';
import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR, TLP_LOGS, TLP_LOGS_SUCCESS, TLP_LOGS_ERROR, TLP_BATTERY_REPLACEMENT_LOGS, TLP_BATTERY_REPLACEMENT_LOGS_SUCCESS, TLP_BATTERY_REPLACEMENT_LOGS_ERROR, TLP_CONFIG_LOGS, TLP_CONFIG_LOGS_SUCCESS, TLP_CONFIG_LOGS_ERROR }  from './constants';
import {REALTIME_CPS_LIST, REALTIME_CPS_LIST_SUCCESS, REALTIME_CPS_LIST_ERROR} from './constants';
import {REALTIME_CPS_DETAILS, REALTIME_CPS_DETAILS_SUCCESS, REALTIME_CPS_DETAILS_ERROR} from './constants';
import { datetimeCovertertolocal } from '../../datetime-converter';

export default function realtimeCPSReducer(state, action) {
	let pagination = {};
	let tlps = [];
	let cps = [];
	let cpsLogs = [];
	let batteryReplacedLogs = [];
	let hardwareconfiglogs = [];

	switch (action.type) {
	case TLPS_LIST:
		return { ...state, loading: true, error: false };

	case TLPS_LIST_SUCCESS:
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.deviceId = item.deviceId ? item.deviceId : 0;
				data.pipelineChainage = item.pipelineChainage ? parseFloat(item.pipelineChainage)/1000 : 0;

				tlps.push(data);
			});

			let sortedTLPS = tlps.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, tlps: sortedTLPS, loading: false, error: false };
		} else {
			return { ...state, tlps : [], loading: false, error: true };
		}

	case TLPS_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case REALTIME_CPS_LIST:
		return { ...state, loading: true };

	case REALTIME_CPS_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};
				const datetime = datetimeCovertertolocal(item ?  item.lastSync : '<Not Set>');
				const newdate = datetime.localdate + ' ' +  datetime.localtime;
				const installedDatetime = datetimeCovertertolocal(item ?  item.installedDate : '<Not Set>');
				const installedNewdate = installedDatetime.localdate + ' ' +  installedDatetime.localtime;
				const batteryReplacedDatetime = datetimeCovertertolocal(item ?  item.batteryReplacedDate : '<Not Set>');
				const batteryReplacedDate = batteryReplacedDatetime.localdate + ' ' +  batteryReplacedDatetime.localtime;
				const hardwareConfigSyncedDatetime = datetimeCovertertolocal(item ?  item.hardwareConfigSyncedDate : '<Not Set>');
				const hardwareConfigSyncedDate = hardwareConfigSyncedDatetime.localdate + ' ' +  hardwareConfigSyncedDatetime.localtime;
				const loggeddatetime = datetimeCovertertolocal(item ?  item.lastLoggedDate : '<Not Set>');
				const newloggeddate = loggeddatetime.localdate + ' ' +  loggeddatetime.localtime;

				let systemStatus = item.systemStatus;
				if(systemStatus) {
					for (let i = 0; i < systemStatus.length; i++) {
						if(systemStatus.includes(',')) {
							systemStatus = systemStatus.replace(',', ' | ');
						}
					}

					if(systemStatus.includes('vdc2')) {
						systemStatus = systemStatus.replace('vdc2', 'PSP');
					}
					if(systemStatus.includes('vdc1')) {
						systemStatus = systemStatus.replace('vdc1', 'CSP');
					}
					if(systemStatus.includes('vac1')) {
						systemStatus = systemStatus.replace('vac1', 'CASING AC PICKUP');
					}
					if(systemStatus.includes('vac2')) {
						systemStatus = systemStatus.replace('vac2', 'AC PICKUP');
					}
				}

				data.id = item.id ? parseInt(item.id) : 0;
				data.regioninfoId = item.regioninfoId ? item.regioninfoId : 0;
				data.pipelineChainageId = item.pipelineChainageId ? item.pipelineChainageId : 0;
				data.pipelineChainage = item.pipelineChainage ?  parseFloat(item.pipelineChainage)/1000 : 0;
				data.deviceId = item.deviceId ?  item.deviceId : 0;
				data.deviceType = item.deviceType ? item.deviceType : 'tlp';
				data.lastSync = item.lastSync ?  newdate : '-';
				data.vac1 = item.vac1 ? parseFloat(item.vac1).toFixed(4) : 0;
				data.vac2 = item.vac2 ? parseFloat(item.vac2).toFixed(4) : 0;
				data.vdc1 = item.vdc1 ? parseFloat(item.vdc1).toFixed(4) : 0;
				data.vdc2 = item.vdc2 ? parseFloat(item.vdc2).toFixed(4) : 0;
				data.vac1Normal = item.vac1Normal ?(item.vac1Normal) : '0:0';
				data.vac2Normal = item.vac2Normal ? (item.vac2Normal) : '0:0';
				data.vdc1Normal = item.vdc1Normal ? (item.vdc1Normal) : '0:0';
				data.vdc2Normal = item.vdc2Normal ? (item.vdc2Normal): '0:0';
				data.vbat = item.vbat ? parseFloat(item.vbat).toFixed(2) : 0;
				data.temp = item.temp ? parseFloat(item.temp).toFixed(2) : 0;
				data.current = item.current ? parseFloat(item.current).toFixed(2) : 0;
				data.pollFrequencyInMinutes = item.pollFrequencyInMinutes ? item.pollFrequencyInMinutes : 0;
				data.anomalyPollFrequencyInMinutes = item.anomalyPollFrequencyInMinutes ? item.anomalyPollFrequencyInMinutes : 0;
				data.duration = item.duration ? item.duration : 0;
				data.retryCount = item.retryCount ? item.retryCount : 0;
				data.secondVoltage = item.secondVoltage ? item.secondVoltage : 0;
				data.anomaly = item.anomaly == true  ?  'Anomaly' : 'Normal';
				data.baseURL = item.baseURL ? item.baseURL : '<Not Set>';
				data.otaURL = item.otaURL ? item.otaURL : '<Not Set>';
				data.otaBinaryURL = item.otaBinaryURL ? item.otaBinaryURL : '<Not Set>';
				data.otaVersion = item.otaVersion ? item.otaVersion : 0;
				data.deviceOTAVersion = item.deviceOTAVersion ? item.deviceOTAVersion : 0;
				data.otaUpdateAvailable = item.otaUpdateAvailable ? item.otaUpdateAvailable : 0;
				data.hasSolarPanel = item.hasSolarPanel ? item.hasSolarPanel : false;
				data.installedDate = item.installedDate ?  installedNewdate : '-';
				data.isTRU = item.isTRU ? item.isTRU : false;
				data.midpointTLP = item.midpointTLP ? item.midpointTLP : '<Not Set>';
				data.truVoltage = item.truVoltage ? item.truVoltage : 0;
				data.truCorrectionVoltage = item.truCorrectionVoltage ? item.truCorrectionVoltage : 0;
				data.isVulnerable = item.isVulnerable ? item.isVulnerable : false;
				data.latitude = item.latitude ? item.latitude : 0;
				data.longitude = item.longitude ? item.longitude : 0;
				data.createdAt = item.createdAt ? item.createdAt : '<Not Set>';
				data.updatedAt = item.updatedAt ? item.updatedAt : '<Not Set>';
				data.batteryReplacedDate = item.batteryReplacedDate ? batteryReplacedDate : '-';
				data.hardwareConfigSyncedDate = item.hardwareConfigSyncedDate ? hardwareConfigSyncedDate : '-';
				data.systemStatus = systemStatus ? systemStatus : 'Normal' ;
				data.lastLoggedDate = item.lastLoggedDate ?  newloggeddate : '-';
				data.offPSPac = item.offPSPac ? parseFloat(item.offPSPac).toFixed(4) : 0;
				data.offPSPdc = item.offPSPdc ? parseFloat(item.offPSPdc).toFixed(4) : 0;
				data.currentDc = item.currentDc ? parseFloat(item.currentDc).toFixed(4): 0;
				data.currentAc = item.currentAc ? parseFloat(item.currentAc).toFixed(4) : 0;
				data.offCSPac = item.offCSPac ? parseFloat(item.offCSPac).toFixed(4) : 0;
				data.offCSPdc = item.offCSPdc ? parseFloat(item.offCSPdc).toFixed(4) : 0;
				data.offPSPacNormal = item.offPSPacNormal ?(item.offPSPacNormal) : '0:0';
				data.offPSPdcNormal = item.offPSPdcNormal ? (item.offPSPdcNormal) : '0:0';
				data.offCSPacNormal = item.offCSPacNormal ? (item.offCSPacNormal) : '0:0';
				data.offCSPdcNormal = item.offCSPdcNormal ? (item.offCSPdcNormal): '0:0';
				data.pspType = item.pspType;
				if(item.pspType == 'on'){
					data.offPSPac = 'NA';
					data.offPSPdc = 'NA';
					data.currentDc = 'NA';
					data.currentAc = 'NA';
					data.offCSPac = 'NA';
					data.offCSPdc = 'NA';
				}
				else if(item.pspType=='polarized'){
					data.offPSPac = 'NA';
					data.offPSPdc = 'NA';
					data.currentDc = 'NA';
					data.currentAc = 'NA';
					data.offCSPac = 'NA';
					data.offCSPdc = 'NA';
				}

				if(item.secondVoltage == false || item.secondVoltage == 0) {
					data.vdc1 = 'NA';
					data.vac1 = 'NA';
					data.offCSPac ='NA';
					data.offCSPdc = 'NA';
					data.vac1Normal = 'NA';
					data.vdc1Normal = 'NA';
					data.offCSPacNormal = 'NA';
					data.offCSPdcNormal = 'NA';
				}


				cps.push(data);
			});

			let sortedCPS = cps.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, cps: sortedCPS, pagination: pagination, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, cps: cps, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}
    
	case REALTIME_CPS_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case REALTIME_CPS_DETAILS:
		return { ...state, loading: true };

	case REALTIME_CPS_DETAILS_SUCCESS:
		return { ...state, details: action.payload, loading: false };
    
	case REALTIME_CPS_DETAILS_ERROR:
		return { ...state, loading: false, error: true };
	case TLP_LOGS :
		return { ...state, loading: true };
	case TLP_LOGS_SUCCESS :
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				const datetime = datetimeCovertertolocal(item ?  item.datetime : '<Not Set>');
				const newdate = datetime.localdate + ' ' +  datetime.localtime;
				let data = {};
				data.id = item.id ? parseInt(item.id) : 0;
				data.regioninfoId = item.regioninfoId ? item.regioninfoId : 0;
				data.pipelineChainageId = item.pipelineChainageId ? item.pipelineChainageId : 0;
				data.pipelineChainage = item.pipelineChainage ?  parseFloat(item.pipelineChainage)/1000 : 0;
				data.deviceId = item.device_id ?  item.device_id : 0;
				data.deviceType = item.deviceType ? item.deviceType : 'tlp';
				data.lastSync = item.smarttlp.lastSync ?  moment(item.smarttlp.lastSync, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm a') : '<Not Set>';
				data.vac1 = item.vac1 ? parseFloat(item.vac1).toFixed(4) : 0;
				data.vac2 = item.vac2 ? parseFloat(item.vac2).toFixed(4) : 0;
				data.vdc1 = item.vdc1 ? parseFloat(item.vdc1).toFixed(4) : 0;
				data.vdc2 = item.vdc2 ? parseFloat(item.vdc2).toFixed(4) : 0;
				data.vac1Normal = item.smarttlp.vac1Normal ?(item.smarttlp.vac1Normal) : '0:0';
				data.vac2Normal = item.smarttlp.vac2Normal ? (item.smarttlp.vac2Normal) : '0:0';
				data.vdc1Normal = item.smarttlp.vdc1Normal ? (item.smarttlp.vdc1Normal) : '0:0';
				data.vdc2Normal = item.smarttlp.vdc2Normal ? (item.smarttlp.vdc2Normal): '0:0';
				data.vbat = item.vbat ? parseFloat(item.vbat).toFixed(2) : 0;
				data.temp = item.temp ? parseFloat(item.temp).toFixed(2) : 0;
				data.current = item.current ? parseFloat(item.current).toFixed(2) : 0;
				data.pollFrequencyInMinutes = item.pollFrequencyInMinutes ? item.pollFrequencyInMinutes : 0;
				data.anomalyPollFrequencyInMinutes = item.anomalyPollFrequencyInMinutes ? item.anomalyPollFrequencyInMinutes : 0;
				data.duration = item.duration ? item.duration : 0;
				data.retryCount = item.retryCount ? item.retryCount : 0;
				data.secondVoltage = item.smarttlp.secondVoltage ? item.smarttlp.secondVoltage : 0;
				data.anomaly = item.anomaly == true  ?  'Anomaly' : 'Normal';
				data.baseURL = item.baseURL ? item.baseURL : '<Not Set>';
				data.otaURL = item.otaURL ? item.otaURL : '<Not Set>';
				data.otaBinaryURL = item.otaBinaryURL ? item.otaBinaryURL : '<Not Set>';
				data.otaVersion = item.otaVersion ? item.otaVersion : 0;
				data.deviceOTAVersion = item.deviceOTAVersion ? item.deviceOTAVersion : 0;
				data.otaUpdateAvailable = item.otaUpdateAvailable ? item.otaUpdateAvailable : 0;
				data.hasSolarPanel = item.hasSolarPanel ? item.hasSolarPanel : false;
				data.installedDate = item.installedDate ? item.installedDate : '<Not Set>';
				data.isTRU = item.isTRU ? item.isTRU : false;
				data.midpointTLP = item.midpointTLP ? item.midpointTLP : '<Not Set>';
				data.truVoltage = item.truVoltage ? item.truVoltage : 0;
				data.truCorrectionVoltage = item.truCorrectionVoltage ? item.truCorrectionVoltage : 0;
				data.isVulnerable = item.isVulnerable ? item.isVulnerable : false;
				data.latitude = item.latitude ? item.latitude : 0;
				data.longitude = item.longitude ? item.longitude : 0;
				data.createdAt = item.createdAt ? item.createdAt : '<Not Set>';
				data.updatedAt = item.updatedAt ? item.updatedAt : '<Not Set>';
				data.datetime = item.datetime ? newdate : '-';
				data.offPSPac = item.offPSPac ? parseFloat(item.offPSPac).toFixed(4) : 0;
				data.offPSPdc = item.offPSPdc ? parseFloat(item.offPSPdc).toFixed(4) : 0;
				data.currentDc = item.currentDc ? parseFloat(item.currentDc).toFixed(4): 0;
				data.currentAc = item.currentAc ? parseFloat(item.currentAc).toFixed(4) : 0;
				data.offCSPac = item.offCSPac ? parseFloat(item.offCSPac).toFixed(4) : 0;
				data.offCSPdc = item.offCSPdc ? parseFloat(item.offCSPdc).toFixed(4) : 0;
				data.offPSPacNormal = item.smarttlp.offPSPacNormal ?(item.smarttlp.offPSPacNormal) : '0:0';
				data.offPSPdcNormal = item.smarttlp.offPSPdcNormal ? (item.smarttlp.offPSPdcNormal) : '0:0';
				data.offCSPacNormal = item.smarttlp.offCSPacNormal ? (item.smarttlp.offCSPacNormal) : '0:0';
				data.offCSPdcNormal = item.smarttlp.offCSPdcNormal ? (item.smarttlp.offCSPdcNormal): '0:0';
				data.pspType = item.pspType;				

				if(item.pspType == 'on'){ //replace this with pspType
					data.offPSPac = 'NA';
					data.offPSPdc = 'NA';
					data.currentDc = 'NA';
					data.currentAc = 'NA';
					data.offCSPac = 'NA';
					data.offCSPdc = 'NA';
				}
				else if(item.pspType=='polarized'){ //replace this with pspType
					data.offPSPac = 'NA';
					data.offPSPdc = 'NA';
					data.currentDc = 'NA';
					data.currentAc = 'NA';
					data.offCSPac = 'NA';
					data.offCSPdc = 'NA';
				}

				if(item.smarttlp.secondVoltage == false || item.smarttlp.secondVoltage == 0) {
					data.vdc1 = 'NA';
					data.vac1 = 'NA';
					data.offCSPac ='NA';
					data.offCSPdc = 'NA';
					data.vac1Normal = 'NA';
					data.vdc1Normal = 'NA';
					data.offCSPacNormal = 'NA';
					data.offCSPdcNormal = 'NA';
				}

				cpsLogs.push(data);
			});
		} 
		return { ...state, cpsLogs: cpsLogs, pagination: pagination, loading: false, error: false };
	case TLP_LOGS_ERROR :
		return { ...state, loading: false, error: true };

	case TLP_BATTERY_REPLACEMENT_LOGS : 
		return { ...state, loading: true };

	case TLP_BATTERY_REPLACEMENT_LOGS_SUCCESS :
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};
				const datetime = datetimeCovertertolocal(item ?  item.datetime : '<Not Set>');
				const newdate = datetime.localdate + ' ' +  datetime.localtime;
				data.id = item.id ? parseInt(item.id) : 0;
				data.voltage = item.voltage ? item.voltage : null;
				data.previousVoltage = item.previousVoltage ? item.previousVoltage : null;
				data.datetime = newdate ? newdate : null;
				data.status = item.systemStatus ? item.systemStatus : null;

				batteryReplacedLogs.push(data);
			});
		}
		return { ...state, batteryReplacedLogs: batteryReplacedLogs, loading: false, error: false };

	case TLP_BATTERY_REPLACEMENT_LOGS_ERROR :
		return { ...state, loading: false, error: true };

	case TLP_CONFIG_LOGS : 
		return { ...state, loading: true };

	case TLP_CONFIG_LOGS_SUCCESS :
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};
				const datetime = datetimeCovertertolocal(item ?  item.datetime : '<Not Set>');
				const newdate = datetime.localdate + ' ' +  datetime.localtime;
				const hardwareConfigSyncedDatetime = datetimeCovertertolocal(item ?  item.hardwareConfigSyncedDate : '<Not Set>');
				const hardwareConfigSyncedDate = hardwareConfigSyncedDatetime.localdate + ' ' +  hardwareConfigSyncedDatetime.localtime;
				data.id = item.id ? parseInt(item.id) : 0;
				data.vdc2Normal = item.vdc2Normal ? item.vdc2Normal : '0:0';
				data.vac2Normal = item.vac2Normal ? item.vac2Normal : '0:0';
				data.vdc1Normal = item.vdc1Normal ? item.vdc1Normal : '0:0';
				data.vac1Normal = item.vac1Normal ? item.vac1Normal : '0:0';
				
				data.offPSPacNormal = item.offPSPacNormal ?(item.offPSPacNormal) : '0:0';
				data.offPSPdcNormal = item.offPSPdcNormal ? (item.offPSPdcNormal) : '0:0';
				data.offCSPacNormal = item.offCSPacNormal ? (item.offCSPacNormal) : '0:0';
				data.offCSPdcNormal = item.offCSPdcNormal ? (item.offCSPdcNormal): '0:0';

				data.pollFrequencyInMinutes = item.pollFrequencyInMinutes ? item.pollFrequencyInMinutes : '-';
				data.datetime = newdate ? newdate : null;
				data.anomalyPollFrequencyInMinutes = item.anomalyPollFrequencyInMinutes ? item.anomalyPollFrequencyInMinutes : 0;
				data.hardwareConfigSyncedDate = item.hardwareConfigSyncedDate ? hardwareConfigSyncedDate : '-';

				hardwareconfiglogs.push(data);
			});
		}
		return { ...state, hardwareconfiglogs: hardwareconfiglogs, loading: false, error: false };

	case TLP_CONFIG_LOGS_ERROR :
		return { ...state, loading: false, error: true };
    
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}